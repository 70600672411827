import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentPlanoContas from '../../components/financeiro/plano-contas/index';

const PlanoContasScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentPlanoContas/>
  </Fragment>
)

export default PlanoContasScreen;