import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSackDollar, faSackXmark } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import moment from 'moment/moment';
import 'moment-timezone';
import 'moment/locale/pt-br'
import { NumericFormat } from 'react-number-format';
import "../../../styles/fluxo.scss";
import "../../../styles/reset.scss";
import FinanceiroService from '../../../services/financeiro';
// import UsersService from '../../../services/users';
// import Modal from "../../layouts/modal";
import Loading from '../../layouts/loading';
Moment.globalLocale = 'pt-BR'

export default function ContentContas() {
    // const [userLogado, setUserLogado] = useState();
    const [contas, setContas] = useState();
    const [planos, setPlanos] = useState();
    const [caixas, setCaixas] = useState();
    const [filtroAtivo, setFiltroAtivo] = useState({data_inicial: moment().subtract(7, 'days').format("YYYY-MM-DD"), data_final: moment().format("YYYY-MM-DD")});
    const [carregado, setCarregado] = useState(false);
    const [days, setDays] = useState();
    const navigate = useNavigate();

    async function getContas() {
        const res = await FinanceiroService.contas();
        setContas(res.data.filter(filtro));
        getDays(res.data.filter(filtro));
    };

    async function getCaixas() {
        const res = await FinanceiroService.caixas();
        setCaixas(res.data);
    };

    async function getPlanos() {
        const res = await FinanceiroService.planos();
        setPlanos(res.data);
    };

    async function getDays(response) {
        const todos = await response.filter(({status}) => status.at(-1).situacao === "Liquidado").map((item) => {return(moment(item.pagamento).format("YYYY-MM-DDT03:00:00.000Z"))});
        setDays([...new Set(todos)]);
    };

    function filtrarFluxo(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        form.caixa === "default" && delete form.caixa;
        form.plano_contas === "default" && delete form.plano_contas;

        if (form.data_final === "") {
            if (form.data_inicial === "") {
                delete form.data_inicial;
                delete form.data_final;
            } else {
                form.data_final = moment().format("YYYY-MM-DD");
            }
        } else {
            if (form.data_inicial === "") {
                form.data_inicial = moment(form.data_final).subtract(7, 'days').format("YYYY-MM-DD");
            } else {
                if (form.data_final < form.data_inicial) {
                    const inicial = form.data_inicial;
                    
                    form.data_inicial = form.data_final;
                    form.data_final = inicial;
                }
            }
        }

        try {
            if (!isEmptyObject(form)) {
                setFiltroAtivo(form);
            }
        } catch (error) {
            console.log(error);
        }
    }

    function isEmptyObject(obj) {
        var name;
        for (name in obj) {
            return false;
        }
            return true;
    }
      

    function filtro(a) {
        if (filtroAtivo.caixa !== undefined && a.caixa !== filtroAtivo.caixa) {
            return false;
          }
        
          if (filtroAtivo.plano_contas !== undefined && a.plano_contas._id !== filtroAtivo.plano_contas) {
            return false;
          }
        
          if (filtroAtivo.data_inicial !== undefined && moment(a.pagamento).format("YYYY-MM-DDT03:00:00.000Z") < moment(filtroAtivo.data_inicial).format("YYYY-MM-DDT03:00:00.000Z")) {
            return false;
          }
        
          if (filtroAtivo.data_final !== undefined && moment(a.pagamento).format("YYYY-MM-DDT03:00:00.000Z") > moment(filtroAtivo.data_final).format("YYYY-MM-DDT03:00:00.000Z")) {
            return false;
          }
        
          return true;
        // if (filtroAtivo.caixa) {
        //     return a.caixa === filtroAtivo.caixa;
        // }

        // if (filtroAtivo.plano_contas !== undefined) {
        //     return a.plano_contas._id === filtroAtivo.plano_contas;
        // }

        // if (filtroAtivo.data_inicial && filtroAtivo.data_final) {
        //     return a.pagamento >= filtroAtivo.data_inicial;
        // }

        // return true;
    }

    useEffect(() => {
        (async () => {
            // await getUserLogado();
            await getContas();
            await getCaixas();
            await getPlanos();
            setCarregado(true);
        })();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getContas();
        // eslint-disable-next-line
    }, [filtroAtivo])

    function separarDias(a,b){
        return b > a ? -1 : b < a ? 1 : 0;
    };

    document.title = `Fluxo de Caixa - DNG Store`;

    return(
        <Fragment>
            { carregado ? 
                <main className='main'>
                    <div className="conteudo-fluxo">
                        <div className="cabecalho-conteudo">
                            <h2>Fluxo de Caixa</h2>
                            <div className="buttons-conteudo">
                                <button className="button-red" onClick={() => navigate(`/financeiro`)}>Voltar</button>
                            </div>
                        </div>
                        {/* <div className='container-search'>
                            <select defaultValue={filtroSelecionado} onChange={e => {setFiltroSelecionado(e.target.value); localStorage.setItem('filtro-ativo-contas', e.target.value)}}>
                                <option value="todos">Todos</option>
                                <option value="pendente">Pendente</option>
                                <option value="liquidado">Liquidado</option>
                                <option value="cartorio">Em Cartório</option>
                                <option value="caução">Em Caução</option>
                                <option value="perdido">Perdido</option>
                            </select>
                            <input type='text' placeholder='Buscar' onChange={e => {setFinds(e.target.value)}}/>
                            <FontAwesomeIcon icon={faSearch}/>
                        </div> */}
                        <div className='filtro-fluxo'>
                            <h3>Filtre sua busca</h3>
                            <form onSubmit={filtrarFluxo}>
                                <div className='conteudo-filtro-fluxo'>
                                    <div className='campo-form campo-100'>
                                        <label htmlFor='caixa'>Caixa</label>
                                        <select id='caixa' name='caixa'>
                                            <option value="default">Todos</option>
                                            { caixas.filter((a) => !a.excluido && a.ativo).map((item, index) => (
                                                <option key={index} value={item._id}>{item.descricao}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='campo-form campo-100'>
                                        <label htmlFor='plano_contas'>Plano de Contas</label>
                                        <select id='plano_contas' name='plano_contas'>
                                            <option value="default">Todos</option>
                                            <option disabled></option>
                                            <option disabled style={{color: "green", fontWeight: 500, textAlign: 'center'}}>Entradas</option>
                                                {(() => {
                                                    return planos.filter(({tipo}) => tipo === "Entradas").map((item, index) => (
                                                        <optgroup key={index} label={item.descricao}>
                                                            {item.subcategorias.map((s, i) => (
                                                                <option key={i} value={s.sub._id}>{s.sub.descricao}</option>
                                                            ))}
                                                        </optgroup>
                                                    ));
                                                })()}
                                            <option disabled></option>
                                            <option disabled style={{color: "red", fontWeight: 600, textAlign: 'center'}}>Saídas</option>
                                                {(() => {
                                                    return planos.filter(({tipo}) => tipo === "Saídas").map((item, index) => (
                                                        <optgroup label={item.descricao}>
                                                            {item.subcategorias.map((s, i) => (
                                                                <option key={i} value={s.sub._id}>{s.sub.descricao}</option>
                                                            ))}
                                                        </optgroup>
                                                    ));
                                                })()}
                                        </select>
                                    </div>
                                    <div className='campo-form campo-50'>
                                        <label htmlFor='data_inicial'>Data Inicial</label>
                                        <input type='date' id='data_inicial' name='data_inicial' defaultValue={filtroAtivo.data_inicial} min="2013-04-24" max={moment().format("YYYY-MM-DD")}/>
                                    </div>
                                    <div className='campo-form campo-50'>
                                        <label htmlFor='data_final'>Data Final</label>
                                        <input type='date' id='data_final' name='data_final' defaultValue={filtroAtivo.data_final} min="2013-04-24" max={moment().format("YYYY-MM-DD")}/>
                                    </div>
                                </div>
                                <button className='button-blue'>Buscar</button>
                            </form>
                        </div>
                        <div className='tabela-fluxo'>
                            { days.length === 0 ?
                                <div className="column-zerado">
                                    Não foi encontrado nenhum resultado.
                                </div>
                            :
                                days.sort(separarDias).map((element) => (
                                    <div className='item-contas-fluxo'>
                                        <h4><Moment format='DD [de] MMMM'>{element}</Moment></h4>
                                        {contas.filter(( a ) => moment(a.pagamento).format("DD/MM/YYYY") === moment(element).format("DD/MM/YYYY") && a.status.at(-1).situacao === "Liquidado").map((item, index) => (
                                            <div key={index} className="row-contas">
                                                <FontAwesomeIcon icon={item.tipo === "Pagar" ? faSackXmark : faSackDollar} style={item.tipo === "Pagar" ? {color: "#d42f2f"} : {color: "#208d20"}}/>
                                                <div className="descricao-contas-fluxo" style={item.tipo === "Pagar" ? {borderLeft: "3px solid #d42f2f"} : {borderLeft: "3px solid #208d20"}}>
                                                    <h6>{item.descricao}</h6>
                                                    <p>{item.plano_contas.descricao}</p>
                                                </div>
                                                <NumericFormat value={item.valor} displayType="text" decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale renderText={(value) => <p>{item.tipo === "Pagar" ? "-" : ""}{value}</p>}/>
                                            </div>
                                        ))}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </main>
            :
            <Loading>
                
            </Loading>
            }
        </Fragment>
    )
}