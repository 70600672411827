import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faRuler, faCloudArrowUp, faListUl, faXmark, faDollarSign, faTruckRampBox, faTShirt, faPlus } from '@fortawesome/free-solid-svg-icons';
import Moment from 'react-moment';
import moment from 'moment/moment';
import 'moment-timezone';
import 'moment/locale/pt-br'
import "../../styles/item.scss";
import "../../styles/reset.scss";
import UsersService from '../../services/users';
import Modal from "../layouts/modal";
import Loading from '../layouts/loading';
import EstoqueService from '../../services/estoque';
import { NumericFormat } from 'react-number-format';
import ModalDangerSmall from '../layouts/modalDangerSmall';
import FinanceiroService from '../../services/financeiro';
Moment.globalLocale = 'pt-BR'

export default function ContentItemEstoque() {
    const [userLogado, setUserLogado] = useState();
    const [planos, setPlanos] = useState();
    const [parcelado, setParcelado] = useState(false);
    const [quantidadeParcelas, setQuantidadeParcelas] = useState(['1', '2']);
    const [vencimentoConta, setVencimentoConta] = useState('');
    const [valorConta, setValorConta] = useState('');
    const [documentoConta, setDocumentoConta] = useState('');
    const [item, setItem] = useState([]);
    const [motivoBaixa, setMotivoBaixa] = useState();
    const [pecaAtiva, setPecaAtiva] = useState();
    const [medidas, setMedidas] = useState([]);
    const [carregado, setCarregado] = useState(false);
    const [modalEditarItem, setModalEditarItem] = useState(false);
    const [modalDeleteItem, setModalDeleteItem] = useState(false);
    const [modalNovaPeca, setModalNovaPeca] = useState(false);
    const [modalPeca, setModalPeca] = useState(false);
    const [modalEditarPeca, setModalEditarPeca] = useState(false);
    const [modalDeletePeca, setModalDeletePeca] = useState(false);
    const [modalBaixarPeca, setModalBaixarPeca] = useState(false);
    const [modalAcrescentarPeca, setModalAcrescentarPeca] = useState(false);
    const [aguardandoRetorno, setAguardandoRetorno] = useState(false);
    const [fileName, setFileName] = useState('');
    const navigate = useNavigate();
    const { id, otherId } = useParams();

    async function getUserLogado() {
        const res = await UsersService.logado();
        setUserLogado(res.data);
    };

    async function getItem() {
        const res = await EstoqueService.item(otherId);
        setItem(res.data);
        getMedidas(res.data);
    };

    async function getPlanos() {
        const res = await FinanceiroService.planos();
        setPlanos(res.data);
    };

    useEffect(() => {
        (async function() {
            await getUserLogado();
            await getItem();
            await getPlanos();
            setCarregado(true);
        })();
        // eslint-disable-next-line
    }, []);

    function adicionarParcela(quantidade) {
        let quantidadeItem = [];

        for (let index = 1; index <= quantidade; index++) {
            quantidadeItem.push(index)
        }
        setQuantidadeParcelas(quantidadeItem);
    };

    async function createPeca(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        (form.imagem === "" || form.imagem === undefined || form.imagem.size === 0) && delete form.imagem;

        const regex = /[\d,]+/g;

        form.valor = form.valor.match(regex).join('').replace(',', '.');
        form.valor_frete = form.valor_frete.match(regex).join('').replace(',', '.');
        form.valor = Number(form.valor);
        form.valor_frete = Number(form.valor_frete);

        try {
            setAguardandoRetorno(true);
            await EstoqueService.create_peca(otherId, form);
            await getItem();
            setModalNovaPeca(false);
            setAguardandoRetorno(false);
            setFileName("");
        } catch (error) {
            console.log(error)
        }
    };

    async function editPeca(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        (form.imagem === "" || form.imagem === undefined || form.imagem.size === 0) && (form.imagem = pecaAtiva.imagem);

        const regex = /[\d,]+/g;

        form.valor = form.valor.match(regex).join('').replace(',', '.');
        form.valor_frete = form.valor_frete.match(regex).join('').replace(',', '.');
        form.valor = Number(form.valor);
        form.valor_frete = Number(form.valor_frete);

        try {
            setAguardandoRetorno(true);
            await EstoqueService.edit_peca(otherId, pecaAtiva._id, form);
            await getItem();
            setModalEditarPeca(false);
            setAguardandoRetorno(false);
            setFileName("");
        } catch (error) {
            console.log(error)
        }
    };

    async function baixarPeca(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        const formBaixa = {
            quantidade: form.quantidade,
            motivo: form.descricao_motivo ? form.descricao_motivo : form.motivo
        };

        const regex = /[\d,]+/g;

        if (form.motivo === "Venda") {
            delete form.parcelado;
            delete form.add_observacao;
            delete form.motivo;
            delete form.quantidade;

            form.lancamento = moment(form.lancamento).add(3, "h");
            form.vencimento = moment(form.vencimento).add(3, "h");
            form.tipo = "Receber"


            form.valor = form.valor.match(regex).join('').replace(',', '.');
            form.valor = Number(form.valor);

            form.observacoes === "" && delete form.observacoes;
        };

        try {
            setAguardandoRetorno(true);

            await EstoqueService.baixar_peca(otherId, pecaAtiva._id, formBaixa);

            if (formBaixa.motivo === "Venda") {
                if (parcelado) {
                    for (let i = 1; i <= quantidadeParcelas.length; i++) {
                        const vencimentoInput = document.querySelector(`input[name^="vencimento${i}"]`).value;
                        const documentoInput = document.querySelector(`input[name="documento${i}"]`).value;
                        const valorInput = document.querySelector(`input[name="valor${i}"]`).value;
                        const tipoPagamentoInput = document.querySelector(`select[name="forma_pgto${i}"]`).value;
            
                        let contaParcelada = {
                            vencimento: moment(vencimentoInput).add(3, 'h'),
                            documento: documentoInput,
                            valor: valorInput,
                            forma_pgto: tipoPagamentoInput,
                            descricao: form.descricao,
                            lancamento: form.lancamento,
                            plano_contas: form.plano_contas,
                            tipo: form.tipo
                        }
    
                        contaParcelada.valor = contaParcelada.valor.match(regex).join('').replace(',', '.');
                        contaParcelada.valor = Number(contaParcelada.valor);
    
                        if (form.observacoes) {
                            contaParcelada.observacoes = form.observacoes
                        }
                        
                        if (form.parceiro) {
                            contaParcelada.parceiro = form.parceiro
                        } else {
                            contaParcelada.cliente = form.cliente
                        }
            
                        await FinanceiroService.create_conta(contaParcelada);
                    };
    
                    setParcelado(false);
                    setVencimentoConta('');
                    setValorConta('');
                    setQuantidadeParcelas(['1', '2']);
                } else {
                    await FinanceiroService.create_conta(form);
                }
            };

            await getItem();
            setMotivoBaixa("");
            setModalBaixarPeca(false);
            setAguardandoRetorno(false);
        } catch (error) {
            console.log(error)
        }

    };

    async function acrescentarPeca(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        try {
            setAguardandoRetorno(true);
            await EstoqueService.acrescentar_peca(otherId, pecaAtiva._id, form);
            await getItem();
            setModalAcrescentarPeca(false);
            setAguardandoRetorno(false);
        } catch (error) {
            console.error(error);
        }
    };

    async function deletarPeca() {
        try {
            await EstoqueService.delete_peca(otherId, pecaAtiva._id);
            await getItem();
            setModalDeletePeca(false);
        } catch (error) {
            console.log(error);
        }
    };

    async function editItem(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        (form.imagem === "" || form.imagem === undefined || form.imagem.size === 0) && delete form.imagem;

        try {
            setAguardandoRetorno(true);
            await EstoqueService.edit_item(otherId, form);
            await getItem();
            setModalEditarItem(false);
            setAguardandoRetorno(false);
            setFileName("");
        } catch (error) {
            console.log(error)
        }
    };

    async function getMedidas(array) {
        const todos = await array.sub_itens.map((item) => {return(item.medida)});
        setMedidas([...new Set(todos)]);
    };

    async function deletarItem() {
        setAguardandoRetorno(true);
        await EstoqueService.delete_item(item._id);
        setModalDeleteItem(false);
        navigate(`/estoque/${id}/itens`);
    };

    function ordemData(a, b){
        return a.changed_at < b.changed_at ? -1 : a.changed_at > b.changed_at ? 1 : 0;
    };

    function ordemAlfabetica(a, b){
        return a < b ? -1 : a > b ? 1 : 0;
    };

    document.title = `${carregado && item.descricao !== undefined ? item.descricao + " - " : ""} DNG Store`;

    return(
        <Fragment>
            { carregado ? 
                <main className='main main-item-estoque'>
                    <div className="conteudo-item-estoque">
                        <div className="cabecalho-conteudo">
                            <h2>{item.descricao}</h2>
                            <div className="buttons-conteudo">
                                <button className='button-red' onClick={() => navigate(`/estoque/${id}/itens`)}>Voltar</button>
                                <button className='button-blue' onClick={() => setModalEditarItem(true)}>Editar</button>
                                {userLogado.user.permission === 1 && <button className='button-darkred' onClick={() => setModalDeleteItem(true)}>Excluir</button>}
                                <button className='button-green' onClick={() => setModalNovaPeca(true)}>Adicionar</button>
                            </div>
                        </div>
                        <div className='informacoes-item-estoque'>
                            {item.imagem ? <img src={`https://assets.usedng.com.br/${item.imagem}`} alt={`Imagem do item cadastrado`}/> : <div className='no-image-item-estoque'>Não existe uma imagem para esse item</div>}
                            <div className='dados-item-estoque'>
                                <h4>{item.descricao}</h4>
                                <ul className='cards-item-estoque'>
                                    <li>
                                        <FontAwesomeIcon icon={faListUl}/>
                                        <div className='info-card-item-estoque'>
                                            <h5>{item.sub_itens.length}</h5>
                                            <h6>Peças cadastradas</h6>
                                        </div>
                                    </li>
                                    <li>
                                        <FontAwesomeIcon icon={faTShirt}/>
                                        <div className='info-card-item-estoque'>
                                            {(() => {
                                                let quantidade = 0;

                                                item.sub_itens.forEach(element => {
                                                    quantidade = quantidade + element.quantidade;
                                                });

                                                return(<h5>{quantidade}</h5>)
                                            })()}
                                            <h6>Peças em estoque</h6>
                                        </div>
                                    </li>
                                    <li>
                                        <FontAwesomeIcon icon={faRuler}/>
                                        <div className='info-card-item-estoque'>
                                            {(() => {
                                                const medidas = {};

                                                item.sub_itens.forEach(element => {
                                                    const medida = element.medida;
                                                    medidas[medida] = true;
                                                })

                                                return(<h5>{Object.keys(medidas).length}</h5>)
                                            })()}
                                            <h6>Medidas cadastradas</h6>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='estoque-sub-itens'>
                            <h3>Peças</h3>
                            <div className='conteudo-sub-itens-estoque'>
                                { item.sub_itens
                                // .filter((a) => a.status.at(-1).situacao !== "Baixado")
                                .length === 0 ?
                                    <div className='btn-adicionar-peca'>
                                        <button className='button-green' onClick={() => setModalNovaPeca(true)}><FontAwesomeIcon icon={faPlus}/>Cadastrar peça</button>
                                    </div>
                                :
                                    medidas.sort(ordemAlfabetica).map((m, ind) => (
                                        <div className='categoria-medida-estoque' key={ind}>
                                            <h4>{m}</h4>
                                            <ul>
                                                { item.sub_itens.filter(({ medida }) => medida === m).map((i, index) => (
                                                    <li key={index} onClick={() => {setPecaAtiva(i); setModalPeca(true)}}>
                                                        {i.imagem ? <img src={`https://assets.usedng.com.br/${i.imagem}`} alt={`Imagem da peça em estoque`} loading='lazy'/> : <div className='no-image-sub-item-estoque'><FontAwesomeIcon icon={faXmark}/></div>}
                                                        <div className='info-sub-item-estoque'>
                                                            <div className='dados-sub-item-estoque'>
                                                                <FontAwesomeIcon icon={faDollarSign}/>
                                                                <NumericFormat value={i.valor} displayType="text" decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale renderText={(value) => <Fragment>{value}</Fragment>}/>
                                                            </div>
                                                            <div className='dados-sub-item-estoque'>
                                                                <FontAwesomeIcon icon={faTruckRampBox}/>
                                                                <NumericFormat value={i.valor_frete} displayType="text" decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale renderText={(value) => <Fragment>{value}</Fragment>}/>
                                                            </div>
                                                            <div className='dados-sub-item-estoque'>
                                                                <p>{i.quantidade === 1 ? i.quantidade + " unidade" : i.quantidade + " unidades"}</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        {/* <div className='historico-sub-itens-estoque'>
                            <h3>Movimentações</h3>
                        </div> */}
                    </div>
                </main>
            :
                <Loading/>
            }
            {modalEditarItem ? (
                <Modal titulo={`Editar ${item.descricao}`} width="40%" onClose={() => {setModalEditarItem(false); setFileName("")}}>
                    <form onSubmit={editItem}>
                        <div id="campos-novo-item-estoque">
                            <div className="campo-form campo-100">
                                <label htmlFor="descricao">Descrição</label>
                                <input type="text" name="descricao" id="descricao" autoComplete='off' defaultValue={item.descricao} required/>
                            </div>
                            <div className="campo-form campo-100">
                                <input type="file" name="imagem" id="imagem" accept="image/*" onChange={(e) => {const file = e.target.files[0]; file ? setFileName(file.name) : setFileName("")}}/>
                                <label htmlFor="imagem" className='file-upload-label'>
                                    <FontAwesomeIcon icon={faCloudArrowUp}/>
                                    {fileName === "" ? "Clique para alterar a foto" : fileName}
                                </label>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalEditarItem(false); setFileName("")}}>Cancelar</button>
                            {aguardandoRetorno ? <button type="button" className="button-green">Aguarde<FontAwesomeIcon icon={faSpinner} spinPulse style={{marginLeft: ".5em"}}/></button> : <button type="submit" className="button-green">Alterar</button>}
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalDeleteItem ? (
                <ModalDangerSmall mensagem={['Deseja excluir o item', <br/>,<span>{item.descricao}</span>,"?"]} onClose={() => {setModalDeleteItem(false)}}>
                    <Fragment>
                        <div className='buttons-form' key={'1'}>
                            <button type="button" className="button-red" onClick={() => {setModalDeleteItem(false)}}>Cancelar</button>
                            <button type="submit" className="button-blue" onClick={() => {deletarItem()}}>Excluir</button>
                        </div>
                    </Fragment>
                </ModalDangerSmall>
            ): null}
            {modalNovaPeca ? (
                <Modal titulo={`Adicionar peça ao estoque`} width="30%" onClose={() => {setModalNovaPeca(false); setFileName("")}}>
                    <form onSubmit={createPeca}>
                        <div id="campos-novo-sub-item-estoque">
                            <div className="campo-form campo-50">
                                <label htmlFor="medida">Medida</label>
                                <input type="text" name="medida" id="medida" required/>
                            </div>
                            <div className="campo-form campo-50">
                                <label htmlFor="quantidade">Quantidade</label>
                                <input type="number" name="quantidade" id="quantidade" required/>
                            </div>
                            <div className="campo-form campo-50">
                                <label htmlFor="valor">Valor da peça</label>
                                <NumericFormat id='valor' name='valor' decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale autoComplete='off' required/>
                            </div>
                            <div className="campo-form campo-50">
                                <label htmlFor="valor_frete">Valor do frete</label>
                                <NumericFormat id='valor_frete' name='valor_frete' placeholder='Frete ÷ Peças' decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale autoComplete='off' required/>
                            </div>
                            <div className="campo-form campo-100">
                                <input type="file" name="imagem" id="imagem" accept="image/*" onChange={(e) => {const file = e.target.files[0]; file ? setFileName(file.name) : setFileName("")}}/>
                                <label htmlFor="imagem" className='file-upload-label'>
                                    <FontAwesomeIcon icon={faCloudArrowUp}/>
                                    {fileName === "" ? "Selecione uma foto" : fileName}
                                </label>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalNovaPeca(false); setFileName("")}}>Cancelar</button>
                            {aguardandoRetorno ? <button type="button" className="button-green">Aguarde<FontAwesomeIcon icon={faSpinner} spinPulse style={{marginLeft: ".5em"}}/></button> : <button type="submit" className="button-green">Adicionar</button>}
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalPeca ? (
                <Modal titulo='Visualizar peça' width="40%" onClose={() => {setModalPeca(false)}}>
                    <div id='modal-peca'>
                        {pecaAtiva.imagem ? <img src={`https://assets.usedng.com.br/${pecaAtiva.imagem}`} alt={`Imagem da peça em estoque`} loading='lazy'/> : <div className='no-image-sub-item-estoque'><FontAwesomeIcon icon={faXmark}/></div>}
                        <div className="info-modal">
                            <div className="campo-info campo-50">
                                <h4>Medida:</h4>
                                <h5>{pecaAtiva.medida}</h5>
                            </div>
                            <div className="campo-info campo-50">
                                <h4>Quantidade:</h4>
                                <h5>{pecaAtiva.quantidade}</h5>
                            </div>
                            <div className="campo-info campo-50">
                                <h4>Valor:</h4>
                                <NumericFormat value={pecaAtiva.valor} displayType="text" decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale renderText={(value) => <h5>{value}</h5>}/>
                            </div>
                            <div className="campo-info campo-50">
                                <h4>Valor frete:</h4>
                                <NumericFormat value={pecaAtiva.valor_frete} displayType="text" decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale renderText={(value) => <h5>{value}</h5>}/>
                            </div>
                        </div>
                        <hr/>
                        <div className='informacoes-historico-peca'>
                            <ul>
                                <h3>Histórico</h3>
                                {pecaAtiva.logs.sort(ordemData).map((item, index) => (
                                    <li key={index}>
                                        <Moment format='DD/MM/YY [às] HH:mm'>{item.changed_at}</Moment> - {item.situacao}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className='buttons-info'>
                        <button type="reset" className="button-red" onClick={() => {setModalPeca(false)}}>Cancelar</button>
                        <button type="button" className="button-blue" onClick={() => {setModalPeca(false); setModalEditarPeca(true);}}>Editar</button>
                        <button type="button" className="button-darkred" onClick={() => {setModalPeca(false); setModalDeletePeca(true)}}>Excluir</button>
                        {/* { pecaAtiva.status.at(-1).situacao !== "Liquidado" ?  */}
                        <button type="button" className="button-green" onClick={() => { setModalPeca(false); setModalBaixarPeca(true)}}>Baixar</button>
                        <button type="button" className="button-green" onClick={() => { setModalPeca(false); setModalAcrescentarPeca(true)}}>Adicionar</button>
                         {/* : null} */}
                    </div>
                </Modal>
            ): null}
            {modalEditarPeca ? (
                <Modal titulo={`Editar peça`} width="30%" onClose={() => {setModalEditarPeca(false); setFileName("")}}>
                    <form onSubmit={editPeca}>
                        <div id="campos-edit-sub-item-estoque">
                            <div className="campo-form campo-50">
                                <label htmlFor="medida">Medida</label>
                                <input type="text" name="medida" id="medida" defaultValue={pecaAtiva.medida} required/>
                            </div>
                            <div className="campo-form campo-50">
                                <label htmlFor="quantidade">Quantidade</label>
                                <input type="number" name="quantidade" id="quantidade" defaultValue={pecaAtiva.quantidade} required/>
                            </div>
                            <div className="campo-form campo-50">
                                <label htmlFor="valor">Valor da peça</label>
                                <NumericFormat id='valor' name='valor' value={pecaAtiva.valor} decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale autoComplete='off' required/>
                            </div>
                            <div className="campo-form campo-50">
                                <label htmlFor="valor_frete">Valor do frete</label>
                                <NumericFormat id='valor_frete' name='valor_frete' value={pecaAtiva.valor_frete} placeholder='Frete ÷ Peças' decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale autoComplete='off' required/>
                            </div>
                            <div className="campo-form campo-100">
                                <input type="file" name="imagem" id="imagem" accept="image/*" onChange={(e) => {const file = e.target.files[0]; file ? setFileName(file.name) : setFileName("")}}/>
                                <label htmlFor="imagem" className='file-upload-label'>
                                    <FontAwesomeIcon icon={faCloudArrowUp}/>
                                    {fileName === "" ? "Alterar a foto" : fileName}
                                </label>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalEditarPeca(false); setFileName(""); setModalPeca(true)}}>Cancelar</button>
                            {aguardandoRetorno ? <button type="button" className="button-green">Aguarde<FontAwesomeIcon icon={faSpinner} spinPulse style={{marginLeft: ".5em"}}/></button> : <button type="submit" className="button-green">Salvar</button>}
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalBaixarPeca ? (
            <Modal titulo={`Baixar peça`} width={motivoBaixa === "Venda" ? "50%" : "30%"} onClose={() => {setModalBaixarPeca(false); setMotivoBaixa("");}}>
                    <form onSubmit={baixarPeca}>
                        <div id="campos-baixar-peca-estoque">
                            <div className="campo-form campo-50">
                                <label htmlFor="motivo">Motivo</label>
                                <select name='motivo' id='motivo' defaultValue="" required onChange={(e) => setMotivoBaixa(e.target.value)}>
                                    <option value="" disabled>Selecione</option>
                                    <option>Venda</option>
                                    <option>Divulgação</option>
                                    <option>Brinde / Presente</option>
                                    <option>Roubo / Furto</option>
                                    <option>Perda</option>
                                    <option>Outro</option>
                                </select>
                            </div>
                            <div className="campo-form campo-50">
                                <label htmlFor="quantidade">Quantidade</label>
                                <input type='number' name='quantidade' id='quantidade' max={pecaAtiva.quantidade} required/>
                            </div>
                            { motivoBaixa === "Outro" ?
                                <div className="campo-form campo-100">
                                    <label htmlFor="descricao_motivo">Descrição do motivo</label>
                                    <input type='text' name='descricao_motivo' id='descricao_motivo' required/>
                                </div>
                            :
                                null
                            }
                            {motivoBaixa === "Venda" ? 
                                <Fragment>
                                    <div className="campo-form campo-50">
                                        <label htmlFor="parceiro">Cliente</label>
                                        <input type='text' name='cliente' id='cliente' required/>
                                    </div>
                                    <div className="campo-form campo-50">
                                        <label htmlFor="descricao">Descrição</label>
                                        <input type="text" name="descricao" id="descricao"/>
                                    </div>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="parcelado">Parcelado</label>
                                        <select name='parcelado' id='parcelado' defaultValue="false" onChange={(e) => {setParcelado(e.target.value === "true" ? true : false);}}>
                                            <option value={false}>Não</option>
                                            <option value={true}>Sim</option>
                                        </select>
                                    </div>
                                    { !parcelado ? 
                                        <div className="campo-form campo-25">
                                            <label htmlFor="forma_pgto">Forma de Pgto</label>
                                            <select name='forma_pgto' id='forma_pgto' defaultValue="" required>
                                                <option value="" disabled>Selecione</option>
                                                <option>Boleto Bancário</option>
                                                <option>Cartão de Crédito</option>
                                                <option>Cartão de Débito</option>
                                                <option>Transferência Bancária</option>
                                                <option>Depósito Bancário</option>
                                                <option>Débito Automático</option>
                                                <option>Nota Promissória</option>
                                                <option>Cheque</option>
                                                <option>Troca/Permuta</option>
                                                <option>Outros</option>
                                            </select>
                                        </div>
                                    :
                                        <div className="campo-form campo-25">
                                            <label htmlFor="parcelas">Parcelas</label>
                                            <input type='number' id='parcelas' name='parcelas' min={2} defaultValue={quantidadeParcelas.length} onBlur={(e) => { e.target.value > 2 ? adicionarParcela(e.target.value) : adicionarParcela(2)}}/>
                                        </div>
                                    }
                                    <div className="campo-form campo-50">
                                        <label htmlFor="plano_contas">Plano de Contas</label>
                                        <select name='plano_contas' id='plano_contas' defaultValue="" required>
                                            <option value="" disabled>Selecione</option>
                                            {planos.filter(({tipo}) => tipo === "Entradas").map((item, index) => (
                                                <optgroup key={index} label={item.descricao}>
                                                    {item.subcategorias.map((s, i) => (
                                                        <option key={i} value={s.sub._id}>{s.sub.descricao}</option>
                                                    ))}
                                                </optgroup>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="documento">Documento</label>
                                        <input type='text' id='documento' name='documento' value={documentoConta} onChange={(e) => {setDocumentoConta(e.target.value);}}/>
                                    </div>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="lancamento">Data Lançamento</label>
                                        <input type='date' id='lancamento' name='lancamento'/>
                                    </div>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="vencimento">Data Vencimento</label>
                                        <input type='date' id='vencimento' name='vencimento' value={vencimentoConta} onChange={(e) => {setVencimentoConta(e.target.value)}}/>
                                    </div>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="valor">Valor</label>
                                        <NumericFormat id='valor' name='valor' decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale onBlur={(e) => setValorConta(e.target.value.match(/[\d,]+/g).join('').replace(',', '.'))}/>
                                    </div>
                                    <div className="campo-form campo-100">
                                        <label htmlFor="observacoes">Observação</label>
                                        <input type='text' id='observacoes' name='observacoes'/>
                                    </div>
                                    { parcelado ? 
                                        <Fragment>
                                            <h5>Parcelas</h5>
                                            {quantidadeParcelas.map((item, index) => (
                                                <div className={`container-inputs`} key={index}>
                                                    <div className="campo-form campo-25">
                                                        <label htmlFor={`vencimento${item}`}>Vencimento</label>
                                                        <input type="date" name={`vencimento${item}`} id={`vencimento${item}`} value={moment(vencimentoConta).add(item - 1, 'M').format("YYYY-MM-DD")}/>
                                                    </div>
                                                    <div className="campo-form campo-20">
                                                        <label htmlFor={`documento${item}`}>Documento</label>
                                                        <input type="text" name={`documento${item}`} id={`documento${item}`} value={`${documentoConta}/${item}-${quantidadeParcelas.length}`} readOnly/>
                                                    </div>
                                                    <div className="campo-form campo-25">
                                                        <label htmlFor={`valor${item}`}>Valor</label>
                                                        <NumericFormat id={`valor${item}`} name={`valor${item}`} decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale value={ valorConta/quantidadeParcelas.length}/>
                                                    </div>
                                                    <div className='campo-form campo-30'>
                                                        <label htmlFor={`forma_pgto${item}`}>Forma de Pgto</label>
                                                        <select name={`forma_pgto${item}`} id={`forma_pgto${item}`} defaultValue="" required>
                                                            <option value="" disabled>Selecione</option>
                                                            <option>Boleto Bancário</option>
                                                            <option>Cartão de Crédito</option>
                                                            <option>Cartão de Débito</option>
                                                            <option>Transferência Bancária</option>
                                                            <option>Depósito Bancário</option>
                                                            <option>Débito Automático</option>
                                                            <option>Nota Promissória</option>
                                                            <option>Cheque</option>
                                                            <option>Troca/Permuta</option>
                                                            <option>Outros</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            ))}
                                        </Fragment>
                                    :
                                        null
                                    }
                                </Fragment>
                            :
                                null
                            }
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalBaixarPeca(false); setModalPeca(true); setMotivoBaixa("");}}>Cancelar</button>
                            {aguardandoRetorno ? <button type="button" className="button-green">Aguarde<FontAwesomeIcon icon={faSpinner} spinPulse style={{marginLeft: ".5em"}}/></button> : <button type="submit" className="button-green">Baixar</button>}
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalAcrescentarPeca ? (
                <Modal titulo={`Acrescentar unidade`} width={"30%"} onClose={() => {setModalAcrescentarPeca(false);}}>
                    <form onSubmit={acrescentarPeca}>
                        <div id="campos-acrescentar-peca-estoque">
                            <div className="campo-form campo-100">
                                <label htmlFor="quantidade">Quantidade</label>
                                <input type='number' name='quantidade' id='quantidade' min="1" required/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalAcrescentarPeca(false); setModalPeca(true);}}>Cancelar</button>
                            {aguardandoRetorno ? <button type="button" className="button-green">Aguarde<FontAwesomeIcon icon={faSpinner} spinPulse style={{marginLeft: ".5em"}}/></button> : <button type="submit" className="button-green">Acrescentar</button>}
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalDeletePeca ? (
                <ModalDangerSmall mensagem={["Deseja excluir essa peça?"]} onClose={() => {setModalDeletePeca(false)}}>
                    <Fragment>
                        <div className='buttons-form' key={'1'}>
                            <button type="button" className="button-red" onClick={() => {setModalDeletePeca(false); setModalPeca(true)}}>Cancelar</button>
                            <button type="submit" className="button-blue" onClick={() => {deletarPeca()}}>Excluir</button>
                        </div>
                    </Fragment>
                </ModalDangerSmall>
            ): null}
        </Fragment>
    )
}