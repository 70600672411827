import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/pt-br'
import "../../../styles/fornecedores.scss";
import "../../../styles/reset.scss";
import FinanceiroService from '../../../services/financeiro';
import UsersService from '../../../services/users';
import Modal from "../../layouts/modal";
import Loading from '../../layouts/loading';
import jsonp from 'jsonp';
import { PatternFormat } from 'react-number-format';
import ModalDangerSmall from '../../layouts/modalDangerSmall';
Moment.globalLocale = 'pt-BR'

export default function ContentFornecedores() {
    const [userLogado, setUserLogado] = useState();
    const [parceiros, setParceiros] = useState();
    const [carregado, setCarregado] = useState(false);
    const [parceiroAtivo, setParceiroAtivo] = useState();
    const [modalNovoParceiro, setModalNovoParceiro] = useState(false);
    const [modalParceiro, setModalParceiro] = useState(false);
    const [modalEditarParceiro, setModalEditarParceiro] = useState(false);
    const [modalDeleteParceiro, setModalDeleteParceiro] = useState(false);
    const [cnpj, setCnpj] = useState([]);
    const [finds, setFinds] = useState("");
    const [pessoaAtiva, setPessoaAtiva] = useState("pj");
    const [itemAtivo, setItemAtivo] = useState("fornecedores");
    const [filtroSelecionado, setFiltroSelecionado] = useState(localStorage.getItem('filtro-ativo-parceiros') || 'clientes');
    const navigate = useNavigate();

    async function getCnpj(cnpj) {
        jsonp(`https://receitaws.com.br/v1/cnpj/${cnpj}`, null, function (err, data) {
            if (err) {
                console.error(err.message);
            } else {
                setCnpj(data);
            }
        });
    };

    async function getUserLogado() {
        const res = await UsersService.logado();
        setUserLogado(res.data);
    };

    async function getParceiros() {
        const res = await FinanceiroService.parceiros();
        setParceiros(res.data);
    };

    async function createParceiro(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        form.fornecedor === "sim" ? form.fornecedor = true : form.fornecedor = false;
        form.cliente === "sim" ? form.cliente = true : form.cliente = false;

        if (pessoaAtiva === "pj") {
            form.tipo = "pj"
        } else if (pessoaAtiva === 'pf') {
            form.tipo = "pf"
        }

        try {
            await FinanceiroService.create_parceiro(form);
            await getParceiros();
            setModalNovoParceiro(false);
            setCnpj("");
        } catch (error) {
            console.log(error)
        }
    };

    async function editParceiro(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        form.fornecedor === "sim" ? form.fornecedor = true : form.fornecedor = false;
        form.cliente === "sim" ? form.cliente = true : form.cliente = false;

        try {
            await FinanceiroService.edit_parceiro(parceiroAtivo._id, form);
            await getParceiros();
            setModalEditarParceiro(false);
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        (async () => {
            await getUserLogado();
            await getParceiros();
            setCarregado(true);
        })();
        // eslint-disable-next-line
    }, []);

    async function deletarParceiro() {
        await FinanceiroService.delete_parceiro(parceiroAtivo._id);
        setModalDeleteParceiro(false);
        getParceiros();
    }

    async function ativarParceiro() {
        await FinanceiroService.ativar_parceiro(parceiroAtivo._id);
        setModalParceiro(false);
        getParceiros();
    }

    async function inativarParceiro() {
        await FinanceiroService.inativar_parceiro(parceiroAtivo._id);
        setModalParceiro(false);
        getParceiros();
    }

    // FILTRO
    function filtro(a) {
        switch (true) {
            case (itemAtivo === "clientes"):
                if (filtroSelecionado === "ativos") {
                    return a.cliente === true && a.ativo === true;
                } else if (filtroSelecionado === "inativos") {
                    return a.cliente === true && a.ativo === false;
                } else {
                    return a.cliente === true;
                }
            case (itemAtivo === "fornecedores"):
                if (filtroSelecionado === "ativos") {
                    return a.fornecedor === true && a.ativo === true;
                } else if (filtroSelecionado === "inativos") {
                    return a.fornecedor === true && a.ativo === false;
                } else {
                    return a.fornecedor === true;
                }
            default:
            break;
        }
    }

    function busca(a) {
        if (finds === "") {
            return a
        } else {
            return a.razao_social.toLowerCase().includes(finds.toLowerCase()) || a.fantasia.toLowerCase().includes(finds.toLowerCase()) || a.cnpj.toLowerCase().includes(finds.toLowerCase()) || a.cidade.toLowerCase().includes(finds.toLowerCase())
        }
    }

    document.title = `Fornecedores - DNG Store`;

    return(
        <Fragment>
            { carregado ? 
                <main className='main'>
                    <div className="conteudo-parceiros">
                        <div className="cabecalho-conteudo">
                            <h2>Fornecedores</h2>
                            <div className="buttons-conteudo">
                                <button className="button-red" onClick={() => navigate(`/financeiro`)}>Voltar</button>
                                <button className="button-green" onClick={() => setModalNovoParceiro(true)}>Cadastrar</button>
                            </div>
                        </div>
                        {/* <div className='selecao-parceiros-financeiro'>
                            <div className={itemAtivo === "fornecedores" ? "item-ativo-parceiros" : ""} onClick={() => setItemAtivo("fornecedores")}>Fornecedores</div>
                            <div className={itemAtivo === "clientes" ? "item-ativo-parceiros" : ""} onClick={() => setItemAtivo("clientes")}>Clientes</div>
                        </div> */}
                        <div className='container-search'>
                            <select defaultValue={filtroSelecionado} onChange={e => {setFiltroSelecionado(e.target.value); localStorage.setItem('filtro-ativo-parceiros', e.target.value)}}>
                                <option value="todos">Todos</option>
                                <option value="ativos">Ativos</option>
                                <option value="inativos">Inativos</option>
                            </select>
                            <input type='text' placeholder='Buscar' onChange={e => {setFinds(e.target.value)}}/>
                            <FontAwesomeIcon icon={faSearch}/>
                        </div>
                        <div className="tabela-dados">
                            <div className="cabecalho-dados">
                                <div className="column-15">CNPJ / CPF</div>
                                <div className="column-40">Razão Social / Nome</div>
                                <div className="column-35">Nome Fantasia</div>
                                <div className="column-10">Cadastro</div>
                            </div>
                            <div className="corpo-dados corpo-parceiros">
                                { parceiros.filter(filtro).filter(busca).length === 0 ?
                                        <div className="column-zerado">
                                            Não foi encontrado nenhum resultado.
                                        </div>
                                    : (
                                        (parceiros.filter(filtro).filter(({excluido}) => excluido === false).slice(0).reverse().filter(busca).map((item, index) => (
                                            <div key={index} className="row-dados" onClick={() => {setParceiroAtivo(item); setModalParceiro(true)}}>
                                                <div className="column-15">
                                                    {item.cnpj}
                                                </div>
                                                <div className="column-40">
                                                    {item.razao_social}
                                                </div>
                                                <div className="column-35">
                                                    {item.fantasia}
                                                </div>
                                                <div className="column-10">
                                                    <Moment format="DD/MM/YYYY">
                                                        {item.created_at}
                                                    </Moment>
                                                </div>
                                            </div>
                                        )))
                                    )
                                }
                                <h5>Exibindo {parceiros.filter(filtro).filter(({excluido}) => excluido === false).filter(busca).length} {parceiros.filter(filtro).filter(({excluido}) => excluido === false).filter(busca).length === 1 ? "resultado" : "resultados"}</h5>
                            </div>
                        </div>
                    </div>
                </main>
            :
            <Loading>
                
            </Loading>
            }
            {modalNovoParceiro ? (
                <Modal titulo="Novo Parceiro" width="65%" onClose={() => {setModalNovoParceiro(false); setCnpj("")}}>
                    <form onSubmit={createParceiro}>
                        <div id="campos-novo-parceiro">
                            <div className="campo-form campo-25">
                                <label htmlFor="tipo-pessoa">Tipo</label>
                                <select name="tipo-pessoa" id="tipo-pessoa" defaultValue={pessoaAtiva} onChange={(e) => setPessoaAtiva(e.target.value)} required>
                                    <option value="pj">Pessoa Jurídica</option>
                                    <option value="pf">Pessoa Física</option>
                                </select>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="cnpj">{pessoaAtiva === "pj" ? "CNPJ" : "CPF"}</label>
                                {pessoaAtiva === "pf" ? <PatternFormat name='cnpj' id='cnpj' format="###.###.###-##"/> : <PatternFormat onBlur={(event) =>  (event.target.value.length >= 5 ? getCnpj(event.target.value.replace(/[./-]/g, "")) : setCnpj(""))} name='cnpj' id='cnpj' format="##.###.###/####-##"/>}
                                {/* <input onBlur={(event) =>  (event.target.value.length !== 0 ? getCnpj(event.target.value) : setCnpj(""))} type="number" name="cnpj" id="cnpj"/> */}
                            </div>
                            <div className="campo-form campo-50">
                                <label htmlFor="razao_social">{pessoaAtiva === "pj" ? "Razão Social" : "Nome"}</label>
                                <input type="text" name="razao_social" id="razao_social" defaultValue={cnpj["nome"]} autoComplete="off" required/>
                            </div>
                            { pessoaAtiva === "pj" ?
                                <div className="campo-form campo-40">
                                    <label htmlFor="fantasia">Fantasia</label>
                                    <input type="text" name="fantasia" id="fantasia" defaultValue={cnpj["fantasia"] === "" ? cnpj["nome"] : cnpj["fantasia"]}/>
                                </div>
                            :
                                null
                            }
                            <div className="campo-form campo-35">
                                <label htmlFor="email">Email</label>
                                <input type="text" name="email" id="email" defaultValue={cnpj["email"]}/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="telefone">Telefone</label>
                                <input type="text" name="telefone" id="telefone"/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="fornecedor">Fornecedor</label>
                                <select name="fornecedor" id="fornecedor" defaultValue="" required>
                                    <option value="" disabled>Selecione</option>
                                    <option value="sim">Sim</option>
                                    <option value="não">Não</option>
                                </select>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="cliente">Cliente</label>
                                <select name="cliente" id="cliente" defaultValue="" required>
                                    <option value="" disabled>Selecione</option>
                                    <option value="sim">Sim</option>
                                    <option value="não">Não</option>
                                </select>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="cep">CEP</label>
                                <PatternFormat name='cep' id='cep' value={cnpj["cep"]} format="##.###-###"/>
                            </div>
                            <div className={`campo-form ${pessoaAtiva === "pj" ? "campo-40" : "campo-65"}`}>
                                <label htmlFor="logradouro">Logradouro</label>
                                <input type="text" name="logradouro" id="logradouro" defaultValue={cnpj.length === 0 ? null : `${cnpj["logradouro"]}`}/>
                            </div>
                            <div className="campo-form campo-15">
                                <label htmlFor="numero">Número</label>
                                <input type="text" name="numero" id="numero" defaultValue={cnpj["numero"]}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="complemento">Complemento</label>
                                <input type="text" name="complemento" id="complemento" defaultValue={cnpj["complemento"]}/>
                            </div>
                            <div className={`campo-form ${pessoaAtiva === "pj" ? "campo-25" : "campo-30"}`}>
                                <label htmlFor="bairro">Bairro</label>
                                <input type="text" name="bairro" id="bairro" defaultValue={cnpj["bairro"]}/>
                            </div>
                            <div className={`campo-form ${pessoaAtiva === "pj" ? "campo-20" : "campo-30"}`}>
                                <label htmlFor="cidade">Cidade</label>
                                <input type="text" name="cidade" id="cidade" defaultValue={cnpj["municipio"]}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="estado">Estado</label>
                                <select name="estado" id="estado" defaultValue="" required>
                                    <option value="" disabled>Selecione</option>
                                    <option value="AC">Acre</option>
                                    <option value="AL">Alagoas</option>
                                    <option value="AM">Amazonas</option>
                                    <option value="AP">Amapá</option>
                                    <option value="BA">Bahia</option>
                                    <option value="CE">Ceará</option>
                                    <option value="DF">Distrito Federal</option>
                                    <option value="ES">Espirito Santo</option>
                                    <option value="GO">Goiás</option>
                                    <option value="MA">Maranhão</option>
                                    <option value="MT">Mato Grosso</option>
                                    <option value="MS">Mato Grosso do Sul</option>
                                    <option value="MG">Minas Gerais</option>
                                    <option value="PA">Pará</option>
                                    <option value="PB">Paraíba</option>
                                    <option value="PR">Paraná</option>
                                    <option value="PE">Pernambuco</option>
                                    <option value="PI">Piauí</option>
                                    <option value="RJ">Rio de Janeiro</option>
                                    <option value="RN">Rio Grande do Norte</option>
                                    <option value="RS">Rio Grande do Sul</option>
                                    <option value="SC">Santa Catarina</option>
                                    <option value="SP">São Paulo</option>
                                    <option value="SE">Sergipe</option>
                                    <option value="RO">Rondônia</option>
                                    <option value="RR">Roraima</option>
                                    <option value="TO">Tocantins</option>
                                </select>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalNovoParceiro(false); setCnpj("")}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalParceiro ? (
                <Modal titulo={itemAtivo === "fornecedores" ? "Fornecedor" : "Cliente"} width="60%" onClose={() => {setModalParceiro(false)}}>
                    <div className="info-modal modal-parceiro">
                        <div className="campo-info campo-25">
                            <h4>{parceiroAtivo.tipo === "pj" ? "CNPJ:" : "CPF:"}</h4>
                            <h5>{parceiroAtivo.cnpj}</h5>
                        </div>
                        <div className="campo-info campo-75">
                            <h4>{parceiroAtivo.tipo === "pj" ? "Razão Social:" : "Nome:"}</h4>
                            <h5>{parceiroAtivo.razao_social}</h5>
                        </div>
                        {parceiroAtivo.tipo === "pj" ?
                            <div className="campo-info campo-45">
                                <h4>Fantasia:</h4>
                                <h5>{parceiroAtivo.fantasia}</h5>
                            </div>
                        :
                            null
                        }
                        <div className={`campo-info ${parceiroAtivo.tipo === "pj" ? "campo-35" : "campo-60"}`}>
                            <h4>Email:</h4>
                            <h5>{parceiroAtivo.email !== "" ? parceiroAtivo.email : "--"}</h5>
                        </div>
                        <div className={`campo-info ${parceiroAtivo.tipo === "pj" ? "campo-20" : "campo-40"}`}>
                            <h4>Telefone:</h4>
                            <h5>{parceiroAtivo.telefone !== "" ? parceiroAtivo.telefone : "--"}</h5>
                        </div>
                        <div className="campo-info campo-20">
                            <h4>CEP:</h4>
                            <h5>{parceiroAtivo.cep}</h5>
                        </div>
                        <div className="campo-info campo-65">
                            <h4>Logradouro:</h4>
                            <h5>{parceiroAtivo.logradouro}</h5>
                        </div>
                        <div className="campo-info campo-15">
                            <h4>Número:</h4>
                            <h5>{parceiroAtivo.numero}</h5>
                        </div>
                        <div className="campo-info campo-30">
                            <h4>Complemento:</h4>
                            <h5>{parceiroAtivo.complemento !== "" ? parceiroAtivo.complemento : "--"}</h5>
                        </div>
                        <div className="campo-info campo-40">
                            <h4>Bairro:</h4>
                            <h5>{parceiroAtivo.bairro}</h5>
                        </div>
                        <div className="campo-info campo-30">
                            <h4>Cidade:</h4>
                            <h5>{parceiroAtivo.cidade}/{parceiroAtivo.estado}</h5>
                        </div>
                    </div>
                    <div className='buttons-info'>
                        <button type="reset" className="button-red" onClick={() => {setModalParceiro(false)}}>Cancelar</button>
                        <button type="button" className="button-blue" onClick={() => {setModalParceiro(false); setModalEditarParceiro(true)}}>Editar</button>
                        { parceiroAtivo.ativo ? <button type="button" className="button-red" onClick={() => {inativarParceiro()}}>Inativar</button> : <button type="button" className="button-green" onClick={() => {ativarParceiro()}}>Ativar</button>}
                        { userLogado.user.permission === 1 ? <button type="button" className="button-darkred" onClick={() => { setModalParceiro(false); setModalDeleteParceiro(true)}}>Excluir</button> : null}
                    </div>
                </Modal>
            ): null}
            {modalEditarParceiro ? (
                <Modal titulo={itemAtivo === "fornecedores" ? "Editar Fornecedor" : "Editar Cliente"} width="65%" onClose={() => {setModalEditarParceiro(false)}}>
                    <form onSubmit={editParceiro}>
                        <div id="campos-novo-parceiro">
                            <div className="campo-form campo-30">
                                <label htmlFor="cnpj">{parceiroAtivo.tipo === "pj" ? "CNPJ" : "CPF"}</label>
                                {parceiroAtivo.tipo === "pf" ? <PatternFormat value={parceiroAtivo.cnpj} name='cnpj' id='cnpj' format="###.###.###-##"/> : <PatternFormat value={parceiroAtivo.cnpj} name='cnpj' id='cnpj' format="##.###.###/####-##"/>}
                            </div>
                            <div className="campo-form campo-70">
                                <label htmlFor="razao_social">{parceiroAtivo.tipo === "pj" ? "Razão Social" : "Nome"}</label>
                                <input type="text" name="razao_social" id="razao_social" defaultValue={parceiroAtivo.razao_social} autoComplete="off"/>
                            </div>
                            { parceiroAtivo.tipo === "pj" ?
                                <div className="campo-form campo-40">
                                    <label htmlFor="fantasia">Fantasia</label>
                                    <input type="text" name="fantasia" id="fantasia" defaultValue={parceiroAtivo.fantasia}/>
                                </div>
                            :
                                null
                            }
                            <div className="campo-form campo-35">
                                <label htmlFor="email">Email</label>
                                <input type="text" name="email" id="email" defaultValue={parceiroAtivo.email}/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="telefone">Telefone</label>
                                <input type="text" name="telefone" id="telefone"/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="fornecedor">Fornecedor</label>
                                <select name="fornecedor" id="fornecedor" defaultValue={parceiroAtivo.fornecedor === true ? "sim" : "não"} required>
                                    <option value="default" disabled>Selecione</option>
                                    <option value="sim">Sim</option>
                                    <option value="não">Não</option>
                                </select>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="cliente">Cliente</label>
                                <select name="cliente" id="cliente" defaultValue={parceiroAtivo.cliente === true ? "sim" : "não"} required>
                                    <option value="default" disabled>Selecione</option>
                                    <option value="sim">Sim</option>
                                    <option value="não">Não</option>
                                </select>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="cep">CEP</label>
                                <PatternFormat name='cep' id='cep' value={parceiroAtivo.cep} format="##.###-###"/>
                            </div>
                            <div className={`campo-form ${parceiroAtivo.tipo === "pj" ? "campo-40" : "campo-65"}`}>
                                <label htmlFor="logradouro">Logradouro</label>
                                <input type="text" name="logradouro" id="logradouro" defaultValue={parceiroAtivo.logradouro}/>
                            </div>
                            <div className="campo-form campo-15">
                                <label htmlFor="numero">Número</label>
                                <input type="text" name="numero" id="numero" defaultValue={parceiroAtivo.numero}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="complemento">Complemento</label>
                                <input type="text" name="complemento" id="complemento" defaultValue={parceiroAtivo.complemento}/>
                            </div>
                            <div className={`campo-form ${parceiroAtivo.tipo === "pj" ? "campo-25" : "campo-30"}`}>
                                <label htmlFor="bairro">Bairro</label>
                                <input type="text" name="bairro" id="bairro" defaultValue={parceiroAtivo.bairro}/>
                            </div>
                            <div className={`campo-form ${parceiroAtivo.tipo === "pj" ? "campo-20" : "campo-30"}`}>
                                <label htmlFor="cidade">Cidade</label>
                                <input type="text" name="cidade" id="cidade" defaultValue={parceiroAtivo.cidade}/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="estado">Estado</label>
                                <select name="estado" id="estado" defaultValue={parceiroAtivo.estado} required>
                                    <option value="default">Selecione</option>
                                    <option value="AC">Acre</option>
                                    <option value="AL">Alagoas</option>
                                    <option value="AM">Amazonas</option>
                                    <option value="AP">Amapá</option>
                                    <option value="BA">Bahia</option>
                                    <option value="CE">Ceará</option>
                                    <option value="DF">Distrito Federal</option>
                                    <option value="ES">Espirito Santo</option>
                                    <option value="GO">Goiás</option>
                                    <option value="MA">Maranhão</option>
                                    <option value="MT">Mato Grosso</option>
                                    <option value="MS">Mato Grosso do Sul</option>
                                    <option value="MG">Minas Gerais</option>
                                    <option value="PA">Pará</option>
                                    <option value="PB">Paraíba</option>
                                    <option value="PR">Paraná</option>
                                    <option value="PE">Pernambuco</option>
                                    <option value="PI">Piauí</option>
                                    <option value="RJ">Rio de Janeiro</option>
                                    <option value="RN">Rio Grande do Norte</option>
                                    <option value="RS">Rio Grande do Sul</option>
                                    <option value="SC">Santa Catarina</option>
                                    <option value="SP">São Paulo</option>
                                    <option value="SE">Sergipe</option>
                                    <option value="RO">Rondônia</option>
                                    <option value="RR">Roraima</option>
                                    <option value="TO">Tocantins</option>
                                </select>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalEditarParceiro(false); setModalParceiro(true)}}>Cancelar</button>
                            <button type="submit" className="button-green">Salvar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalDeleteParceiro ? (
                <ModalDangerSmall mensagem={['Deseja excluir o parceiro', <br/>,<span>{parceiroAtivo.razao_social}</span>,"?"]} onClose={() => {setModalDeleteParceiro(false)}}>
                    <Fragment>
                        <div className='buttons-form'>
                            <button type="button" className="button-red" onClick={() => {setModalDeleteParceiro(false); setModalParceiro(true)}}>Cancelar</button>
                            <button type="submit" className="button-blue" onClick={() => {deletarParceiro()}}>Excluir</button>
                        </div>
                    </Fragment>
                </ModalDangerSmall>
            ): null}
        </Fragment>
    )
}