import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, fas } from '@fortawesome/free-solid-svg-icons';
import { faCheck, faCircle, faMinus, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'
import "../../styles/home.scss";
import "../../styles/reset.scss";
import Moment from 'react-moment';
import 'moment-timezone';
import UsersService from '../../services/users';
// import TreinamentosService from '../../services/treinamentos';
import Modal from "../layouts/modal";
import ModalDangerSmall from '../layouts/modalDangerSmall';
import AtalhosService from '../../services/atalhos';

function ContentHome() {
    const [subCarregados, setSubCarregados] = useState(false);
    const [carregado, setCarregado] = useState(false);
    const [userLogado, setUserLogado] = useState();
    const [atalhos, setAtalhos] = useState();
    const [atalhosUser, setAtalhosUser] = useState();
    const [treinamentos, setTreinamentos] = useState();
    const [anotacoes, setAnotacoes] = useState();
    const [anotacaoAtiva, setAnotacaoAtiva] = useState();
    const [modalAddAtalho, setModalAddAtalho] = useState(false);
    const [modalCriarAnotacao, setModalCriarAnotacao] = useState(false);
    const [modalAnotacao, setModalAnotacao] = useState(false);
    const [modalAlterarStatus, setModalAlterarStatus] = useState(false);
    const [modalEditarAnotacao, setModalEditarAnotacao] = useState(false);
    const [modalDeleteAnotacao, setModalDeleteAnotacao] = useState(false);
    const [finds, setFinds] = useState("");
    const navigate = useNavigate();
    Moment.globalLocale = 'pt-BR';

    // async function getTreinamentos() {
    //     try {
    //         const res = await TreinamentosService.limit_treinamento(10);
    //         setTreinamentos(res.data);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    async function getAnotacoes() {
        try {
            const res = await UsersService.anotacoes();
            setAnotacoes(res.data);
        } catch (error) {
            console.log(error);
        }
    };

    async function getUserLogado() {
        try {
            const response = await UsersService.logado();
            setUserLogado(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    async function getAtalhosUser() {
        try {
            const res = await UsersService.atalhos();
            setAtalhosUser(res.data);
        } catch (error) {
            console.log(error);
        }
    }

    async function getAtalhos() {
        try {
            const res = await AtalhosService.index();
            setAtalhos(res.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        (async function() {
            await getAnotacoes();
            await getUserLogado();
            setCarregado(true);
            // await getTreinamentos();
            await getAtalhosUser();
            setSubCarregados(true);
            await getAtalhos();
        })();
    }, []);

    async function addAtalho(id) {
        let data = {};

        data.atalhoId = id;

        try {
            await UsersService.add_atalhos(data);
            getAtalhosUser();
            setModalAddAtalho(false);
        } catch (error) {
            console.log(error);
        }
    };

    async function removerAtalho(id) {
        try {
            await UsersService.delete_atalhos(id);
            getAtalhosUser();
        } catch (error) {
            console.log(error);
        }
    };

    const createAnotacao = async event => {
        event.preventDefault();

        const formData = new FormData(event.target);
        let data = Object.fromEntries(formData);

        data.status = {
            situacao: "Cadastrado",
            changed_at: new Date()
        }
        
        try {
            await UsersService.add_anotacoes(data);
            getAnotacoes();
            setModalCriarAnotacao(false);
        } catch (error) {
            console.log(error)
        }
    };

    const editAnotacao = async event => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData);

        try {
            await UsersService.editar_anotacoes(anotacaoAtiva._id, data);
            getAnotacoes();
            setModalEditarAnotacao(false);
        } catch (error) {
            console.log(error)
        }
    };

    async function alterarStatus(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);

        try {
            if (data.situacao !== undefined) {
                await UsersService.status_anotacoes(anotacaoAtiva._id, data);
                getAnotacoes();
                setModalAlterarStatus(false);
                setModalAnotacao(false);
            }
        } catch (error) {
            console.log(error)
        }
    };

    async function deleteAnotacao(id) {
        try {
            await UsersService.delete_anotacoes(anotacaoAtiva._id);
            getAnotacoes();
            setModalDeleteAnotacao(false);
        } catch (error) {
            console.log(error)
        }
    };

    // ORDEM ALFABÉTICA ATALHOS
    function ordemAlfabetica(a, b){
        return a.titulo < b.titulo ? -1 : a.titulo > b.titulo ? 1 : 0;
    };

    // FILTRO ATALHOS
    function busca(a) {
        if (finds === "") {
            return a
        } else {
            return a.titulo.toLowerCase().includes(finds.toLowerCase()) || a.url.toLowerCase().includes(finds.toLowerCase());
        }
    };

    function permissaoAtalhos(a) {
        return a.permission >= userLogado.user.permission;
    };

    document.title = 'Inicio - DNG Store';

    return(
        <Fragment>
            { carregado ? 
                <main id='main-home'>
                    <div className="texto-principal-home">
                        <h2>Olá, <span>{userLogado.user.name.split(' ').shift()}</span>!</h2>
                        <p>Seja bem vindo(a) ao sistema da DNG Store.</p>
                    </div>
                    <div className="atalhos-home">
                        <div className="cabecalho-box">
                            <h3>Espaço para adicionar informações</h3>
                        </div>
                        <ul className="lista-atalhos">
                            {/* { !subCarregados ? */}
                                <Fragment>
                                    <li style={{cursor: 'default'}}>
                                        <div className='square-div-placeholder placeholder-loading'/>
                                        <div className='line-div-placeholder placeholder-loading' style={{width: "80%"}}/>
                                    </li>
                                    <li style={{cursor: 'default'}}>
                                        <div className='square-div-placeholder placeholder-loading'/>
                                        <div className='line-div-placeholder placeholder-loading' style={{width: "80%"}}/>
                                    </li>
                                    <li style={{cursor: 'default'}}>
                                        <div className='square-div-placeholder placeholder-loading'/>
                                        <div className='line-div-placeholder placeholder-loading' style={{width: "80%"}}/>
                                    </li>
                                    <li style={{cursor: 'default'}}>
                                        <div className='square-div-placeholder placeholder-loading'/>
                                        <div className='line-div-placeholder placeholder-loading' style={{width: "80%"}}/>
                                    </li>
                                    <li style={{cursor: 'default'}}>
                                        <div className='square-div-placeholder placeholder-loading'/>
                                        <div className='line-div-placeholder placeholder-loading' style={{width: "80%"}}/>
                                    </li>
                                </Fragment>
                            {/* :
                                <Fragment>
                                    { atalhosUser.map((item, index) => (
                                        <li key={index} onClick={() => navigate(`${item.url}`)}>
                                            <button className='button-red' onClick={(event) => {event.stopPropagation(); removerAtalho(item._id)}}><FontAwesomeIcon icon={faXmark}/></button>
                                            <div className='icone-atalhos'>
                                                <FontAwesomeIcon icon={fas[item.icone]}/>
                                            </div>
                                            <p>{item.titulo}</p>
                                        </li>
                                    ))}
                                    { atalhosUser.length < 5 ? 
                                        <li className='add-atalho' onClick={() => setModalAddAtalho(true)}>
                                            <div className='icone-atalhos'>
                                                <FontAwesomeIcon icon={faPlus}/>
                                            </div>
                                            <p>Adicionar Atalho</p>
                                        </li>
                                    : null}
                                </Fragment>
                            } */}
                        </ul>
                    </div>
                    <div className="treinamentos-home">
                        <div className="cabecalho-box">
                            <h3>Espaço para adicionar informações</h3>
                        </div>
                        <ul>
                            {/* { !subCarregados ? */}
                                <Fragment>
                                    <li>
                                        <div className='rounded-div-placeholder placeholder-loading'/>
                                        <div className='line-div-placeholder placeholder-loading' style={{width: "45%", marginRight: "3.5%"}}/>
                                        <div className='line-div-placeholder placeholder-loading' style={{width: "25%", marginRight: "3.5%"}}/>
                                        <div className='line-div-placeholder placeholder-loading' style={{width: "15%"}}/>
                                    </li>
                                    <li>
                                        <div className='rounded-div-placeholder placeholder-loading'/>
                                        <div className='line-div-placeholder placeholder-loading' style={{width: "45%", marginRight: "3.5%"}}/>
                                        <div className='line-div-placeholder placeholder-loading' style={{width: "25%", marginRight: "3.5%"}}/>
                                        <div className='line-div-placeholder placeholder-loading' style={{width: "15%"}}/>
                                    </li>
                                    <li>
                                        <div className='rounded-div-placeholder placeholder-loading'/>
                                        <div className='line-div-placeholder placeholder-loading' style={{width: "45%", marginRight: "3.5%"}}/>
                                        <div className='line-div-placeholder placeholder-loading' style={{width: "25%", marginRight: "3.5%"}}/>
                                        <div className='line-div-placeholder placeholder-loading' style={{width: "15%"}}/>
                                    </li>
                                    <li>
                                        <div className='rounded-div-placeholder placeholder-loading'/>
                                        <div className='line-div-placeholder placeholder-loading' style={{width: "45%", marginRight: "3.5%"}}/>
                                        <div className='line-div-placeholder placeholder-loading' style={{width: "25%", marginRight: "3.5%"}}/>
                                        <div className='line-div-placeholder placeholder-loading' style={{width: "15%"}}/>
                                    </li>
                                    <li>
                                        <div className='rounded-div-placeholder placeholder-loading'/>
                                        <div className='line-div-placeholder placeholder-loading' style={{width: "45%", marginRight: "3.5%"}}/>
                                        <div className='line-div-placeholder placeholder-loading' style={{width: "25%", marginRight: "3.5%"}}/>
                                        <div className='line-div-placeholder placeholder-loading' style={{width: "15%"}}/>
                                    </li>
                                </Fragment>
                            {/* :
                                treinamentos?.length === 0 ?
                                    <li className='column-zerado'>Não existe nenhum treinamento cadastrado.</li>
                                :
                                    treinamentos.map((item, index) => (
                                        <li key={index}>
                                            <a href={`/treinamento/${item._id}`}>
                                                <div className='rounded-div-placeholder'><img alt={`Foto de perfil do instrutor`} onLoad={(e) => e.currentTarget.setAttribute('style', 'display: flex')} src={userLogado.url_images + item.dados_instrutor.instrutor.imagem}/></div>
                                                <h4>{item.titulo}</h4>
                                                <p>{item.dados_curso.titulo} - {item.dados_curso.tipo_formacao}</p>
                                                <h5 style={item.status.at(-1).situacao === "Finalizado" ? {backgroundColor: '#298129'}: {backgroundColor: "#007bff"}}>{item.status.at(-1).situacao}</h5>
                                            </a>
                                        </li>
                                    ))
                            } */}
                        </ul>
                    </div>
                    <div className='anotacoes-home'>
                        <div className="cabecalho-box">
                            <h3>Anotações</h3>
                        </div>
                        <ul>
                            <li className='add-anotacao' onClick={() => {setModalCriarAnotacao(true)}}>
                                <h4>Adicionar Anotação</h4>
                            </li>
                            {anotacoes.slice().reverse().map((item, index) => (
                                <li key={index} onClick={() => {setAnotacaoAtiva(item); setModalAnotacao(true)}}>
                                    <FontAwesomeIcon icon={item.status.at(-1).situacao === "Cadastrado" ? faMinus : item.status.at(-1).situacao === "Em andamento" ? faCircle : faCheck }/>
                                    <h4>{item.titulo}</h4>
                                </li>
                            ))}
                        </ul>
                    </div>
                </main>
            :
            <div className='loading'>
                <div className='load-circle'>
                    
                </div>
            </div>
            }
            {modalAddAtalho ? (
                <Modal titulo="Novo Atalho" width="45%" onClose={() => {setModalAddAtalho(false)}}>
                    <div id="campos-novo-atalho">
                        <div className='container-search'>
                            <input type='text' placeholder='Buscar' onChange={e => {setFinds(e.target.value)}}/>
                            <FontAwesomeIcon icon={faSearch}/>
                        </div>
                        <ul>
                            { atalhos.filter(permissaoAtalhos).filter(busca).sort(ordemAlfabetica).map((item, index) => (
                                <li key={index} onClick={() => addAtalho(item._id)}>
                                    <div className='icone-atalhos'>
                                        <FontAwesomeIcon icon={fas[item.icone]}/>
                                    </div>
                                    <p>{item.titulo}</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                </Modal>
            ): null}
            {modalCriarAnotacao ? (
                <Modal titulo="Nova Anotação" width="45%" onClose={() => {setModalCriarAnotacao(false)}}>
                    <form onSubmit={createAnotacao}>
                        <div id="campos-nova-anotacao">
                            <div className="campo-form campo-100">
                                <label htmlFor="titulo">Titulo</label>
                                <input type="text" name="titulo" id="titulo" autoComplete='off' required/>
                            </div>
                            <div className="campo-form campo-100">
                                <label htmlFor="descricao">Descrição</label>
                                <textarea type="text" name="descricao" id="descricao" autoComplete='off' rows="5" required/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalCriarAnotacao(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalAnotacao ? (
                <Modal titulo="Anotação" width="45%" onClose={() => {setModalAnotacao(false)}}>
                    <div className="info-modal">
                        <div className="campo-info campo-100">
                            <h4>Título:</h4>
                            <h5>{anotacaoAtiva.titulo}</h5>
                        </div>
                        <div className="campo-info campo-100">
                            <h4>Descrição:</h4>
                            <h5>{anotacaoAtiva.descricao}</h5>
                        </div>
                    </div>
                    <div className='buttons-info'>
                        <button type="reset" className="button-red" onClick={() => {setModalAnotacao(false)}}>Cancelar</button>
                        <button type="button" className="button-darkred" onClick={() => {setModalAnotacao(false); setModalDeleteAnotacao(true)}}>Excluir</button>
                        <button type="button" className="button-cyan" onClick={() => {setModalAnotacao(false); setModalEditarAnotacao(true)}}>Editar</button>
                        { anotacaoAtiva.status.at(-1).situacao !== "Finalizado" ? <button type="button" className="button-blue" onClick={() => {setModalAlterarStatus(true)}}>Alterar Situação</button> : null}
                    </div>
                </Modal>
            ): null}
            {modalAlterarStatus ? (
                <Modal titulo="Alterar Status" width="35%" onClose={() => {setModalAlterarStatus(false)}}>
                    <form onSubmit={alterarStatus}>
                        <div id="campos-nova-visita">
                            <div className="campo-form campo-100">
                                <label htmlFor="situacao">Situação</label>
                                <select name="situacao" id="situacao" defaultValue={'default'}>
                                    <option value="default" disabled>Selecione</option>
                                    {anotacaoAtiva.status.at(-1).situacao !== "Em andamento" ? <option value='Em andamento'>Em andamento</option> : null}
                                    <option value='Finalizado'>Finalizado</option>
                                </select>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalAlterarStatus(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Salvar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalEditarAnotacao ? (
                <Modal titulo="Editar Anotação" width="45%" onClose={() => {setModalEditarAnotacao(false)}}>
                    <form onSubmit={editAnotacao}>
                        <div id="campos-nova-anotacao">
                            <div className="campo-form campo-100">
                                <label htmlFor="titulo">Titulo</label>
                                <input type="text" name="titulo" id="titulo" autoComplete='off' defaultValue={anotacaoAtiva.titulo}/>
                            </div>
                            <div className="campo-form campo-100">
                                <label htmlFor="descricao">Descrição</label>
                                <textarea type="text" name="descricao" id="descricao" rows="5" autoComplete='off' defaultValue={anotacaoAtiva.descricao}/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalEditarAnotacao(false); setModalAnotacao(true)}}>Cancelar</button>
                            <button type="submit" className="button-green">Salvar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalDeleteAnotacao ? (
                <ModalDangerSmall mensagem={['Deseja excluir a anotação', <br/>,<span>{anotacaoAtiva.titulo}</span>,"?"]} onClose={() => {setModalDeleteAnotacao(false)}}>
                    <Fragment>
                        <div className='buttons-form'>
                            <button type="button" className="button-red" onClick={() => {setModalDeleteAnotacao(false); setModalAnotacao(true)}}>Cancelar</button>
                            <button type="submit" className="button-blue" onClick={() => {deleteAnotacao(anotacaoAtiva._id)}}>Excluir</button>
                        </div>
                    </Fragment>
                </ModalDangerSmall>
            ): null}
        </Fragment>
    )
};
   
export default ContentHome;