import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRotateLeft, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import 'moment-timezone';
import "../../../styles/excluidos.scss";
import "../../../styles/reset.scss";
// import SinistrosService from '../../../services/sinistros';
import ModalDangerSmall from '../../layouts/modalDangerSmall';
import UsersService from '../../../services/users';
// import ApolicesService from '../../../services/apolices';
// import VisitasService from '../../../services/visitas';

export default function ContentAdministracaoSeguradoras() {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [userAtivo, setUserAtivo] = useState();
    const [cursos, setCursos] = useState([]);
    const [chamados, setChamados] = useState([]);
    // const [visitas, setVisitas] = useState([]);
    const [treinamentos, setTreinamentos] = useState([]);
    const [treinamentoAtivo, setTreinamentoAtivo] = useState();
    const [cursoAtivo, setCursoAtivo] = useState();
    const [chamadoAtivo, setChamadoAtivo] = useState();
    // const [visitaAtiva, setVisitaAtiva] = useState();
    const [modalDeleteUsuario, setModalDeleteUsuario] = useState(false);
    const [modalDeleteTreinamento, setModalDeleteTreinamento] = useState(false);
    const [modalDeleteCurso, setModalDeleteCurso] = useState(false);
    const [modalDeleteChamado, setModalDeleteChamado] = useState(false);
    // const [modalDeleteVisita, setModalDeleteVisita] = useState(false);

    async function getUsuarios() {
        const res = await UsersService.index();
        setUsers(res.data.user.filter(({ excluido }) => excluido === true));
    };

    // async function getTreinamentos() {
    //     const res = await TreinamentosService.index();
    //     setTreinamentos(res.data.filter(({ excluido }) => excluido === true));
    // };

    // async function getCursos() {
    //     const res = await CursosService.index();
    //     setCursos(res.data.filter(({ excluido }) => excluido === true));
    // };

    // async function getChamados() {
    //     const res = await ChamadosService.index();
    //     setChamados(res.data.filter(({ excluido }) => excluido === true));
    // };

    // async function getVisitas() {
    //     const res = await VisitasService.index();
    //     setVisitas(res.data.filter(({ excluido }) => excluido === true));
    // };

    useEffect(() => {
        getUsuarios();
        // getTreinamentos();
        // getCursos();
        // getChamados();
        // getVisitas();
    }, []);

// USUÁRIOS
    async function restoreUsuario(id) {
        await UsersService.habilitar(id);
        getUsuarios();
    }

    async function deleteUsuario(id) {
        await UsersService.deletar(id);
        getUsuarios();
        setModalDeleteUsuario(false);
    }

// TREINAMENTOS
    // async function restoreTreinamento(id) {
    //     await TreinamentosService.restaurar_treinamento(id);
    //     getTreinamentos();
    // }

    // async function deleteTreinamento(id) {
    //     await TreinamentosService.delete_treinamento(id);
    //     getTreinamentos();
    //     setModalDeleteTreinamento(false);
    // }

// CURSOS
    // async function restoreCurso(id) {
    //     await CursosService.restaurar_curso(id);
    //     getCursos();
    // }

    // async function deleteCurso(id) {
    //     await CursosService.delete_curso(id);
    //     getCursos();
    //     setModalDeleteCurso(false);
    // }

// SEGURADORAS
    // async function restoreChamado(id) {
    //     await ChamadosService.habilitar(id);
    //     getChamados();
    // }

    // async function deleteChamado(id) {
    //     await ChamadosService.delete(id);
    //     getChamados();
    //     setModalDeleteChamado(false);
    // }

// // VISITAS
//     async function restoreVisita(id) {
//         await VisitasService.ativar(id);
//         getVisitas();
//     }

//     async function deleteVisita(id) {
//         await VisitasService.delete(id);
//         getVisitas();
//         setModalDeleteVisita(false);
//     }

    document.title = `Excluidos - DNG Store`;

    return(
        <Fragment>
            <main id='main-excluidos'>
                <div className="cabecalho-conteudo">
                    <h2>Excluidos</h2>
                    <div className="buttons-conteudo">
                        <button className="button-red" onClick={()=> navigate(`/administracao`)}>Voltar</button>
                        <button className="button-blue" onClick={() => {window.print()}}>Imprimir</button>
                    </div>
                </div>
                <div className="tabela-dados">
                    <div className='informacoes-dados'>
                        <h3>Usuários</h3>
                    </div>
                    <div className="cabecalho-dados">
                        <div className="column-50">Nome</div>
                        <div className="column-45">E-mail</div>
                    </div>
                    <div className="corpo-dados corpo-usuarios">
                        { users.length === 0 ? 
                            <div className="row-dados">
                                <div className="column-zerado">
                                    Não foi encontrado nenhum resultado.
                                </div>
                            </div>
                            : (
                                (users.slice(0).reverse().map((users) => (
                                    <Fragment>
                                        <div key={users._id} className="row-dados">
                                            <div className="column-50">
                                                {users.name}
                                            </div>
                                            <div className="column-45">
                                                {users.email}
                                            </div>
                                            <div className="column-icon column-icon-green">
                                                <FontAwesomeIcon icon={faArrowRotateLeft} onClick={() => restoreUsuario(users._id)}/>
                                            </div>
                                            <div className="column-icon column-icon-red">
                                                <FontAwesomeIcon icon={faTrashCan} onClick={() => {setUserAtivo(users); setModalDeleteUsuario(true)}}/>
                                            </div>
                                        </div>
                                    </Fragment>
                                )))
                            )
                        }
                        <h5>Exibindo {users.length} {users.length === 1 ? "resultado" : "resultados"}</h5>
                    </div>
                </div>
                {/* <div className="tabela-dados">
                    <div className='informacoes-dados'>
                        <h3>Treinamentos</h3>
                    </div>
                    <div className="cabecalho-dados">
                        <div className="column-25">Titulo</div>
                        <div className="column-25">Curso</div>
                        <div className="column-25">Instrutor</div>
                        <div className="column-20">Local</div>
                    </div>
                    <div className="corpo-dados corpo-seguradoras">
                        { treinamentos.length === 0 ? 
                            <div className="row-dados">
                                <div className="column-zerado">
                                    Não foi encontrado nenhum resultado.
                                </div>
                            </div>
                            : (
                                (treinamentos.slice(0).reverse().map((item) => (
                                    <Fragment>
                                        <div key={item._id} className="row-dados">
                                            <div className="column-25">
                                                {item.titulo}
                                            </div>
                                            <div className="column-25">
                                                {item.curso.titulo}
                                            </div>
                                            <div className="column-25">
                                                {item.instrutor.name}
                                            </div>
                                            <div className="column-20">
                                                {item.local.nome}
                                            </div>
                                            <div className="column-icon column-icon-green">
                                                <FontAwesomeIcon icon={faArrowRotateLeft} onClick={() => restoreTreinamento(item._id)}/>
                                            </div>
                                            <div className="column-icon column-icon-red">
                                                <FontAwesomeIcon icon={faTrashCan} onClick={() => {setTreinamentoAtivo(item); setModalDeleteTreinamento(true)}}/>
                                            </div>
                                        </div>
                                    </Fragment>
                                )))
                            )
                        }
                        <h5>Exibindo {treinamentos.length} {treinamentos.length === 1 ? "resultado" : "resultados"}</h5>
                    </div>
                </div>
                <div className="tabela-dados">
                    <div className='informacoes-dados'>
                        <h3>Cursos</h3>
                    </div>
                    <div className="cabecalho-dados">
                        <div className="column-30">Titulo / Tipo de Formação</div>
                        <div className="column-65">Descritivo</div>
                    </div>
                    <div className="corpo-dados corpo-apolices">
                        { cursos.length === 0 ? 
                            <div className="row-dados">
                                <div className="column-zerado">
                                    Não foi encontrado nenhum resultado.
                                </div>
                            </div>
                            : (
                                (cursos.slice(0).reverse().map((item) => (
                                    <Fragment>
                                        <div key={item._id} className="row-dados">
                                            <div className="column-30">
                                                {item.titulo} / {item.tipo_formacao} {item.info_complementar !== "" ? `(${item.info_complementar})` : null}`
                                            </div>
                                            <div className="column-65">
                                                {item.descritivo}
                                            </div>
                                            <div className="column-icon column-icon-green">
                                                <FontAwesomeIcon icon={faArrowRotateLeft} onClick={() => restoreCurso(item._id)}/>
                                            </div>
                                            <div className="column-icon column-icon-red">
                                                <FontAwesomeIcon icon={faTrashCan} onClick={() => {setModalDeleteCurso(true); setCursoAtivo(item)}}/>
                                            </div>
                                        </div>
                                    </Fragment>
                                )))
                            )
                        }
                        <h5>Exibindo {cursos.length} {cursos.length === 1 ? "resultado" : "resultados"}</h5>
                    </div>
                </div>
                <div className="tabela-dados">
                    <div className='informacoes-dados'>
                        <h3>Chamados</h3>
                    </div>
                    <div className="cabecalho-dados">
                        <div className="column-20">Titulo</div>
                        <div className="column-15">Área</div>
                        <div className="column-50">Descrição</div>
                        <div className="column-10">Criado em</div>
                    </div>
                    <div className="corpo-dados corpo-apolices">
                        { chamados.length === 0 ? 
                            <div className="row-dados">
                                <div className="column-zerado">
                                    Não foi encontrado nenhum resultado.
                                </div>
                            </div>
                            : (
                                (chamados.slice(0).reverse().map((item) => (
                                    <Fragment>
                                        <div key={item._id} className="row-dados">
                                            <div className="column-20">
                                                {item.titulo}
                                            </div>
                                            <div className="column-15">
                                                {item.area}
                                            </div>
                                            <div className="column-50">
                                                {item.descricao}
                                            </div>
                                            <div className="column-10">
                                                <Moment format='DD/MM/YYYY'>{item.created_at}</Moment>
                                            </div>
                                            <div className="column-icon column-icon-green">
                                                <FontAwesomeIcon icon={faArrowRotateLeft} onClick={() => restoreChamado(item._id)}/>
                                            </div>
                                            <div className="column-icon column-icon-red">
                                                <FontAwesomeIcon icon={faTrashCan} onClick={() => {setModalDeleteChamado(true); setChamadoAtivo(item)}}/>
                                            </div>
                                        </div>
                                    </Fragment>
                                )))
                            )
                        }
                        <h5>Exibindo {chamados.length} {chamados.length === 1 ? "resultado" : "resultados"}</h5>
                    </div>
                </div> */}
                {/* <div className="tabela-dados">
                    <div className='informacoes-dados'>
                        <h3>Seguradoras</h3>
                    </div>
                    <div className="cabecalho-dados">
                        <div className="column-50">Razão Social</div>
                        <div className="column-45">CNPJ</div>
                    </div>
                    <div className="corpo-dados corpo-seguradoras">
                        { seguradoras.length === 0 ? 
                            <div className="row-dados">
                                <div className="column-zerado">
                                    Não foi encontrado nenhum resultado.
                                </div>
                            </div>
                            : (
                                (seguradoras.slice(0).reverse().map((item) => (
                                    <Fragment>
                                        <div key={item._id} className="row-dados">
                                            <div className="column-50">
                                                {item.razao}
                                            </div>
                                            <div className="column-45">
                                                {item.cnpj}
                                            </div>
                                            <div className="column-icon column-icon-green">
                                                <FontAwesomeIcon icon={faArrowRotateLeft} onClick={() => restoreSeguradora(item._id)}/>
                                            </div>
                                            <div className="column-icon column-icon-red">
                                                <FontAwesomeIcon icon={faTrashCan} onClick={() => {setModalDeleteSeguradora(true); setSeguradoraAtiva(item)}}/>
                                            </div>
                                        </div>
                                    </Fragment>
                                )))
                            )
                        }
                        <h5>Exibindo {seguradoras.length} {seguradoras.length === 1 ? "resultado" : "resultados"}</h5>
                    </div>
                </div>
                <div className="tabela-dados">
                    <div className='informacoes-dados'>
                        <h3>Visitas</h3>
                    </div>
                    <div className="cabecalho-dados">
                        <div className="column-50">Empresa</div>
                        <div className="column-15">Dia da Visita</div>
                        <div className="column-15">Horário de início</div>
                        <div className="column-15">Horário de saída</div>
                    </div>
                    <div className="corpo-dados corpo-visitas">
                        { visitas.length === 0 ? 
                            <div className="row-dados">
                                <div className="column-zerado">
                                    Não foi encontrado nenhum resultado.
                                </div>
                            </div>
                            : (
                                (visitas.slice(0).reverse().map((item) => (
                                    <Fragment>
                                        <div key={item._id} className="row-dados">
                                            <div className="column-50">
                                                {item.empresa}
                                            </div>
                                            <div className="column-15">
                                                <Moment add={{ hours: 3 }} format="DD [de] MMMM">{item.dia}</Moment>
                                            </div>
                                            <div className="column-15">
                                                <Moment format='HH:mm'>{item.inicio}</Moment>
                                            </div>
                                            <div className="column-15">
                                                <Moment format='HH:mm'>{item.fim}</Moment>
                                            </div>
                                            <div className="column-icon column-icon-green">
                                                <FontAwesomeIcon icon={faArrowRotateLeft} onClick={() => restoreVisita(item._id)}/>
                                            </div>
                                            <div className="column-icon column-icon-red">
                                                <FontAwesomeIcon icon={faTrashCan} onClick={() => {setModalDeleteVisita(true); setVisitaAtiva(item)}}/>
                                            </div>
                                        </div>
                                    </Fragment>
                                )))
                            )
                        }
                        <h5>Exibindo {visitas.length} {visitas.length === 1 ? "resultado" : "resultados"}</h5>
                    </div>
                </div> */}
            </main>
            {modalDeleteUsuario ? (
                <ModalDangerSmall mensagem={['Deseja excluir definitivamente o usuário', <br/>,<span>{userAtivo.name}</span>,"?"]} onClose={() => {setModalDeleteUsuario(false)}}>
                    <Fragment>
                        <div className='buttons-form'>
                            <button type="button" className="button-red" onClick={() => {setModalDeleteUsuario(false)}}>Cancelar</button>
                            <button type="submit" className="button-blue" onClick={() => {deleteUsuario(userAtivo._id)}}>Excluir</button>
                        </div>
                    </Fragment>
                </ModalDangerSmall>
            ): null}
            {/* {modalDeleteTreinamento ? (
                <ModalDangerSmall mensagem={['Deseja excluir definitivamente o treinamento', <br/>,<span>{treinamentoAtivo.titulo}</span>,"?"]} onClose={() => {setModalDeleteTreinamento(false)}}>
                    <Fragment>
                        <div className='buttons-form'>
                            <button type="button" className="button-red" onClick={() => {setModalDeleteTreinamento(false)}}>Cancelar</button>
                            <button type="submit" className="button-blue" onClick={() => {deleteTreinamento(treinamentoAtivo._id)}}>Excluir</button>
                        </div>
                    </Fragment>
                </ModalDangerSmall>
            ): null} */}
            {/* {modalDeleteApolice ? (
                <ModalDangerSmall mensagem={['Deseja excluir definitivamente a apolice', <br/>,<span>{apoliceAtiva.titulo}</span>,"?"]} onClose={() => {setModalDeleteApolice(false)}}>
                    <Fragment>
                        <div className='buttons-form'>
                            <button type="button" className="button-red" onClick={() => {setModalDeleteApolice(false)}}>Cancelar</button>
                            <button type="submit" className="button-blue" onClick={() => {deleteApolice(apoliceAtiva._id)}}>Excluir</button>
                        </div>
                    </Fragment>
                </ModalDangerSmall>
            ): null}
            {modalDeleteSeguradora ? (
                <ModalDangerSmall mensagem={['Deseja excluir definitivamente a seguradora', <br/>,<span>{seguradoraAtiva.fantasia}</span>,"?"]} onClose={() => {setModalDeleteSeguradora(false)}}>
                    <Fragment>
                        <div className='buttons-form'>
                            <button type="button" className="button-red" onClick={() => {setModalDeleteSeguradora(false)}}>Cancelar</button>
                            <button type="submit" className="button-blue" onClick={() => {deleteSeguradora(seguradoraAtiva._id)}}>Excluir</button>
                        </div>
                    </Fragment>
                </ModalDangerSmall>
            ): null}
            {modalDeleteVisita ? (
                <ModalDangerSmall mensagem={['Deseja excluir definitivamente a visita à empresa', <br/>,<span>{visitaAtiva.empresa}</span>,"?"]} onClose={() => {setModalDeleteVisita(false)}}>
                    <Fragment>
                        <div className='buttons-form'>
                            <button type="button" className="button-red" onClick={() => {setModalDeleteVisita(false)}}>Cancelar</button>
                            <button type="submit" className="button-blue" onClick={() => {deleteVisita(visitaAtiva._id)}}>Excluir</button>
                        </div>
                    </Fragment>
                </ModalDangerSmall>
            ): null} */}
        </Fragment>
    )
};