import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSackXmark, faSackDollar, faArrowRightLong, faArrowTurnUp, faPlus, faMinus, faPen, faAdd } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/pt-br';
import { NumericFormat } from 'react-number-format';
import "../../../styles/planos.scss";
import "../../../styles/reset.scss";
import FinanceiroService from '../../../services/financeiro';
import Modal from "../../layouts/modal";
import Loading from '../../layouts/loading';
Moment.globalLocale = 'pt-BR'

export default function ContentFinanceiro() {
    const [planos, setPlanos] = useState();
    const [contas, setContas] = useState();
    const [carregado, setCarregado] = useState(false);
    const [editarPlanos, setEditarPlanos] = useState(false);
    const [planoAtivo, setPlanoAtivo] = useState();
    const [quantidadeSubcategoriasPlano, setQuantidadeSubcategoriasPlano] = useState(0);
    const [subcategoriasPlano, setSubcategoriasPlano] = useState(["0"]);
    const [modalNovoPlano, setModalNovoPlano] = useState(false);
    const [modalEditarPlano, setModalEditarPlano] = useState(false);
    const [modalAdicionarSubPlano, setModalAdicionarSubPlano] = useState(false);
    const navigate = useNavigate();

    function removerSubcategoria() {
        const remocao = [...subcategoriasPlano];
        remocao.pop();
        setQuantidadeSubcategoriasPlano(quantidadeSubcategoriasPlano - 1)
        setSubcategoriasPlano(remocao);
    }

    function adicionarSubcategoria() {
        setSubcategoriasPlano([ ...subcategoriasPlano, `${quantidadeSubcategoriasPlano + 1}`]);
        setQuantidadeSubcategoriasPlano(quantidadeSubcategoriasPlano + 1);
    }

    async function getContas() {
        const res = await FinanceiroService.contas();
        setContas(res.data);
    };

    async function getPlanos() {
        const res = await FinanceiroService.planos();
        setPlanos(res.data);
    };

    async function createPlano(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        form.subs = [];

        for (let i = 0; i < subcategoriasPlano.length; i++) {
            const subInput = document.querySelector(`input[name="subcategoria${i}"]`);

            const sub = {
                descricao: subInput.value
            }

            if (subInput.value !== "") {
                form.subs.push(sub);
            }
        };

        for (let propriedade in form) {
            if (/^subcategoria\d+$/.test(propriedade)) {
                delete form[propriedade];
            }
        }

        form.subcategorias = [];

        try {
            for (const element of form.subs) {
                const res = await FinanceiroService.create_sub_plano(element);
                form.subcategorias.push({ sub: res.data.sub._id });
            }

            delete form.subs;

            await FinanceiroService.create_plano(form);
            await getPlanos();
            setModalNovoPlano(false);
            setSubcategoriasPlano(["0"]);
            setQuantidadeSubcategoriasPlano(0);
        } catch (error) {
            console.log(error);
        }
    };

    async function editPlano(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        try {
            if (planoAtivo.subcategorias) {
                await FinanceiroService.edit_plano(planoAtivo._id, form);
            } else {
                await FinanceiroService.edit_sub_plano(planoAtivo._id, form);
            }

            await getPlanos();
            setModalEditarPlano(false);
        } catch (error) {
            console.log(error);
        }
    };

    async function addSubPlano(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        form.subcategorias = [];
        
        const res = await FinanceiroService.create_sub_plano(form);
        form.subcategorias.push({ sub: res.data.sub._id });

        delete form.descricao;

        try {
            await FinanceiroService.add_sub_plano(planoAtivo._id, form);
            await getPlanos();
            setModalAdicionarSubPlano(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        (async function() {
            await getContas();
            await getPlanos();
            setCarregado(true);
        })();
        // eslint-disable-next-line
    }, []);

    function ordemAlfabetica(a, b){
        return a.descricao < b.descricao ? -1 : a.descricao > b.descricao ? 1 : 0;
    }

    document.title = `Plano de Contas - DNG Store`;

    return(
        <Fragment>
            { carregado ? 
                <main className='main'>
                    <div className="conteudo-planos">
                        <div className="cabecalho-conteudo">
                            <h2>Plano de Contas</h2>
                            <div className="buttons-conteudo">
                                <button className="button-red" onClick={() => navigate(`/financeiro`)}>Voltar</button>
                                {editarPlanos === false ? <button className="button-blue" onClick={() => setEditarPlanos(true)}>Editar</button> : <button className="button-red" onClick={() => setEditarPlanos(false)}>Cancelar edição</button>}
                                <button className="button-green" onClick={() => {setModalNovoPlano(true)}}>Cadastrar</button>
                            </div>
                        </div>
                        <div className="tabela-planos">
                            <div className='titulo-tabela-plano titulo-plano-entradas'>
                                <FontAwesomeIcon icon={faSackDollar}/>
                                <h3>Entradas</h3>
                            </div>
                            <div className="corpo-planos">
                                {planos.filter(({tipo}) => tipo === "Entradas").sort(ordemAlfabetica).map((item, index) => (
                                    <div className='categoria-tabela-planos' key={index}>
                                        <div className='icon-plano' style={editarPlanos ? {width: "6.5%", marginRight: "1.5%" , display: 'flex', justifyContent: 'space-between'} : null}>
                                            { editarPlanos ? <FontAwesomeIcon icon={faPen} style={{cursor: 'pointer'}} onClick={() => {setModalEditarPlano(true); setPlanoAtivo(item)}}/> : <FontAwesomeIcon icon={faArrowRightLong}/>}
                                            { editarPlanos ? <FontAwesomeIcon icon={faAdd} style={{cursor: 'pointer'}} onClick={() => {setModalAdicionarSubPlano(true); setPlanoAtivo(item)}}/> : null}
                                        </div>
                                        <h4 style={editarPlanos ? {width: "72%"} : null}>{item.descricao}</h4>
                                        <p className='valor-plano valor-plano-categoria'>
                                            {(() => {
                                                let valorTotal = 0;

                                                item.subcategorias.forEach(d => {
                                                    contas.filter(({status}) => status.at(-1).situacao === "Liquidado").filter(({plano_contas}) => plano_contas._id === d.sub._id).forEach(element => {
                                                        valorTotal = valorTotal + element.valor;
                                                    })
                                                });

                                                return(<NumericFormat value={valorTotal} displayType="text" decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale renderText={(value) => <Fragment>{value}</Fragment>}/>)
                                            })()}
                                        </p>
                                        {item.subcategorias.sort(ordemAlfabetica).map((s, i) => 
                                            <div className='subcategoria-tabela-planos' key={i}>
                                                <div className='icon-plano'>
                                                    { editarPlanos ? <FontAwesomeIcon icon={faPen} style={{cursor: 'pointer'}} onClick={() => {setModalEditarPlano(true); setPlanoAtivo(s.sub)}}/> : <FontAwesomeIcon icon={faArrowTurnUp} rotation={90}/>}
                                                </div>
                                                <h5 key={i}>{s.sub.descricao}</h5>
                                                <p className='valor-plano valor-plano-subcategoria'>
                                                    {(() => {
                                                        let valorTotalReceber = 0;

                                                        contas.filter(({status}) => status.at(-1).situacao === "Liquidado").filter(({plano_contas}) => plano_contas._id === s.sub._id).forEach(element => {
                                                            valorTotalReceber = valorTotalReceber + element.valor;
                                                        });

                                                        return(<NumericFormat value={valorTotalReceber} displayType="text" decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale renderText={(value) => <p>{value}</p>}/>)
                                                    })()}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="tabela-planos">
                            <div className='titulo-tabela-plano titulo-plano-saidas'>
                                <FontAwesomeIcon icon={faSackXmark}/>
                                <h3>Saídas</h3>
                            </div>
                            <div className="corpo-planos">
                                {planos.filter(({tipo}) => tipo === "Saídas").sort(ordemAlfabetica).map((item, index) => (
                                    <div className='categoria-tabela-planos' key={index}>
                                        <div className='icon-plano' style={editarPlanos ? {width: "6.5%", marginRight: "1.5%" , display: 'flex', justifyContent: 'space-between'} : null}>
                                            { editarPlanos ? <FontAwesomeIcon icon={faPen} style={{cursor: 'pointer'}} onClick={() => {setModalEditarPlano(true); setPlanoAtivo(item)}}/> : <FontAwesomeIcon icon={faArrowRightLong}/>}
                                            { editarPlanos ? <FontAwesomeIcon icon={faAdd} style={{cursor: 'pointer'}} onClick={() => {setModalAdicionarSubPlano(true); setPlanoAtivo(item)}}/> : null}
                                        </div>
                                        <h4 style={editarPlanos ? {width: "72%"} : null}>{item.descricao}</h4>
                                        <p className='valor-plano valor-plano-categoria'>
                                            {(() => {
                                                let valorTotal = 0;

                                                item.subcategorias.forEach(d => {
                                                    contas.filter(({status}) => status.at(-1).situacao === "Liquidado").filter(({plano_contas}) => plano_contas._id === d.sub._id).forEach(element => {
                                                        valorTotal = valorTotal + element.valor;
                                                    })
                                                });

                                                return(<NumericFormat value={valorTotal} displayType="text" decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale renderText={(value) => <Fragment>{value}</Fragment>}/>)
                                            })()}
                                        </p>
                                        {item.subcategorias.sort(ordemAlfabetica).map((s, i) => 
                                            <div className='subcategoria-tabela-planos' key={i}>
                                                <div className='icon-plano'>
                                                    { editarPlanos ? <FontAwesomeIcon icon={faPen} style={{cursor: 'pointer'}} onClick={() => {setModalEditarPlano(true); setPlanoAtivo(s.sub)}}/> : <FontAwesomeIcon icon={faArrowTurnUp} rotation={90}/>}
                                                </div>
                                                <h5 key={i}>{s.sub.descricao}</h5>
                                                <p className='valor-plano valor-plano-subcategoria'>
                                                    {(() => {
                                                        let valorTotalPagar = 0;

                                                        contas.filter(({status}) => status.at(-1).situacao === "Liquidado").forEach(element => {
                                                            if (element.plano_contas._id === s.sub._id) {
                                                                valorTotalPagar = valorTotalPagar + element.valor;
                                                            }
                                                        });

                                                        return(<NumericFormat value={valorTotalPagar} displayType="text" decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale renderText={(value) => <p>{value}</p>}/>)
                                                    })()}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </main>
            :
            <Loading>
                
            </Loading>
            }
            {modalNovoPlano ? (
                <Modal titulo="Novo Plano de Contas" width="50%" onClose={() => {setModalNovoPlano(false); setSubcategoriasPlano(["0"]); setQuantidadeSubcategoriasPlano(0)}}>
                    <form onSubmit={createPlano}>
                        <div id="campos-novo-parceiro">
                            <div className="campo-form campo-40">
                                <label htmlFor="tipo">Tipo</label>
                                <select name="tipo" id="tipo" defaultValue="default">
                                    <option value="default" disabled>Selecione</option>
                                    <option value="Entradas">Entradas</option>
                                    <option value="Saídas">Saídas</option>
                                </select>
                            </div>
                            <div className="campo-form campo-60">
                                <label htmlFor="descricao">Descrição</label>
                                <input type="text" name="descricao" id="descricao" autoComplete="off"/>
                            </div>
                            <h5>Sub-Categorias <button className='button-green' type='button' onClick={() => {adicionarSubcategoria()}}><FontAwesomeIcon icon={faPlus}/></button> { subcategoriasPlano.length !== 1 ? <button className='button-red' type='button' onClick={() => {removerSubcategoria()}}><FontAwesomeIcon icon={faMinus}/></button> : <button className='button-red button-disabled' type='button'><FontAwesomeIcon icon={faMinus}/></button>} </h5>
                            {subcategoriasPlano.map((item, index) => (
                                <div className={`container-inputs`} key={index}>
                                    <div className="campo-form campo-100">
                                        <input type="text" name={`subcategoria${item}`} id={`subcategoria${item}`}/>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalNovoPlano(false); setSubcategoriasPlano(["0"]); setQuantidadeSubcategoriasPlano(0)}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalEditarPlano ? (
                <Modal titulo="Editar Plano de Contas" width="40%" onClose={() => {setModalEditarPlano(false);}}>
                    <form onSubmit={editPlano}>
                        <div id="campos-editar-plano">
                            <div className="campo-form campo-100">
                                <label htmlFor="descricao">Descrição</label>
                                <input type='text' name='descricao' id='descricao' defaultValue={planoAtivo.descricao} required autoComplete='off'/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalEditarPlano(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Salvar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalAdicionarSubPlano ? (
                <Modal titulo="Adicionar Sub-Categoria" width="40%" onClose={() => {setModalAdicionarSubPlano(false);}}>
                    <form onSubmit={addSubPlano}>
                        <div id="campos-add-sub-plano">
                            <div className="campo-form campo-100">
                                <label htmlFor="descricao">Descrição</label>
                                <input type='text' name='descricao' id='descricao' required autoComplete='off'/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalAdicionarSubPlano(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Adicionar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
        </Fragment>
    )
}