import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentItemEstoque from '../../components/estoque/item';

const EstoqueItemScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentItemEstoque/>
  </Fragment>
)

export default EstoqueItemScreen;