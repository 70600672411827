import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentSuporteFinalizadosChamados from '../../components/chamados/suporte/finalizados';

const ChamadosSuporteFinalizadosScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentSuporteFinalizadosChamados/>
  </Fragment>
)

export default ChamadosSuporteFinalizadosScreen;