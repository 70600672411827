import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/pt-br'
import "../../../styles/caixas.scss";
import "../../../styles/reset.scss";
import FinanceiroService from '../../../services/financeiro';
import Modal from "../../layouts/modal";
import Loading from '../../layouts/loading';
import axios from 'axios';
import { NumericFormat } from 'react-number-format';
import UsersService from '../../../services/users';
import ModalDangerSmall from '../../layouts/modalDangerSmall';
Moment.globalLocale = 'pt-BR'

export default function ContentFinanceiroCaixas() {
    const [userLogado, setUserLogado] = useState();
    const [caixas, setCaixas] = useState();
    const [contas, setContas] = useState();
    const [caixaAtivo, setCaixaAtivo] = useState();
    const [carregado, setCarregado] = useState(false);
    const [modalNovoCaixa, setModalNovoCaixa] = useState(false);
    const [modalCaixa, setModalCaixa] = useState(false);
    const [modalEditarCaixa, setModalEditarCaixa] = useState(false);
    const [modalDeleteCaixa, setModalDeleteCaixa] = useState(false);
    // const [valoresEntradas, setValoresEntradas] = useState(false);
    // const [valoresSaidas, setValoresSaidas] = useState(false);
    const [semBancoSelecionado, setSemBancoSelecionado] = useState(false);
    const [bancos, setBancos] = useState();
    const [finds, setFinds] = useState("");
    const [filtroSelecionado, setFiltroSelecionado] = useState(localStorage.getItem('filtro-ativo-caixas') || 'ativos');
    const navigate = useNavigate();

    async function getUserLogado() {
        const res = await UsersService.logado();
        setUserLogado(res.data.user);
    }

    async function getCaixas() {
        const res = await FinanceiroService.caixas();
        setCaixas(res.data);
    };

    async function getBancos() {
        const res = await axios.get("https://brasilapi.com.br/api/banks/v1");
        setBancos(res.data);
    }

    async function getContas() {
        const res = await FinanceiroService.contas();
        setContas(res.data);
    }

    async function createCaixa(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        try {
            await FinanceiroService.create_caixa(form);
            await getCaixas();
            setSemBancoSelecionado(false);
            setModalNovoCaixa(false);
        } catch (error) {
            console.log(error)
        }
    };

    async function editCaixa(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        try {
            await FinanceiroService.edit_caixa(caixaAtivo._id, form);
            await getCaixas();
            setSemBancoSelecionado(false);
            setModalEditarCaixa(false);
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        (async function() {
            await getUserLogado();
            await getCaixas();
            await getContas();
            await getBancos();
            setCarregado(true);
        })();
        // eslint-disable-next-line
    }, []);

    // FILTRO
    function filtro(a) {
        switch (true) {
            case (filtroSelecionado === "ativos"):
                return a.ativo === true;
            case (filtroSelecionado === "inativos"):
                return a.ativo === false;
            case (filtroSelecionado === "todos"):
                return a;
            default:
            break;
        }
    }

    function busca(a) {
        if (finds === "") {
            return a
        } else {
            return a.descricao.toLowerCase().includes(finds.toLowerCase()) || a.banco.toLowerCase().includes(finds.toLowerCase()) || a.agencia.toLowerCase().includes(finds.toLowerCase()) || a.conta.toLowerCase().includes(finds.toLowerCase())
        }
    }

    async function ativarCaixa() {
        await FinanceiroService.ativar_caixa(caixaAtivo._id);
        setModalCaixa(false);
        getCaixas();
    }

    async function inativarCaixa() {
        await FinanceiroService.inativar_caixa(caixaAtivo._id);
        setModalCaixa(false);
        getCaixas();
    }

    async function deletarCaixa() {
        await FinanceiroService.delete_caixa(caixaAtivo._id);
        setModalDeleteCaixa(false);
        getCaixas();
    }

    // function getValores(val) {
    //     let valorEntradas = 0;
    //     let valorSaidas = 0;

    //     val.filter((a) => a.excluido === false && a.status.at(-1).situacao === "Liquidado").forEach(element => {
    //         if (element.tipo === "Pagar") {
    //             valorSaidas = valorSaidas + (element.valor_pago ? parseFloat(element.valor_pago) : parseFloat(element.valor));
    //         } else {
    //             valorEntradas = valorEntradas + (element.valor_pago ? parseFloat(element.valor_pago) : parseFloat(element.valor))
    //         }
    //     });

    //     setValoresSaidas(valorSaidas);
    //     setValoresEntradas(valorEntradas);
    // }

    function ordemBanco(a, b) {
        return a.code < b.code ? -1 : a.code > b.code ? 1 : 0;
    }

    document.title = `Caixas - DNG Store`;

    return(
        <Fragment>
            { carregado ? 
                <main className='main'>
                    <div className="conteudo-parceiros">
                        <div className="cabecalho-conteudo">
                            <h2>Caixas</h2>
                            <div className="buttons-conteudo">
                                <button className="button-red" onClick={() => navigate(`/financeiro`)}>Voltar</button>
                                <button className="button-green" onClick={() => setModalNovoCaixa(true)}>Cadastrar</button>
                            </div>
                        </div>
                        <div className='container-search'>
                            <select defaultValue={filtroSelecionado} onChange={e => {setFiltroSelecionado(e.target.value); localStorage.setItem('filtro-ativo-caixas', e.target.value)}}>
                                <option value="todos">Todos</option>
                                <option value="ativos">Ativos</option>
                                <option value="inativos">Inativos</option>
                            </select>
                            <input type='text' placeholder='Buscar' onChange={e => {setFinds(e.target.value)}}/>
                            <FontAwesomeIcon icon={faSearch}/>
                        </div>
                        <div className="tabela-dados">
                            <div className="cabecalho-dados">
                                <div className="column-30">Descrição</div>
                                <div className="column-20">Banco</div>
                                <div className="column-10">Agência</div>
                                <div className="column-10">Conta</div>
                                <div className="column-10">Entradas</div>
                                <div className="column-10">Saídas</div>
                                <div className="column-10">Saldo</div>
                            </div>
                            <div className="corpo-dados corpo-caixas">
                                { caixas.filter(filtro).filter(({excluido}) => excluido === false).slice(0).reverse().filter(busca).length === 0 ? 
                                        <div className="column-zerado">
                                            Não foi encontrado nenhum resultado.
                                        </div>
                                    : (
                                        (caixas.filter(filtro).filter(({excluido}) => excluido === false).slice(0).reverse().filter(busca).map((item, index) => {
                                            let valorEntradas = 0;
                                            let valorSaidas = 0;

                                            contas.filter((a) => a.excluido === false && a.status.at(-1).situacao === "Liquidado").filter(({caixa}) => caixa === item._id).forEach(element => {
                                                if (element.tipo === "Pagar") {
                                                    valorSaidas = valorSaidas + (element.valor_pago ? parseFloat(element.valor_pago) : parseFloat(element.valor));
                                                } else {
                                                    valorEntradas = valorEntradas + (element.valor_pago ? parseFloat(element.valor_pago) : parseFloat(element.valor))
                                                }
                                            });
                                            
                                            return(<div key={index} className="row-dados" onClick={() => {setModalCaixa(true); setCaixaAtivo(item)}}>
                                                <div className="column-30">
                                                    {item.descricao}
                                                </div>
                                                <div className="column-20">
                                                    {item.banco}
                                                </div>
                                                <div className="column-10">
                                                    {item.agencia === "" || item.agencia === undefined ? "--" : item.agencia}
                                                </div>
                                                <div className="column-10">
                                                    {item.conta === "" || item.conta === undefined ? "--" : item.conta}
                                                </div>
                                                <div className="column-10" style={{color: "green"}}>
                                                    <NumericFormat value={valorEntradas} displayType="text" decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale renderText={(value) => <Fragment>{value}</Fragment>}/>
                                                </div>
                                                <div className="column-10" style={{color: "red"}}>
                                                    -<NumericFormat value={valorSaidas} displayType="text" decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale renderText={(value) => <Fragment>{value}</Fragment>}/>
                                                </div>
                                                <div className="column-10" style={{fontWeight: 600}}>
                                                    <NumericFormat value={valorEntradas - valorSaidas} displayType="text" decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale renderText={(value) => <Fragment>{value}</Fragment>}/>
                                                </div>
                                            </div>)
                                        }))
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </main>
            :
            <Loading>
                
            </Loading>
            }
            {modalNovoCaixa ? (
                <Modal titulo="Novo Caixa" width="45%" onClose={() => {setModalNovoCaixa(false); setSemBancoSelecionado(false)}}>
                    <form onSubmit={createCaixa}>
                        <div id="campos-novo-caixa">
                            <div className="campo-form campo-100">
                                <label htmlFor="descricao">Descrição</label>
                                <input type="text" name="descricao" id="descricao" autoComplete="off"/>
                            </div>
                            <div className={`campo-form ${semBancoSelecionado ? "campo-100" : "campo-50"}`}>
                                <label htmlFor="banco">Banco</label>
                                <select name='banco' id='banco' onChange={(e) => { e.target.value === "0 - SEM BANCO" ? setSemBancoSelecionado(true) : setSemBancoSelecionado(false)}}>
                                    <option>Selecione</option>
                                    <option>0 - SEM BANCO</option>
                                    { bancos.filter((a) => a.name !== undefined && Number(a.code)).sort(ordemBanco).map((item, index) => (
                                        <option key={index}>{item.code} - {item.name}</option>
                                    ))}
                                </select>
                            </div>
                            { !semBancoSelecionado ?
                                <Fragment>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="agencia">Agência</label>
                                        <input type="text" name="agencia" id="agencia" autoComplete="off"/>
                                    </div>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="conta">Conta</label>
                                        <input type="text" name="conta" id="conta" autoComplete="off"/>
                                    </div>
                                </Fragment>
                            :
                                null
                            }
                            <div className="campo-form campo-70">
                                <label htmlFor="titular">Razão Social (Titular)</label>
                                <input type="text" name="titular" id="titular" autoComplete="off"/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="doc_titular">CNPJ</label>
                                <input type="text" name="doc_titular" id="doc_titular"/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalNovoCaixa(false); setSemBancoSelecionado(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalCaixa ? (
                <Modal titulo="Caixa" width="45%" onClose={() => {setModalCaixa(false)}}>
                    <div className="info-modal modal-parceiro">
                        <div className={`campo-info ${caixaAtivo.banco !== "0 - SEM BANCO" ? "campo-100" : "campo-70"}`}>
                            <h4>Descrição:</h4>
                            <h5>{caixaAtivo.descricao}</h5>
                        </div>
                        <div className={`campo-info ${caixaAtivo.banco !== "0 - SEM BANCO" ? "campo-50" : "campo-30"}`}>
                            <h4>Banco:</h4>
                            <h5>{caixaAtivo.banco}</h5>
                        </div>
                        { caixaAtivo.banco !== "0 - SEM BANCO" ?
                            <Fragment>
                                <div className="campo-info campo-25">
                                    <h4>Agência:</h4>
                                    <h5>{caixaAtivo.agencia}</h5>
                                </div>
                                <div className="campo-info campo-25">
                                    <h4>Conta:</h4>
                                    <h5>{caixaAtivo.conta}</h5>
                                </div>
                            </Fragment>
                        :
                            null
                        }
                        <div className="campo-info campo-70">
                            <h4>Razão Social (Titular):</h4>
                            <h5>{caixaAtivo.titular}</h5>
                        </div>
                        <div className="campo-info campo-30">
                            <h4>CNPJ:</h4>
                            <h5>{caixaAtivo.doc_titular}</h5>
                        </div>
                    </div>
                    <div className='buttons-info'>
                        <button type="reset" className="button-red" onClick={() => {setModalCaixa(false)}}>Cancelar</button>
                        <button type="button" className="button-blue" onClick={() => { caixaAtivo.banco === "0 - SEM BANCO" ? setSemBancoSelecionado(true) : setSemBancoSelecionado(false); setModalCaixa(false); setModalEditarCaixa(true)}}>Editar</button>
                        { caixaAtivo.ativo ? <button type="button" className="button-red" onClick={() => {inativarCaixa()}}>Inativar</button> : <button type="button" className="button-green" onClick={() => {ativarCaixa()}}>Ativar</button>}
                        { userLogado.permission === 1 ? <button type="button" className="button-darkred" onClick={() => { setModalCaixa(false); setModalDeleteCaixa(true)}}>Excluir</button> : null}
                    </div>
                </Modal>
            ): null}
            {modalEditarCaixa ? (
                <Modal titulo="Editar Caixa" width="45%" onClose={() => {setModalEditarCaixa(false); setSemBancoSelecionado(false)}}>
                    <form onSubmit={editCaixa}>
                        <div id="campos-editar-caixa">
                            <div className="campo-form campo-100">
                                <label htmlFor="descricao">Descrição</label>
                                <input type="text" name="descricao" id="descricao" defaultValue={caixaAtivo.descricao} autoComplete="off"/>
                            </div>
                            <div className={`campo-form ${semBancoSelecionado ? "campo-100" : "campo-50"}`}>
                                <label htmlFor="banco">Banco</label>
                                <select name='banco' id='banco' defaultValue={caixaAtivo.banco} onChange={(e) => { e.target.value === "0 - SEM BANCO" ? setSemBancoSelecionado(true) : setSemBancoSelecionado(false)}}>
                                    <option>Selecione</option>
                                    <option>0 - SEM BANCO</option>
                                    { bancos.filter((a) => a.name !== undefined && Number(a.code)).sort(ordemBanco).map((item, index) => (
                                        <option key={index}>{item.code} - {item.name}</option>
                                    ))}
                                </select>
                            </div>
                            { !semBancoSelecionado ?
                                <Fragment>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="agencia">Agência</label>
                                        <input type="text" name="agencia" id="agencia" defaultValue={caixaAtivo.agencia} autoComplete="off"/>
                                    </div>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="conta">Conta</label>
                                        <input type="text" name="conta" id="conta" defaultValue={caixaAtivo.conta} autoComplete="off"/>
                                    </div>
                                </Fragment>
                            :
                                null
                            }
                            <div className="campo-form campo-70">
                                <label htmlFor="titular">Razão Social (Titular)</label>
                                <input type="text" name="titular" id="titular" defaultValue={caixaAtivo.titular} autoComplete="off"/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="doc_titular">CNPJ</label>
                                <input type="text" name="doc_titular" defaultValue={caixaAtivo.doc_titular} id="doc_titular"/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalEditarCaixa(false); setModalCaixa(true); setSemBancoSelecionado(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Salvar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalDeleteCaixa ? (
                <ModalDangerSmall mensagem={['Deseja excluir o caixa', <br/>,<span>{caixaAtivo.descricao}</span>,"?"]} onClose={() => {setModalDeleteCaixa(false)}}>
                    <Fragment>
                        <div className='buttons-form' key={'1'}>
                            <button type="button" className="button-red" onClick={() => {setModalDeleteCaixa(false); setModalCaixa(true)}}>Cancelar</button>
                            <button type="submit" className="button-blue" onClick={() => {deletarCaixa()}}>Excluir</button>
                        </div>
                    </Fragment>
                </ModalDangerSmall>
            ): null}
        </Fragment>
    )
}