import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentFinanceiroCaixas from '../../components/financeiro/caixas';

const CaixasScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentFinanceiroCaixas/>
  </Fragment>
)

export default CaixasScreen;