import React, { Fragment, useEffect, useState } from 'react';
import { ResponsiveContainer, Tooltip, XAxis, YAxis, AreaChart, Area } from 'recharts';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSackXmark, faSackDollar, faPiggyBank, faHandshake, faFileLines, faCashRegister, faFileInvoiceDollar, faWallet } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import moment from 'moment/moment';
import 'moment-timezone';
import 'moment/locale/pt-br'
import "../../styles/financeiro.scss";
import "../../styles/reset.scss";
import UsersService from '../../services/users';
// import Modal from "../layouts/modal";
import Loading from '../layouts/loading';
import FinanceiroService from '../../services/financeiro';
import { NumericFormat } from 'react-number-format';
Moment.globalLocale = 'pt-BR'

export default function ContentFinanceiro() {
    const [userLogado, setUserLogado] = useState();
    const [contas, setContas] = useState();
    const [valoresGrafico, setValoresGrafico] = useState();
    const [valorEntradasTotal, setValorEntradaTotal] = useState(0);
    const [valorSaidasTotal, setValorSaidaTotal] = useState(0);
    // const [valorEntradaMes, setValorEntradaMes] = useState(0);
    // const [valorSaidaMes, setValorSaidaMes] = useState(0);
    const [carregado, setCarregado] = useState(false);
    const navigate = useNavigate();

    async function getValoresMeses(res) {
        // for (let mes = 1; mes <= 12 ; mes++) {
        //     let valorEntrada = 0;
        //     let valorSaida = 0;

        //     res.filter(({tipo}) => tipo === "Receber").filter(({status}) => status.at(-1).situacao === "Liquidado").forEach(element => {
        //         if (new Date(element.pagamento).getMonth() + 1 === mes) {
        //             console.log(mes);
        //         }
        //     });
        // }

        const meses = [['Jan', 1], ['Fev', 2], ['Mar', 3], ['Abr', 4], ['Mai', 5], ['Jun', 6], ['Jul', 7], ['Ago', 8], ['Set', 99], ['Out', 10], ['Nov', 11], ['Dez', 12]];

        const arrayGrafico = []

        for (const [mes, mesNumeral] of meses) {
            let valorEntrada = 0;
            let valorSaida = 0;

            res.filter(({tipo}) => tipo === "Receber").filter(({status}) => status.at(-1).situacao === "Liquidado").forEach(element => {
                if (new Date(element.pagamento).getFullYear() === new Date().getFullYear() && new Date(element.pagamento).getMonth() + 1 === mesNumeral) {
                    valorEntrada = valorEntrada + element.valor;
                }
            });

            res.filter(({tipo}) => tipo === "Pagar").filter(({status}) => status.at(-1).situacao === "Liquidado").forEach(element => {
                if (new Date(element.pagamento).getFullYear() === new Date().getFullYear() && new Date(element.pagamento).getMonth() + 1 === mesNumeral) {
                    valorSaida = valorSaida + element.valor;
                }
            });

            arrayGrafico.push({name: mes, Receitas: valorEntrada.toFixed(2), Despesas: valorSaida.toFixed(2)});
        }

        setValoresGrafico(arrayGrafico);
    }

    // GRÁFICO ENTRADAS
    // const data = [
    //     {
    //       name: 'Jan',
    //       Despesas: 4000,
    //       Receitas: 2400,
    //     },
    //     {
    //       name: 'Fev',
    //       Despesas: 1000,
    //       Receitas: 1398,
    //     },
    //     {
    //       name: 'Mar',
    //       Despesas: 4000,
    //       Receitas: 3500,
    //     },
    //     {
    //       name: 'Abr',
    //       Despesas: 3000,
    //       Receitas: 1398,
    //     },
    //     {
    //       name: 'Mai',
    //       Despesas: 2000,
    //       Receitas: 7000,
    //     },
    //     {
    //       name: 'Jun',
    //       Despesas: 0,
    //       Receitas: 0,
    //     },
    //     {
    //       name: 'Jul',
    //       Despesas: 0,
    //       Receitas: 0,
    //     },
    //     {
    //       name: 'Ago',
    //       Despesas: 0,
    //       Receitas: 0,
    //     },
    //     {
    //       name: 'Set',
    //       Despesas: 0,
    //       Receitas: 0,
    //     },
    //     {
    //       name: 'Out',
    //       Despesas: 0,
    //       Receitas: 0,
    //     },
    //     {
    //       name: 'Nov',
    //       Despesas: 0,
    //       Receitas: 0,
    //     },
    //     {
    //       name: 'Dez',
    //       Despesas: 0,
    //       Receitas: 0,
    //     }
    // ];

    async function getUserLogado() {
        const res = await UsersService.logado();
        setUserLogado(res.data);
    };

    async function getContas() {
        const res = await FinanceiroService.contas();
        setContas(res.data);
        getValores(res.data);
        getValoresMeses(res.data);
    };

    function getValores(res) {
        let valorEntradasTotal = 0;
        let valorSaidasTotal = 0;
        let valorEntrada = 0;
        let valorSaida = 0;

        res.filter(({tipo}) => tipo === "Receber").filter(({status}) => status.at(-1).situacao === "Liquidado").forEach(element => {
            valorEntradasTotal = valorEntradasTotal + element.valor;

            if (new Date(element.status.at(-1).data).getMonth() === new Date().getMonth()) {
                valorEntrada = valorEntrada + element.valor;
            }
        });

        res.filter(({tipo}) => tipo === "Pagar").filter(({status}) => status.at(-1).situacao === "Liquidado").forEach(element => {
            valorSaidasTotal = valorSaidasTotal + element.valor;

            if (new Date(element.status.at(-1).data).getMonth() === new Date().getMonth()) {
                valorSaida = valorSaida + element.valor;
            }
        });

        setValorSaidaTotal(valorSaidasTotal);
        setValorEntradaTotal(valorEntradasTotal);
        // setValorEntradaMes(valorEntrada);
        // setValorSaidaMes(valorSaida);
    };

    useEffect(() => {
        (async function() {
            await getUserLogado();
            await getContas();
            setCarregado(true);
        })();
        // eslint-disable-next-line
    }, []);

    function filtroContas(a) {
        return a.excluido === false && a.status.at(-1).situacao === "Pendente" && moment(a.vencimento) < moment().add(7, "d");
    };

    function ordemData(a, b){
        return a.vencimento < b.vencimento ? -1 : a.vencimento > b.vencimento ? 1 : 0;
    }

    document.title = `Financeiro - DNG Store`;

    return(
        <Fragment>
            { carregado ? 
                <main id='main-financeiro'>
                    <div className="conteudo-financeiro">
                        <div className="cabecalho-conteudo">
                            <h2>Financeiro</h2>
                            {/* <div className="buttons-conteudo"> */}
                                {/* <button className="button-red" onClick={() => navigate(`/treinamentos`)}>Voltar</button> */}
                                {/* {treinamento.status.at(-1).situacao === "Finalizado" ? null : <button className="button-blue" onClick={() => setModalAlterarStatus(true)}>Alterar status</button>}
                                {treinamento.status.at(-1).situacao === "Finalizado" ? null : <button className="button-blue" onClick={() => atualizarTreinamento()}>Atualizar</button>} */}
                                {/* <button className="button-cyan" onClick={() => { setModalEditarTreinamento(true); setDatasTreinamento(treinamento.datas); setEmpresaTreinamento(treinamento.empresa === undefined ? 'false' : 'true') }}>Editar</button> */}
                                {/* {permission === 1 ? <button className="button-darkred" onClick={() => {setModalExcluirTreinamento(true)}}>Excluir</button> : null } */}
                            {/* </div> */}
                        </div>
                        <div className='divisao1'>
                            <div className='mensagem-inicio-financeiro'>
                                <img src={userLogado.url_images + userLogado.user.imagem} alt={`Imagem do usuário ${userLogado.name}`} />
                                <div>
                                    <h4>Bem vindo(a), <span>{userLogado.user.name.split(' ').shift()}</span></h4>
                                    <p>Acompanhe as principais informações financeiras por aqui!</p>
                                </div>
                            </div>
                            <div className='dados-contabilidade-financeiro'>
                                <div className='titulo-box'>
                                    <h3>Contabilidade</h3>
                                    <p>Saldo total com despesas e receitas</p>
                                    {/* <p><Moment format='MMMM [de] YYYY'>{new Date()}</Moment></p> */}
                                </div>
                                <div className='box-saldo'>
                                    <h6>Saldo</h6>
                                    <NumericFormat value={valorEntradasTotal - valorSaidasTotal} displayType="text" decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale renderText={(value) => <h5>{value}</h5>}/>
                                </div>
                                <div className='box-movimentacao-saldo'>
                                    <div>
                                        <FontAwesomeIcon icon={faSackDollar}/>
                                        <div className='valores-movimentacao'>
                                            <NumericFormat value={valorEntradasTotal} displayType="text" decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale renderText={(value) => <h5>{value}</h5>}/>
                                            <h6>Valores de entrada</h6>
                                        </div>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faSackXmark}/>
                                        <div className='valores-movimentacao'>
                                            <NumericFormat value={valorSaidasTotal} displayType="text" decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale renderText={(value) => <h5>{value}</h5>}/>
                                            <h6>Valores de saída</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='box-grafico-saldo'>
                                    {/* <ResponsiveContainer width="30%" height="100%">
                                        <PieChart>
                                            <Tooltip/>
                                            <Pie data={dataPie} dataKey={"value"} nameKey={"name"} innerRadius={40} outerRadius={60} paddingAngle={0}>
                                                {dataPie.map((entry, index) => (
                                                    <Cell nameKey={entry.name} key={`cell-${index}`} fill={entry.name === "Despesas" ? "#d42f2f" : '#208d20'}>{entry.name}</Cell>
                                                ))}
                                            </Pie>
                                        </PieChart>
                                    </ResponsiveContainer> */}

                                    <ResponsiveContainer width="100%" height="100%">
                                        <AreaChart data={valoresGrafico} dataKey="name">
                                            <XAxis dataKey="name"/>
                                            <YAxis/>
                                            <Tooltip/>
                                            <Area type='monotone' stackId="1" dataKey="Receitas" fill="#208d20" stroke='#208d20'/>
                                            <Area type='monotone' stackId="1" dataKey="Despesas" fill="#d42f2f" stroke='#d42f2f'/>
                                        </AreaChart>
                                    </ResponsiveContainer>

                                    {/* <ResponsiveContainer width="100%" height="100%">
                                        <BarChart data={data} dataKey="name">
                                            <XAxis dataKey="name"/>
                                            <Tooltip/>
                                            <Bar stackId="a" dataKey="Receitas" fill="#208d20" />
                                            <Bar stackId="a" dataKey="Despesas" fill="#d42f2f" />
                                        </BarChart>
                                    </ResponsiveContainer> */}

                                    {/* <ResponsiveContainer width="100%" height="100%">
                                        <LineChart data={data} dataKey="name">
                                            <XAxis dataKey="name"/>
                                            <YAxis/>
                                            <Tooltip/>
                                            <Line type="monotone" dataKey="Receitas" stroke="#208d20" strokeWidth={2} />
                                            <Line type="monotone" dataKey="Despesas" stroke="#d42f2f" strokeWidth={2} />
                                        </LineChart>
                                    </ResponsiveContainer> */}
                                </div>
                            </div>
                        </div>
                        <div className='divisao2'>
                            <ul className='opcoes-financeiro'>
                                <li onClick={() => navigate('/financeiro/fornecedores')}>
                                    <FontAwesomeIcon icon={faHandshake}/>
                                    Fornecedores
                                </li>
                                <li onClick={() => navigate('/financeiro/contas')}>
                                    <FontAwesomeIcon icon={faPiggyBank}/>
                                    Contas
                                </li>
                                <li onClick={() => navigate('/financeiro/parceiros')}>
                                    <FontAwesomeIcon icon={faFileLines}/>
                                    Relatórios
                                </li>
                                <li onClick={() => navigate('/financeiro/plano-de-contas')}>
                                    <FontAwesomeIcon icon={faFileInvoiceDollar}/>
                                    Plano de Contas
                                </li>
                                <li onClick={() => navigate('/financeiro/fluxo-de-caixa')}>
                                    <FontAwesomeIcon icon={faCashRegister}/>
                                    Fluxo de Caixa
                                </li>
                                <li onClick={() => navigate('/financeiro/caixas')}>
                                    <FontAwesomeIcon icon={faWallet}/>
                                    Caixas
                                </li>
                            </ul>
                            <div className='contas-pagar-receber'>
                                <div className='box-contas'>
                                    <div className='titulo-box'>
                                        <h3>Contas a Pagar e Receber</h3>
                                        <p>Contas a pagar e receber nos próximos 7 dias</p>
                                    </div>
                                    <ul>
                                        { contas.filter(filtroContas).length === 0 ?
                                            <div className='column-zerado'>Não foi encontrado nenhuma conta a pagar ou receber nos próximos 7 dias.</div>
                                        :
                                            contas.filter(filtroContas).sort(ordemData).map((item, index) => (
                                                <li key={index}>
                                                    <Moment format='DD/MM'>{item.vencimento}</Moment>
                                                    <div className={item.tipo === "Pagar" ? "conta-pagar-item" : "conta-receber-item"}>
                                                        <h5>{item.descricao}</h5>
                                                        <h6>{item.tipo === "Pagar" ? "Contas a pagar" : "Contas a receber"}</h6>
                                                    </div>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            :
            <Loading>
                
            </Loading>
            }
        </Fragment>
    )
}