import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentItensEstoque from '../../components/estoque/itens';

const EstoqueItensScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentItensEstoque/>
  </Fragment>
)

export default EstoqueItensScreen;