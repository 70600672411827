import Api from './api';

const FinanceiroService = {
    // PARCEIROS
    parceiros: () => Api.get('/financeiro/parceiros'),
    create_parceiro: (params) => Api.post('/financeiro/parceiros', (params)),
    edit_parceiro: (id, params) => Api.put(`/financeiro/parceiros/${id}`, (params)),
    ativar_parceiro: (id) => Api.put(`/financeiro/parceiros/${id}/ativar`),
    inativar_parceiro: (id) => Api.put(`/financeiro/parceiros/${id}/inativar`),
    delete_parceiro: (id) => Api.delete(`/financeiro/parceiros/${id}`),
    // PLANO DE CONTAS
    planos: () => Api.get('/financeiro/plano-de-contas'),
    create_plano: (params) => Api.post('/financeiro/plano-de-contas', (params)),
    edit_plano: (id, params) => Api.put(`/financeiro/plano-de-contas/${id}`, (params)),
    add_sub_plano: (id, params) => Api.post(`/financeiro/plano-de-contas/${id}/sub`, (params)),
    create_sub_plano: (params) => Api.post('/financeiro/plano-de-contas/sub', (params)),
    edit_sub_plano: (id, params) => Api.put(`/financeiro/plano-de-contas/sub/${id}`, (params)),
    // CONTAS
    contas: () => Api.get('/financeiro/contas'),
    create_conta: (params) => Api.post('/financeiro/contas', (params)),
    edit_conta: (id, params) => Api.put(`/financeiro/contas/${id}`, (params)),
    enviar_comprovante: async (id, params) => await Api.post(`/financeiro/contas/comprovante/${id}`, (params), {headers: { 'Content-Type': `multipart/form-data; boundary=${FormData._boundary}`}}),
    baixar_conta: (id, params) => Api.put(`/financeiro/contas/baixar/${id}`, (params)),
    alterar_situacao_conta: (id, params) => Api.put(`/financeiro/contas/${id}/alterar-situacao`, (params)),
    delete_conta: (id) => Api.delete(`/financeiro/contas/${id}`),
    // CAIXAS
    caixas: () => Api.get('/financeiro/caixas'),
    create_caixa: (params) => Api.post('/financeiro/caixas', (params)),
    edit_caixa: (id, params) => Api.put(`/financeiro/caixas/${id}`, (params)),
    ativar_caixa: (id) => Api.put(`/financeiro/caixas/${id}/ativar`),
    inativar_caixa: (id) => Api.put(`/financeiro/caixas/${id}/inativar`),
    delete_caixa: (id) => Api.delete(`/financeiro/caixas/${id}`)
}

export default FinanceiroService;