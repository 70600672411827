import React, { Fragment, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import 'moment-timezone';
import "../../styles/chamado.scss";
import "../../styles/reset.scss";
// import UsersService from '../../services/users';
import Modal from '../layouts/modal';
import ModalDangerSmall from '../layouts/modalDangerSmall';
import UsersService from '../../services/users';
import Loading from '../layouts/loading';
import ChamadosService from '../../services/chamados';

export default function ContentSinistro() {
    const [chamado, setChamado] = useState([]);
    const [userLogado, setUserLogado] = useState();
    const [permission, setPermission] = useState();
    const [modalFinalizarChamado, setModalFinalizarChamado] = useState(false);
    const [modalExcluirChamado, setModalExcluirChamado] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();

    async function getChamado() {
        try {
            const res = await ChamadosService.chamado(id);
            setChamado(res.data);
            console.log(res.data)
        } catch (error) {
            console.log(error)
        }
    };
    
    async function getUserLogado() {
        try {
            const res = await UsersService.logado();
            setUserLogado(res.data);
            setPermission(res.data.user.permission);
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        getChamado();
        getUserLogado();
        // eslint-disable-next-line
    }, []);

    async function finalizarChamado(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        try {
            await ChamadosService.finalizar(id, form);
            window.location.reload();
        } catch (error) {
            console.log(error)
        }
    }

    // async function editAluno(event) {
    //     event.preventDefault();

    //     const formData = new FormData(event.target);
    //     let form = Object.fromEntries(formData);

    //     try {
    //         console.log(form)
    //         await TreinamentosService.editar_aluno(id, alunoAtivo._id, form)
    //         getTreinamento();
    //         setModalEditarAluno(false);
            
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // async function excluirAluno() {
    //     try {
    //         await TreinamentosService.excluir_aluno(id, alunoAtivo._id)
    //         getTreinamento();
    //         setModalExcluirAluno(false);
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    async function desativarChamado() {
        await ChamadosService.desabilitar(id);
        window.location.href = `/chamados/${chamado.area.toLowerCase().replace("õ", "o")}`
    };
    
    document.title = `${chamado.titulo} - DNG Store`;

    return(
        <Fragment>
            { chamado.length !== 0 && userLogado !== undefined?
            <main className='main'>
                <div className="conteudo-chamado">
                    <div className="cabecalho-conteudo">
                        <h2>{chamado.titulo}</h2>
                        <div className="buttons-conteudo">
                            <button className="button-red" onClick={()=> navigate(`/chamados/${chamado.area.toLowerCase().replace("õ", "o")}${chamado.concluido === true ? '/finalizados' : ""}`)}>Voltar</button>
                            {/* <button className="button-blue">Alterar status</button> */}
                            {permission === 1 ? <button className="button-darkred" onClick={() => {setModalExcluirChamado(true)}}>Excluir</button> : null }
                            <button className="button-cyan" onClick={() => {}}>Editar</button>
                            {permission === 1 && chamado.concluido === false ? <button className="button-green" onClick={() => {setModalFinalizarChamado(true)}}>Finalizar</button> : null }
                        </div>
                    </div>
                    <div className='informacoes-chamado'>
                        <div className='post-chamado'>
                            <div className='informacoes-autor'>
                                <img src={userLogado.url_images + chamado.created_by.imagem} alt='Imagem do autor'></img>
                                {(() => {
                                    switch (true) {
                                        case (chamado.created_by.permission === 1):
                                            return(<Fragment><h3 style={{color: "#007bff"}}>{chamado.created_by.name}</h3><span>Desenvolvedor</span></Fragment>)
                                        case (chamado.created_by.permission === 2):
                                            return(<Fragment><h3 style={{color: "#000080"}}>{chamado.created_by.name}</h3><span>Diretor</span></Fragment>)
                                        case (chamado.created_by.permission === 3):
                                            return(<Fragment><h3 style={{color: "#FF0000"}}>{chamado.created_by.name}</h3><span>Financeiro</span></Fragment>)
                                        case (chamado.created_by.permission === 4):
                                            return(<Fragment><h3 style={{color: "#006400"}}>{chamado.created_by.name}</h3><span>Instrutor</span></Fragment>)
                                        default:
                                        break;
                                    }
                                })()}
                            </div>
                            <div className='descricao-post'>
                                <Moment format='DD [de] MMMM [de] YYYY'>{chamado.created_at}</Moment>
                                <p>{chamado.descricao}</p>
                                <button><FontAwesomeIcon icon={faPlus}/>Citar</button>
                            </div>
                        </div>
                        {chamado.respostas.map((item, index) => (
                            <div className='post-chamado'>
                                <div className='informacoes-autor'>
                                    <img src={userLogado.url_images + item.autor.imagem} alt='Imagem do autor'></img>
                                    {(() => {
                                        switch (true) {
                                            case (item.autor.permission === 1):
                                                return(<Fragment><h3 style={{color: "#007bff"}}>{item.autor.name}</h3><span>Desenvolvedor</span></Fragment>)
                                            case (item.autor.permission === 2):
                                                return(<Fragment><h3 style={{color: "#000080"}}>{item.autor.name}</h3><span>Diretor</span></Fragment>)
                                            case (item.autor.permission === 3):
                                                return(<Fragment><h3 style={{color: "#FF0000"}}>{item.autor.name}</h3><span>Financeiro</span></Fragment>)
                                            case (item.autor.permission === 4):
                                                return(<Fragment><h3 style={{color: "#006400"}}>{item.autor.name}</h3><span>Instrutor</span></Fragment>)
                                            default:
                                            break;
                                        }
                                    })()}
                                </div>
                                <div className='descricao-post'>
                                    <Moment format='DD [de] MMMM [de] YYYY'>{item.created_at}</Moment>
                                    <p>{item.resposta}</p>
                                    <button><FontAwesomeIcon icon={faPlus}/>Citar</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </main> 
            :
            <Loading>
                
            </Loading>
            }
            {modalExcluirChamado ? (
                <ModalDangerSmall mensagem="Deseja realmente excluir esse chamado?" onClose={() => {setModalExcluirChamado(false)}}>
                    <div className='buttons-form'>
                        <button type="reset" className="button-red" onClick={() => {setModalExcluirChamado(false)}}>Cancelar</button>
                        <button type="submit" className="button-blue" onClick={() => {desativarChamado()}}>Excluir</button>
                    </div>
                </ModalDangerSmall>
            ): null}
            {modalFinalizarChamado ? (
                <Modal titulo="Finalizar Chamado" width="70%" onClose={() => {setModalFinalizarChamado(false)}}>
                    <form onSubmit={finalizarChamado}>
                        <div id="campos-nova-agenda">
                            <div className="campo-form campo-100">
                                <label htmlFor="resposta">Resposta</label>
                                <textarea rows={5} name="resposta" id="resposta" required/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalFinalizarChamado(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Finalizar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {/* {modalCertificadoAluno ? (
                <ModalCertificado certificadoId={certificadoAtivo} onClose={() => {setModalCertificadoAluno(false)}}>
                    
                </ModalCertificado>
            ): null}
            {modalEditarAluno ? (
                <Modal titulo="Editar Aluno" onClose={() => {setModalEditarAluno(false)}}>
                    <form onSubmit={editAluno}>
                        <div id="campos-nova-agenda">
                            <div className="campo-form campo-55">
                                <label htmlFor="nome">Nome Completo</label>
                                <input type="text" name="nome" id="nome" defaultValue={alunoAtivo.nome}/>
                            </div>
                            <div className="campo-form campo-45">
                                <label htmlFor="cpf">CPF</label>
                                <input type="text" name="cpf" id="cpf" defaultValue={alunoAtivo.cpf}/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalEditarAluno(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Salvar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalExcluirAluno ? (
                <ModalDangerSmall mensagem={['Deseja excluir o aluno', <br/>,<span>{alunoAtivo.nome}</span>,"?"]} onClose={() => {setModalExcluirAluno(false)}}>
                    <div className='buttons-form'>
                        <button type="reset" className="button-red" onClick={() => {setModalExcluirAluno(false)}}>Cancelar</button>
                        <button type="submit" className="button-blue" onClick={() => {excluirAluno()}}>Excluir</button>
                    </div>
                </ModalDangerSmall>
            ): null} */}
            {/* {modalEditarSinistro ? (
                <Modal titulo="Editar Beneficiário" onClose={() => {setModalEditarSinistro(false)}}>
                    <form>
                        <div id="campos-novo-sinistro">
                            <div className="campo-form campo-30">
                                <label htmlFor="ramo-sinistro">Ramo</label>
                                <select name="ramo" id="ramo-sinistro" defaultValue={sinistro.ramo} disabled>
                                    <option value="Vida">Vida</option>
                                    <option value="Auto">Auto</option>
                                    <option value="Residêncial">Residêncial</option>
                                </select>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="tipo-sinistro">Tipo</label>
                                <select name="tipo" id="tipo-sinistro" defaultValue={sinistro.tipo} onChange={(event) => setTipo(event.target.value)}>
                                    <option value="Sinistro">Sinistro</option>
                                    <option value="Assistência">Assistência</option>
                                </select>
                            </div>
                            <div className="campo-form campo-40">
                                <label htmlFor="motivo-sinistro">Motivo</label>
                                <select name="motivo" id="motivo-sinistro" defaultValue={ tipo !== "" ? sinistro.motivo : null}>
                                    {motivo?.map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                        // (() => {
                                        // })
                                        // <option key={index} value={item === "Selecione um ramo e um tipo" || item === "Selecione um ramo" || item === "Selecione um tipo" ? "default" : item }>{item}</option>
                                    ))}
                                </select>
                            </div>
                            <h4>Dados do Titular do Seguro</h4>
                            <div className="campo-form campo-25">
                                <label htmlFor='doc-titular'>Documento</label>
                                <div className="radio-box">
                                    <input type="radio" name="doctitular" id="cpf-titular" defaultChecked={sinistro.titularDoc.length > 13} 
                                    // onChange={() => {setTitular('cpf')}}
                                    />
                                    <label htmlFor="cpf-titular">CPF</label>
                                    <input type="radio" name="doctitular" id="cnpj-titular" defaultChecked={sinistro.titularDoc.length === 13}
                                    // onChange={() => {setTitular('cnpj')}}
                                    />
                                    <label htmlFor="cnpj-titular">CNPJ</label>
                                </div>
                                { sinistro.titularDoc.length === 18 ? <input type="text" name="titularDoc" id="doc-titular" className="mask-cnpj" defaultValue={sinistro.titularDoc}/> : <input type="text" name="titularDoc" id="doc-titular" className="mask-cpf" defaultValue={sinistro.titularDoc}/> }
                                
                            </div>
                            <div className={ sinistro.titularDoc.length === 18 ? "campo-form campo-70" : "campo-form campo-40"}>
                                {/* { sinistro.titularDoc.length === 18 ? 
                                    <Fragment>
                                        <label htmlFor="nome-titular">Razão Social</label>
                                    </Fragment>
                                :
                                    <Fragment>
                                        <label htmlFor="nome-titular">Nome do titular</label>
                                    </Fragment>
                                }
                                <label htmlFor='nome-titular'>Titular do Seguro</label>
                                <input type="text" name="titularNome" id="nome-titular" defaultValue={sinistro.titularNome}/>
                            </div>
                            {sinistro.titularDoc.length === 18 ?
                                null
                            :
                                <div className="campo-form campo-35">
                                    <label htmlFor="empresa-segurado">Empresa</label>
                                    <input type="text" name="empresaSegurado" id="empresa-segurado"/>
                                </div>
                            }
                            { sinistro.ramo === "Vida" ? 
                                <Fragment>
                                    <h4>Dados do Sinistrado</h4>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="cpf-sinistrado">CPF</label>
                                        <input type="text" name="sinistradoCpf" id="cpf-sinistrado" className='mask-cpf' defaultValue={sinistro.sinistradoCpf}/>
                                    </div>
                                    <div className="campo-form campo-50">
                                        <label htmlFor="nome-sinistrado">Nome do sinistrado</label>
                                        <input type="text" name="sinistradoNome" id="nome-sinistrado" defaultValue={sinistro.sinistradoNome}/>
                                    </div>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="nasc-sinistrado">Data de Nasc.</label>
                                        <input type="date" name="sinistradoNasc" id="nasc-sinistrado" min='1910-01-01' max='2020-01-01'/>
                                    </div>
                                </Fragment>
                            : null }
                            { sinistro.ramo === "Auto" ? 
                                <Fragment>
                                    <h4>Dados do Veículo</h4>
                                    <div className="campo-form campo-15">
                                        <label htmlFor="placas-sinistrado">Placas</label>
                                        <input type="text" name="sinistradoPlacas" id="placas-sinistrado" defaultValue={sinistro.sinistradoPlacas}/>
                                    </div>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="renavam-sinistrado">Renavam</label>
                                        <input type="text" name="sinistradoRenavam" id="renavam-sinistrado" defaultValue={sinistro.sinistradoRenavam}/>
                                    </div>
                                    <div className="campo-form campo-20">
                                        <label htmlFor="marca-sinistrado">Marca</label>
                                        <input type="text" name="sinistradoMarca" id="marca-sinistrado" defaultValue={sinistro.sinistradoMarca}/>
                                    </div>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="modelo-sinistrado">Modelo</label>
                                        <input type="text" name="sinistradoModelo" id="modelo-sinistrado" defaultValue={sinistro.sinistradoModelo}/>
                                    </div>
                                    <div className="campo-form campo-15">
                                        <label htmlFor="ano-sinistrado">Ano/Fab</label>
                                        <input type="text" name="sinistradoAnoFab" id="ano-sinistrado" defaultValue={sinistro.sinistradoAnoFab}/>
                                    </div>
                                </Fragment>
                            : null }
                            { sinistro.ramo === "Residêncial" ? 
                                <Fragment>
                                    <h4>Dados da Residência</h4>
                                    <div className="campo-form campo-20">
                                        <label htmlFor="cep-sinistro">CEP</label>
                                        <input type="text" name="sinistradoCep" id="cep-sinistro" className="mask-cep" defaultValue={sinistro.sinistradoCep}/>
                                    </div>
                                    <div className="campo-form campo-60">
                                        <label htmlFor="logradouro-sinistro">Logradouro</label>
                                        <input type="text" name="sinistradoEndereco" id="logradouro-sinistro" defaultValue={sinistro.sinistradoEndereco}/>
                                    </div>
                                    <div className="campo-form campo-20">
                                        <label htmlFor="numero-sinistro">Número</label>
                                        <input type="text" name="sinistradoNumero" id="numero-sinistro" defaultValue={sinistro.sinistradoNumero}/>
                                    </div>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="complemento-sinistro">Complemento</label>
                                        <input type="text" name="sinistradoComplemento" id="complemento-sinistro" defaultValue={sinistro.sinistradoComplemento}/>
                                    </div>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="bairro-sinistro">Bairro</label>
                                        <input type="text" name="sinistradoBairro" id="bairro-sinistro" defaultValue={sinistro.sinistradoBairro}/>
                                    </div>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="cidade-sinistro">Cidade</label>
                                        <input type="text" name="sinistradoCidade" id="cidade-sinistro" defaultValue={sinistro.sinistradoCidade}/>
                                    </div>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="estado-sinistro">Estado</label>
                                        <select name="sinistradoEstado" id="estado-sinistro" defaultValue={sinistro.sinistradoEstado}>
                                            <option value="default">Selecione</option>
                                            <option value="AC">Acre</option>
                                            <option value="AL">Alagoas</option>
                                            <option value="AM">Amazonas</option>
                                            <option value="AP">Amapá</option>
                                            <option value="BA">Bahia</option>
                                            <option value="CE">Ceará</option>
                                            <option value="DF">Distrito Federal</option>
                                            <option value="ES">Espirito Santo</option>
                                            <option value="GO">Goiás</option>
                                            <option value="MA">Maranhão</option>
                                            <option value="MT">Mato Grosso</option>
                                            <option value="MS">Mato Grosso do Sul</option>
                                            <option value="MG">Minas Gerais</option>
                                            <option value="PA">Pará</option>
                                            <option value="PB">Paraíba</option>
                                            <option value="PR">Paraná</option>
                                            <option value="PE">Pernambuco</option>
                                            <option value="PI">Piauí</option>
                                            <option value="RJ">Rio de Janeiro</option>
                                            <option value="RN">Rio Grande do Norte</option>
                                            <option value="RS">Rio Grande do Sul</option>
                                            <option value="SC">Santa Catarina</option>
                                            <option value="SP">São Paulo</option>
                                            <option value="SE">Sergipe</option>
                                            <option value="RO">Rondônia</option>
                                            <option value="RR">Roraima</option>
                                            <option value="TO">Tocantins</option>
                                        </select>
                                    </div>
                                </Fragment>
                            : null }
                            <h4>Dados do Seguro</h4>
                            <div className="campo-form campo-40">
                                <label htmlFor="seguradora-sinistro">Seguradora</label>
                                <select name="seguradora" id="seguradora-sinistro" defaultValue={sinistro.seguradora}>
                                    {seguradoras?.map((item, index) => (
                                        <option key={index} value={item._id}>{item.fantasia}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="campo-form campo-35">
                                <label htmlFor="apolice-sinistro">Apólice</label>
                                <input type="text" name="apolice" id="apolice" className="mask-number" defaultValue={sinistro.apolice}/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="data-ocorrencia">Data de Ocor.</label>
                                <input type="text" name="dataSinistro" id="data-ocorrencia" className="mask-number" value={sinistro.dataSinistro}/>
                            </div>
                            <div className="campo-form campo-40">
                                <label htmlFor="corretor-sinistro">Corretor</label>
                                <select name="corretor" id="corretor-sinistro">
                                    <option value="default">Selecione</option>
                                    {corretor?.filter(({ excluido }) => excluido === false).map((item, index) => (
                                        <option key={index} value={item._id}>{item.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="protocolo-sinistro">Prot. de Abertura</label>
                                <input type="text" name="protocolo" id="protocolo-sinistro" className="mask-number" defaultValue={sinistro.protocolo}/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="n-sinistro">N° de sinistro</label>
                                <input type="text" name="nSinistro" id="n-sinistro" className="mask-number" defaultValue={sinistro.nSinistro}/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={(() => {setModalEditarSinistro(false)})}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null} */}
            {/* {modalExcluirHistorico ? (
                <ModalDangerSmall mensagem={"Deseja excluir esse histórico definitivamente?"} onClose={() => {setModalExcluirHistorico(false)}}>
                    <div className='buttons-form'>
                        <button type="reset" className="button-red" onClick={() => {setModalExcluirHistorico(false)}}>Cancelar</button>
                        <button type="submit" className="button-blue" onClick={() => {deleteHistorico()}}>Excluir</button>
                    </div>
                </ModalDangerSmall>
            ): null} */}
            {/* {modalCadastrarAgenda ? (
                <Modal titulo="Nova Agenda" onClose={() => {setModalCadastrarAgenda(false)}}>
                    <form onSubmit={createAgenda}>
                        <div id="campos-nova-agenda">
                            <div className="campo-form campo-55">
                                <label htmlFor="assunto">Assunto</label>
                                <input type="text" name="assunto" id="assunto" required/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="data">Data</label>
                                <input type="date" name="data" id="data" required/>
                            </div>
                            <div className="campo-form campo-20">
                                <label htmlFor="horario">Horário</label>
                                <input type="time" name="horario" id="horario" required/>
                            </div>
                            <div className="campo-form campo-100">
                                <label htmlFor="descricao">Descrição</label>
                                <textarea type="text" name="descricao" id="descricao" rows="5" required=""/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalCadastrarAgenda(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null} */}
            {/* {modalExcluirAgenda ? (
                <ModalDangerSmall mensagem={"Deseja excluir essa agenda definitivamente?"} onClose={() => {setModalExcluirAgenda(false)}}>
                    <div className='buttons-form'>
                        <button type="reset" className="button-red" onClick={() => {setModalExcluirAgenda(false)}}>Cancelar</button>
                        <button type="submit" className="button-blue" onClick={() => {deleteAgenda()}}>Excluir</button>
                    </div>
                </ModalDangerSmall>
            ): null} */}
            {/* {modalCadastrarBeneficiario ? (
                <Modal titulo="Novo Beneficiário" onClose={() => {setModalCadastrarBeneficiario(false)}}>
                    <form onSubmit={createBeneficiario}>
                        <div id="campos-novo-beneficiario">
                            <div className="campo-form campo-50">
                                <label htmlFor="nome">Nome</label>
                                <input type="text" name="nome" id="nome" required/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="parentesco">Parentesco</label>
                                <select name="parentesco" id="parentesco" defaultValue={"default"}>
                                    <option value="default" disabled>Selecione</option>
                                    <option value="Cônjuge">Cônjuge</option>
                                    <option value="Filho(a)">Filho(a)</option>
                                    <option value="Irmão(ã)">Irmão(ã)</option>
                                    <option value="Mãe">Mãe</option>
                                    <option value="Pai">Pai</option>
                                    <option value="Outros">Outros</option>
                                </select>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="valor">Valor a receber</label>
                                <input type="text" name="valor" id="valor" required/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalCadastrarBeneficiario(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null} */}
            {/* {modalEditarBeneficiario ? (
                <Modal titulo="Editar Beneficiário" onClose={() => {setModalEditarBeneficiario(false)}}>
                    <form onSubmit={editBeneficiario}>
                        <div id="campos-novo-beneficiario">
                            <div className="campo-form campo-50">
                                <label htmlFor="nome">Nome</label>
                                <input type="text" name="nome" id="nome" defaultValue={beneficiarioAtivo.nome} required/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="parentesco">Parentesco</label>
                                <select name="parentesco" id="parentesco" defaultValue={beneficiarioAtivo.parentesco}>
                                    <option value="Cônjuge">Cônjuge</option>
                                    <option value="Filho(a)">Filho(a)</option>
                                    <option value="Irmão(ã)">Irmão(ã)</option>
                                    <option value="Mãe">Mãe</option>
                                    <option value="Pai">Pai</option>
                                    <option value="Outros">Outros</option>
                                </select>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="valor">Valor a receber</label>
                                <input type="text" name="valor" id="valor" defaultValue={beneficiarioAtivo.valor} required/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalEditarBeneficiario(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Salvar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalExcluirBeneficiario ? (
                <ModalDangerSmall mensagem={["Deseja excluir o beneficiário", <br/>, <span>{beneficiarioAtivo.nome.toUpperCase()}</span>,"?"]} onClose={() => {setModalExcluirBeneficiario(false)}}>
                    <div className='buttons-form'>
                        <button type="reset" className="button-red" onClick={() => {setModalExcluirBeneficiario(false)}}>Cancelar</button>
                        <button type="submit" className="button-blue" onClick={() => {deleteBeneficiario(beneficiarioAtivo._id)}}>Excluir</button>
                    </div>
                </ModalDangerSmall>
            ): null} */}
        </Fragment>
    )
};