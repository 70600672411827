import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faBoxes, faCloudArrowUp, faPlus, faTShirt } from '@fortawesome/free-solid-svg-icons';
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/pt-br'
import "../../styles/itens.scss";
import "../../styles/reset.scss";
import UsersService from '../../services/users';
import Modal from "../layouts/modal";
import Loading from '../layouts/loading';
import EstoqueService from '../../services/estoque';
Moment.globalLocale = 'pt-BR'

export default function ContentItensEstoque() {
    const [userLogado, setUserLogado] = useState();
    const [categoria, setCategoria] = useState([]);
    const [carregado, setCarregado] = useState(false);
    const [modalNovoItem, setModalNovoItem] = useState(false);
    const [aguardandoRetorno, setAguardandoRetorno] = useState(false);
    const [fileName, setFileName] = useState('');
    const navigate = useNavigate();
    const { id } = useParams();

    async function getUserLogado() {
        const res = await UsersService.logado();
        setUserLogado(res.data);
    };

    async function getCategoria() {
        const res = await EstoqueService.categoria(id);
        setCategoria(res.data);
    };

    useEffect(() => {
        (async function() {
            await getUserLogado();
            await getCategoria();
            setCarregado(true);
        })();
        // eslint-disable-next-line
    }, []);

    async function createItem(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        (form.imagem === "" || form.imagem === undefined || form.imagem.size === 0) && delete form.imagem;

        try {
            setAguardandoRetorno(true);
            await EstoqueService.create_item(id, form);
            await getCategoria();
            setModalNovoItem(false);
            setAguardandoRetorno(false);
            setFileName("");
        } catch (error) {
            console.log(error)
        }
    };

    function ordemAlfabetica(a, b){
        return a.descricao < b.descricao ? -1 : a.descricao > b.descricao ? 1 : 0;
    };

    document.title = `${carregado && categoria.descricao !== undefined ? categoria.descricao + " - " : ""} DNG Store`;

    return(
        <Fragment>
            { carregado ? 
                <main className='main'>
                    <div className="conteudo-itens-estoque">
                        <div className="cabecalho-conteudo">
                            <h2>{categoria.descricao}</h2>
                            <div className="buttons-conteudo">
                                <button className='button-red' onClick={() => navigate('/estoque')}>Voltar</button>
                                <button className='button-green' onClick={() => setModalNovoItem(true)}>Adicionar</button>
                            </div>
                        </div>
                        <ul className='lista-itens-estoque'>
                            { categoria.itens.filter(({ excluido }) => excluido === false).length === 0 ?
                                console.log()
                                // add escrita
                            :
                                categoria.itens.filter(({ excluido }) => excluido === false).sort(ordemAlfabetica).map((item, index) => (
                                    <li key={index} onClick={() => navigate(`/estoque/${id}/itens/${item._id}`)}>
                                        {item.imagem ? <img src={`https://assets.usedng.com.br/${item.imagem}`} alt={`Imagem de ${item.descricao}`}/> : <div className='no-image-item-estoque'>Não existe uma imagem para esse item</div>}
                                        <div className='informacoes-itens-estoque'>
                                            <h3>{item.descricao}</h3>
                                            <div className='dados-itens-estoque'>
                                                <FontAwesomeIcon icon={faBoxes}/>
                                                {item.sub_itens.length} {item.sub_itens.length === 1 ? "peça" : "peças"}
                                            </div>
                                            <div className='dados-itens-estoque'>
                                                <FontAwesomeIcon icon={faTShirt}/>
                                                {(() => {
                                                        let quantidade = 0;
        
                                                        item.sub_itens.forEach(element => {
                                                            quantidade = quantidade + element.quantidade;
                                                        });
        
                                                    return(<Fragment>{quantidade} {quantidade === 1 ? "unidade" : "unidades"}</Fragment>)
                                                })()}
                                            </div>
                                        </div>
                                    </li>
                                ))
                            }
                            <li className='item-add-itens' onClick={() => setModalNovoItem(true)}>
                                <FontAwesomeIcon icon={faPlus}/>
                                Adicionar item
                            </li>
                        </ul>
                    </div>
                </main>
            :
                <Loading/>
            }
            {modalNovoItem ? (
                <Modal titulo="Novo item" width="40%" onClose={() => {setModalNovoItem(false); setFileName("")}}>
                    <form onSubmit={createItem}>
                        <div id="campos-novo-item-estoque">
                            <div className="campo-form campo-100">
                                <label htmlFor="descricao">Descrição</label>
                                <input type="text" name="descricao" id="descricao" autoComplete='off' required/>
                            </div>
                            <div className="campo-form campo-100">
                                <input type="file" name="imagem" id="imagem" accept="image/*" onChange={(e) => {const file = e.target.files[0]; file ? setFileName(file.name) : setFileName("")}}/>
                                <label htmlFor="imagem" className='file-upload-label'>
                                    <FontAwesomeIcon icon={faCloudArrowUp}/>
                                    {fileName === "" ? "Selecione uma foto" : fileName}
                                </label>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalNovoItem(false); setFileName("")}}>Cancelar</button>
                            {aguardandoRetorno ? <button type="button" className="button-green">Aguarde<FontAwesomeIcon icon={faSpinner} spinPulse style={{marginLeft: ".5em"}}/></button> : <button type="submit" className="button-green">Cadastrar</button>}
                        </div>
                    </form>
                </Modal>
            ): null}
        </Fragment>
    )
}