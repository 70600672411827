import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { icon, library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import "../../../styles/atalhos.scss";
import "../../../styles/reset.scss";
import Modal from '../../layouts/modal';
import UsersService from '../../../services/users';
import Loading from '../../layouts/loading';
import AtalhosService from '../../../services/atalhos';

function ContentAdministracao() {
    const navigate = useNavigate();
    const [atalhos, setAtalhos] = useState([]);
    const [userAtivo, setUserAtivo] = useState();
    const [userLogado, setUserLogado] = useState();
    const [modalNovoAtalho, setModalNovoAtalho] = useState(false);

    async function getAtalhos() {
        const response = await AtalhosService.index();
        setAtalhos(response.data);

        response.data.forEach(element => {
            const iconAtalho = element.icone;
            const iconDefinition = icon({ prefix: 'fas', iconAtalho });

            if (iconDefinition) {
                library.add(iconDefinition);
            }
        });
    }

    async function getUserLogado() {
        const response_user = await UsersService.logado();
        setUserLogado(response_user.data.user)
    }

    useEffect(() => {
        getUserLogado();
        getAtalhos();
    }, []);

    const createAtalho = async event => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData);

        if (!data.url.startsWith("/")) {
            data.url = "/" + data.url;
        }
        
        try {
            await AtalhosService.create(data);
            setModalNovoAtalho(false);
            getAtalhos();
        } catch (error) {
            console.log(error)
        }
    }

    const editUsuario = async event => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData);
        
        try {
            await UsersService.editar_usuario(userAtivo._id, data);
            window.location.reload();
        } catch (error) {
            console.log(error)
        }
    }

    async function desativarUser() {
        await UsersService.desativar(userAtivo._id);
        window.location.reload();
    };

// ORDENAR
    function ordemAlfabetica(a, b){
        return a.titulo < b.titulo ? -1 : a.titulo > b.titulo ? 1 : 0;
    }

    document.title = 'Atalhos - DNG Store';

    return(
        <Fragment>
            { atalhos !== "" ?
            (<main id='main-atalhos'>
                <div className="cabecalho-conteudo">
                    <h2>Atalhos</h2>
                    <div className="buttons-conteudo">
                        <button className="button-red" onClick={()=> navigate(`/administracao`)}>Voltar</button>
                        <button className="button-blue">Imprimir</button>
                        <button className="button-green" onClick={() => setModalNovoAtalho(true)}>Novo Atalho</button>
                    </div>
                </div>
                <div className="content-atalhos">
                    {atalhos?.sort(ordemAlfabetica).map((item, index) => (
                        <li key={index}>
                            {/* <button><FontAwesomeIcon icon={faTrashCan}/></button> */}
                            <div className='icone-atalhos'>
                                <FontAwesomeIcon icon={fas[item.icone]}/>
                            </div>
                            <p>{item.titulo}</p>
                        </li>
                    ))}
                </div>
            </main>)
            : 
            (
            <Loading>

            </Loading>
            )}
            {modalNovoAtalho ? (
                <Modal titulo="Novo Atalho" onClose={() => {setModalNovoAtalho(false)}}>
                    <form onSubmit={createAtalho}>
                        <div id="campos-novo-atalho">
                            <div className="campo-form campo-60">
                                <label htmlFor="titulo">Titulo</label>
                                <input type="text" name="titulo" id="titulo" autoComplete='off'/>
                            </div>
                            <div className="campo-form campo-40">
                                <label htmlFor="permission">Permissão de Uso</label>
                                <select name="permission" id="permission">
                                    <option value="default">Selecione</option>
                                    <option value="1">Desenvolvedor</option>
                                    <option value="2">Diretor</option>
                                    <option value="3">Financeiro</option>
                                    <option value="4">Instrutor</option>
                                </select>
                            </div>
                            <div className="campo-form campo-50">
                                <label htmlFor="url">Url</label>
                                <input type="text" name="url" id="url" defaultValue={"/"}/>
                            </div>
                            <div className="campo-form campo-50">
                                <label htmlFor="icone">Icone</label>
                                <input type="text" name="icone" id="icone"/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalNovoAtalho(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Cadastrar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {/* {modalDeleteUser ? (
                <ModalDangerSmall mensagem={['Deseja excluir o usuário', <br/>,<span>{userAtivo.name}</span>,"?"]} onClose={() => {setModalDeleteUser(false)}}>
                    <Fragment>
                        <div className='buttons-form'>
                            <button type="button" className="button-red" onClick={() => {setModalDeleteUser(false)}}>Cancelar</button>
                            <button type="submit" className="button-blue" onClick={() => {desativarUser()}}>Excluir</button>
                        </div>
                    </Fragment>
                </ModalDangerSmall>
            ): null}
            {modalEditUser ? (
                <Modal titulo="Editar Usuário" onClose={() => {setModalEditUser(false)}}>
                    <form onSubmit={editUsuario}>
                        <div id="campos-novo-usuario">
                            <div className="campo-form campo-50">
                                <label htmlFor="email-usuario">E-mail</label>
                                <input type="email" name="email" id="email-usuario" defaultValue={userAtivo.email}/>
                            </div>
                            <div className="campo-form campo-50">
                                <label htmlFor="name-usuario">Nome</label>
                                <input type="text" name="name" id="name-usuario" defaultValue={userAtivo.name}/>
                            </div>
                            <div className="campo-form campo-50">
                                <label htmlFor="permissao-usuario">Permissão do Usuário</label>
                                <select name="permission" id="permissao-usuario" defaultValue={userAtivo.permission}>
                                    <option value="default">Selecione</option>
                                    <option value="1">Desenvolvedor</option>
                                    <option value="2">Diretor</option>
                                    <option value="3">Financeiro</option>
                                    <option value="4">Instrutor</option>
                                </select>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="button" className="button-red" onClick={() => {setModalEditUser(false)}}>Cancelar</button>
                            <button type="submit" className="button-green">Salvar</button>
                        </div>
                    </form>
                </Modal>
            ): null} */}
        </Fragment>
    )
};
   
export default ContentAdministracao;