import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentEstoque from '../../components/estoque';

const EstoqueScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentEstoque/>
  </Fragment>
)

export default EstoqueScreen;