import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentFluxoCaixa from '../../components/financeiro/fluxo-caixa/index';

const FluxoCaixaScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentFluxoCaixa/>
  </Fragment>
)

export default FluxoCaixaScreen;