import React, { Fragment } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRoute from './components/auth/private_route';
import LoginScreen from './screens/login/loginScreen';
import HomeScreen from './screens/home/homeScreen';
import AdministracaoScreen from './screens/administracao/administracaoScreen';
import UsuariosScreen from './screens/administracao/usuariosScreen';
import ExcluidosScreen from './screens/administracao/excluidosScreen';
import LogScreen from "./screens/administracao/logScreen";
// import PrivatePages from "./components/auth/private_route/private_pages";
import ConfiguracoesScreen from "./screens/configuracoes/configuracoesScreen";
import AtalhosScreen from "./screens/administracao/atalhosScreen";
import FinanceiroScreen from "./screens/financeiro/financeiroScreen";
import FornecedoresScreen from "./screens/financeiro/fornecedoresScreen";
import PlanoContasScreen from "./screens/financeiro/planoContasScreen";
import ContasScreen from "./screens/financeiro/contasScreen";
import FluxoCaixaScreen from "./screens/financeiro/fluxoCaixaScreen";
import CaixasScreen from "./screens/financeiro/caixasScreen";
import ChamadosScreen from "./screens/chamados/chamadosScreen";
import ChamadoScreen from "./screens/chamados/chamadoScreen";
import ChamadosBugScreen from "./screens/chamados/chamadosBugsScreen";
import ChamadosBugsFinalizadosScreen from "./screens/chamados/chamadosBugsFinalizadosScreen";
import ChamadosCorrecoesScreen from "./screens/chamados/chamadosCorrecoesScreen";
import ChamadosSugestoesScreen from "./screens/chamados/chamadosSugestoesScreen";
import ChamadosSuporteScreen from "./screens/chamados/chamadosSuporteScreen";
import ChamadosSugestoesFinalizadosScreen from "./screens/chamados/chamadosSugestoesFinalizadosScreen";
import ChamadosCorrecoesFinalizadosScreen from "./screens/chamados/chamadosCorrecoesFinalizadosScreen";
import ChamadosSuporteFinalizadosScreen from "./screens/chamados/chamadosSuporteFinalizadosScreen";
import EstoqueScreen from "./screens/estoque/estoqueScreen";
import EstoqueItensScreen from "./screens/estoque/estoqueItensScreen";
import EstoqueItemScreen from "./screens/estoque/estoqueItemScreen";

var newLang = 'pt';
document.documentElement.lang = newLang;

const App = () => (
  <Fragment>
    <BrowserRouter>
        <Routes>
            <Route exact path='/login' element={<LoginScreen/>} />
            <Route exact path='/' element={<PrivateRoute/>}>
                <Route exact path='/' element={<HomeScreen ativo="inicio"/>}/>
                {/* <Route Route exact path='/cursos' element={<PrivatePages permission={1}/>}> */}
                {/* </Route> */}
                {/* <Route exact path='/corretora/sinistros' element={<SinistrosScreen ativo="corretora"/>}/>
                <Route exact path='/corretora/sinistros/:id' element={<SinistroScreen ativo="corretora"/>}/>
                <Route exact path='/corretora/historicos' element={<HistoricosScreen ativo="corretora"/>}/> */}
                <Route exact path='/administracao' element={<AdministracaoScreen ativo="administração"/>}/>
                <Route exact path='/administracao/usuarios' element={<UsuariosScreen ativo="administração"/>}/>
                {/* <Route exact path='/administracao/testes' element={<TestesScreen ativo="administração"/>}/> */}
                <Route exact path='/administracao/log' element={<LogScreen ativo="administração"/>}/>
                <Route exact path='/administracao/excluidos' element={<ExcluidosScreen ativo="administração"/>}/>
                <Route exact path='/administracao/atalhos' element={<AtalhosScreen ativo="administração"/>}/>
                {/* <Route exact path='/administracao/emails' element={<EmailsScreen ativo="administração"/>}/>
                <Route exact path='/administracao/notificacoes' element={<NotificacoesScreen ativo="administração"/>}/>*/}
                <Route exact path='/estoque' element={<EstoqueScreen ativo="estoque"/>}/>
                <Route exact path='/estoque/:id/itens' element={<EstoqueItensScreen ativo="estoque"/>}/>
                <Route exact path='/estoque/:id/itens/:otherId' element={<EstoqueItemScreen ativo="estoque"/>}/>
                <Route exact path='/financeiro' element={<FinanceiroScreen ativo="financeiro"/>}/>
                <Route exact path='/financeiro/fornecedores' element={<FornecedoresScreen ativo="financeiro"/>}/>
                <Route exact path='/financeiro/plano-de-contas' element={<PlanoContasScreen ativo="financeiro"/>}/>
                <Route exact path='/financeiro/contas' element={<ContasScreen ativo="financeiro"/>}/>
                <Route exact path='/financeiro/fluxo-de-caixa' element={<FluxoCaixaScreen ativo="financeiro"/>}/>
                <Route exact path='/financeiro/caixas' element={<CaixasScreen ativo="financeiro"/>}/>
                <Route exact path='/chamados' element={<ChamadosScreen ativo="chamados"/>}/>
                <Route exact path='/chamados/bugs' element={<ChamadosBugScreen ativo="chamados"/>}/>
                <Route exact path='/chamados/bugs/:id' element={<ChamadoScreen ativo="chamados"/>}/>
                <Route exact path='/chamados/bugs/finalizados' element={<ChamadosBugsFinalizadosScreen ativo="chamados"/>}/>
                <Route exact path='/chamados/sugestoes' element={<ChamadosSugestoesScreen ativo="chamados"/>}/>
                <Route exact path='/chamados/sugestoes/:id' element={<ChamadoScreen ativo="chamados"/>}/>
                <Route exact path='/chamados/sugestoes/finalizados' element={<ChamadosSugestoesFinalizadosScreen ativo="chamados"/>}/>
                <Route exact path='/chamados/correcoes' element={<ChamadosCorrecoesScreen ativo="chamados"/>}/>
                <Route exact path='/chamados/correcoes/:id' element={<ChamadoScreen ativo="chamados"/>}/>
                <Route exact path='/chamados/correcoes/finalizados' element={<ChamadosCorrecoesFinalizadosScreen ativo="chamados"/>}/>
                <Route exact path='/chamados/suporte' element={<ChamadosSuporteScreen ativo="chamados"/>}/>
                <Route exact path='/chamados/suporte/:id' element={<ChamadoScreen ativo="chamados"/>}/>
                <Route exact path='/chamados/suporte/finalizados' element={<ChamadosSuporteFinalizadosScreen ativo="chamados"/>}/>
                <Route exact path='/configuracoes' element={<ConfiguracoesScreen ativo="configurações"/>}/>
            </Route>
        </Routes>
    </BrowserRouter>
  </Fragment>
)

export default App;