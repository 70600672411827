import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentContas from '../../components/financeiro/contas/index';

const ContasScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentContas/>
  </Fragment>
)

export default ContasScreen;