import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBriefcase, faChalkboardUser, faChartColumn, faEnvelope, faEye, faSchoolFlag, faStar, faTrashCan, faUser, faUserSecret } from '@fortawesome/free-solid-svg-icons'
import "../../styles/administracao.scss";
import "../../styles/reset.scss";
import UsersService from '../../services/users';
import Loading from '../layouts/loading';

function ContentAdministracao() {
    const [permissao, setPermissao] = useState();
    const [user, setUser] = useState();
    const [carregado, setCarregado] = useState(false);

    useEffect(() => {
        (async () => {
            await getPermission();
            setCarregado(true);
        })();
    }, []);

    async function getPermission() {
        const response = await UsersService.logado();
        setPermissao(response.data.user.permission);
        setUser(response.data.user);
    }

    document.title = 'Administração - DNG Store';

    return(
        carregado ?
            <main id='main-administracao'>
                {permissao <= 3 ? (
                <Link to="/administracao/usuarios">
                    <div className="list-itens">
                        <FontAwesomeIcon icon={faUser} />
                        <div className="content-item">
                            <h3>Usuários</h3>
                        </div>
                    </div>
                </Link>) : null}
                {permissao <= 1 ? (
                <Link to="/administracao/log">
                    <div className="list-itens">
                        <FontAwesomeIcon icon={faUserSecret} />
                        <div className="content-item">
                            <h3>Logs</h3>
                        </div>
                    </div>
                </Link>) : null}
                {permissao <= 1 ? (
                <Link to="/administracao/excluidos">
                    <div className="list-itens">
                        <FontAwesomeIcon icon={faTrashCan} />
                        <div className="content-item">
                            <h3>Excluídos</h3>
                        </div>
                    </div>
                </Link>) : null}
                {permissao === 1 ? (
                <Link to="/administracao/estatisticas">
                    <div className="list-itens">
                        <FontAwesomeIcon icon={faChartColumn} />
                        <div className="content-item">
                            <h3>Estatisticas</h3>
                        </div>
                    </div>
                </Link>) : null}
                {/* {permissao <= 4 || permissao === 6 ? (
                <Link to="/administracao/banco-de-senhas">
                    <div className="list-itens">
                        <FontAwesomeIcon icon={faFingerprint} />
                        <div className="content-item">
                            <h3>Banco de Senhas</h3>
                        </div>
                    </div>
                </Link>) : null} */}
                {permissao === 1 ? (
                <Link to="/administracao/emails">
                    <div className="list-itens">
                        <FontAwesomeIcon icon={faEnvelope} />
                        <div className="content-item">
                            <h3>E-mails</h3>
                        </div>
                    </div>
                </Link>) : null}
                {/* {permissao <= 4 || permissao === 6 ? (
                <Link to="/administracao/notificacoes">
                    <div className="list-itens">
                        <FontAwesomeIcon icon={faBell} />
                        <div className="content-item">
                            <h3>Notificações</h3>
                        </div>
                    </div>
                </Link>) : null} */}
                {/* {permissao === 1 ? (
                <Link to="/administracao/testes">
                    <div className="list-itens">
                        <FontAwesomeIcon icon={faCode} />
                        <div className="content-item">
                            <h3>Testes</h3>
                        </div>
                    </div>
                </Link>) : null} */}
                {permissao === 1 ? (
                <Link to="/administracao/atalhos">
                    <div className="list-itens">
                        <FontAwesomeIcon icon={faStar} />
                        <div className="content-item">
                            <h3>Atalhos</h3>
                        </div>
                    </div>
                </Link>) : null}
            </main>
        :
            <Loading/>
    )
};
   
export default ContentAdministracao;