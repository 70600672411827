import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentFinanceiro from '../../components/financeiro/index';

const FinanceiroScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentFinanceiro/>
  </Fragment>
)

export default FinanceiroScreen;