import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket, faHouse, faHeadset, faGear, faChartLine, faUserGear, faDolly } from '@fortawesome/free-solid-svg-icons'
import "../../styles/header.scss";
import "../../styles/reset.scss";
import UsersService from '../../services/users';

function Header(props) {
    const navigate = useNavigate();
    const [userPermission, setUserPermission] = useState();
    const [redirectToHome, setRedirectToHome] = useState(false);

    async function getUserLogado() {
        const response = await UsersService.logado();
        setUserPermission(response.data.user.permission);
    }

    useEffect(() => {
        getUserLogado();
    }, []);

    async function logOut() {
        await UsersService.logout();
        setRedirectToHome(true);
    }
   
    if (redirectToHome === true) {
        return navigate("/login")
    }

    return(
        <Fragment>
            <header>
                <div className='options option-home'>
                    <div className="icon-option">
                        <Link to={'/'} reloadDocument className={ props.ativo === "inicio" ? 'item-ativo' : null}>
                            <FontAwesomeIcon icon={faHouse} />
                        </Link>
                    </div>
                </div>
                <div className='options options-principais'>
                    <div className="icon-option">
                        <Link to={'/estoque'} reloadDocument className={ props.ativo === "estoque" ? 'item-ativo' : null}>
                            <FontAwesomeIcon icon={faDolly} />
                        </Link>
                    </div>
                    { userPermission <= 3 ?
                        <div className="icon-option">
                            <Link to={'/financeiro'} reloadDocument className={ props.ativo === "financeiro" ? 'item-ativo' : null}>
                                <FontAwesomeIcon icon={faChartLine} />
                            </Link>
                        </div> 
                    : null }
                    <div className="icon-option">
                        <Link to={'/chamados'} reloadDocument className={ props.ativo === "chamados" ? 'item-ativo' : null}>
                            <FontAwesomeIcon icon={faHeadset} />
                        </Link>
                    </div>
                    <div className="icon-option">
                        <Link to={'/administracao'} reloadDocument className={ props.ativo === "administração" ? 'item-ativo' : null}>
                            <FontAwesomeIcon icon={faGear} />
                        </Link>
                    </div>
                </div>
                <div className='options option-desconect'>
                    <div className="icon-option icon-configuracoes">
                        <Link to={'/configuracoes'} reloadDocument className={ props.ativo === "configurações" ? 'item-ativo' : null}>
                            <FontAwesomeIcon icon={faUserGear} />
                        </Link>
                    </div>
                    <div className="icon-option icon-desconectar" onClick={e => logOut()}>
                        <FontAwesomeIcon icon={faRightFromBracket}/>
                    </div>
                </div>
            </header>
        </Fragment>
    )
};
   
export default Header;