import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faBoxOpen, faBoxes, faListUl, faPlus } from '@fortawesome/free-solid-svg-icons';
import Moment from 'react-moment';
// import moment from 'moment/moment';
import 'moment-timezone';
import 'moment/locale/pt-br'
import "../../styles/estoque.scss";
import "../../styles/reset.scss";
// import UsersService from '../../services/users';
import Modal from "../layouts/modal";
import ModalDangerSmall from '../layouts/modalDangerSmall';
import Loading from '../layouts/loading';
import EstoqueService from '../../services/estoque';
Moment.globalLocale = 'pt-BR'

export default function ContentEstoque() {
    // const [userLogado, setUserLogado] = useState();
    const [categorias, setCategorias] = useState();
    const [categoriaAtiva, setCategoriaAtiva] = useState();
    const [carregado, setCarregado] = useState(false);
    const [modalNovaCategoria, setModalNovaCategoria] = useState(false);
    const [modalEditarCategoria, setModalEditarCategoria] = useState(false);
    const [modalDeleteCategoria, setModalDeleteCategoria] = useState(false);
    const [aguardandoRetorno, setAguardandoRetorno] = useState(false);
    const navigate = useNavigate();

    // async function getUserLogado() {
    //     const res = await UsersService.logado();
    //     setUserLogado(res.data);
    // };

    async function getCategorias() {
        const res = await EstoqueService.categorias();
        setCategorias(res.data);
    };

    useEffect(() => {
        (async function() {
            // await getUserLogado();
            await getCategorias();
            setCarregado(true);
        })();
        // eslint-disable-next-line
    }, []);

    async function createCategoria(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        try {
            setAguardandoRetorno(true);
            await EstoqueService.create_categoria(form);
            await getCategorias();
            setModalNovaCategoria(false);
            setAguardandoRetorno(false);
        } catch (error) {
            console.log(error)
        }
    };

    async function editCategoria(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        try {
            setAguardandoRetorno(true);
            await EstoqueService.edit_categoria(categoriaAtiva._id, form);
            await getCategorias();
            setModalEditarCategoria(false);
            setAguardandoRetorno(false);
        } catch (error) {
            console.log(error)
        }
    };

    async function deletarCategoria() {
        try {
            await EstoqueService.delete_categoria(categoriaAtiva._id);
            await getCategorias();
            setModalDeleteCategoria(false);
        } catch (error) {
            console.log(error);
        }
    };

    function ordemAlfabetica(a, b){
        return a.descricao < b.descricao ? -1 : a.descricao > b.descricao ? 1 : 0;
    };

    document.title = `Estoque - DNG Store`;

    return(
        <Fragment>
            { carregado ? 
                <main className='main'>
                    <div className="conteudo-estoque">
                        <div className="cabecalho-conteudo">
                            <h2>Estoque</h2>
                            <div className="buttons-conteudo">
                                <button className='button-green' onClick={() => setModalNovaCategoria(true)}>Adicionar</button>
                            </div>
                        </div>
                        <ul className='lista-categorias-estoque'>
                            { categorias.filter(({ excluido }) => excluido === false).length === 0 ?
                                console.log('test')
                                // add escrita
                            :
                                categorias.filter(({ excluido }) => excluido === false).sort(ordemAlfabetica).map((item, index) => (
                                    <li key={index} onClick={() => navigate(`/estoque/${item._id}/itens`)}>
                                        <div className='conteudo-categoria-estoque'>
                                            <FontAwesomeIcon icon={faBoxOpen}/>
                                            <div className='informacoes-categoria-estoque'>
                                                <h3>{item.descricao}</h3>
                                                <div className='dados-categoria-estoque'>
                                                    <FontAwesomeIcon icon={faListUl}/>
                                                    {item.itens.length} {item.itens.length === 1 ? 'item' : 'itens'}
                                                </div>
                                                <div className='dados-categoria-estoque'>
                                                    <FontAwesomeIcon icon={faBoxes}/>
                                                    {(() => {
                                                        let sub = 0;
    
                                                        item.itens.forEach(element => {
                                                            const quantidade = element.sub_itens.length;
                                                            sub = sub + quantidade
                                                        });
    
                                                        return(<Fragment>{sub} {sub === 1 ? "peça" : "peças"}</Fragment>)
                                                    })()}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='buttons-categoria-estoque'>
                                            <button className='button-blue' onClick={(event) => {event.stopPropagation(); setCategoriaAtiva(item); setModalEditarCategoria(true)}}>Editar</button>
                                            <button className='button-red' onClick={(event) => {event.stopPropagation(); setCategoriaAtiva(item); setModalDeleteCategoria(true)}}>Excluir</button>
                                        </div>
                                    </li>
                                ))
                            }
                            <li className='item-add-categoria' onClick={() => setModalNovaCategoria(true)}>
                                <FontAwesomeIcon icon={faPlus}/>
                                Adicionar categoria
                            </li>
                        </ul>
                    </div>
                </main>
            :
                <Loading/>
            }
            {modalNovaCategoria ? (
                <Modal titulo="Nova categoria de estoque" width="40%" onClose={() => {setModalNovaCategoria(false)}}>
                    <form onSubmit={createCategoria}>
                        <div id="campos-nova-categoria-estoque">
                            <div className="campo-form campo-100">
                                <label htmlFor="descricao">Descrição</label>
                                <input type="text" name="descricao" id="descricao" autoComplete='off' required/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalNovaCategoria(false); setCategoriaAtiva();}}>Cancelar</button>
                            {aguardandoRetorno ? <button type="button" className="button-green">Aguarde<FontAwesomeIcon icon={faSpinner} spinPulse style={{marginLeft: ".5em"}}/></button> : <button type="submit" className="button-green">Cadastrar</button>}
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalEditarCategoria ? (
                <Modal titulo="Editar categoria de estoque" width="40%" onClose={() => {setModalEditarCategoria(false)}}>
                    <form onSubmit={editCategoria}>
                        <div id="campos-editar-categoria-estoque">
                            <div className="campo-form campo-100">
                                <label htmlFor="descricao">Descrição</label>
                                <input type="text" name="descricao" id="descricao" autoComplete='off' required defaultValue={categoriaAtiva.descricao}/>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalEditarCategoria(false); setCategoriaAtiva();}}>Cancelar</button>
                            {aguardandoRetorno ? <button type="button" className="button-green">Aguarde<FontAwesomeIcon icon={faSpinner} spinPulse style={{marginLeft: ".5em"}}/></button> : <button type="submit" className="button-green">Salvar</button>}
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalDeleteCategoria ? (
                <ModalDangerSmall mensagem={['Deseja excluir definitivamente a categoria', <br/>,<span>{categoriaAtiva.descricao}</span>,"?"]} onClose={() => {setModalDeleteCategoria(false)}}>
                    <Fragment>
                        <div className='buttons-form' key={'1'}>
                            <button type="button" className="button-red" onClick={() => {setModalDeleteCategoria(false); setCategoriaAtiva();}}>Cancelar</button>
                            <button type="submit" className="button-blue" onClick={() => {deletarCategoria()}}>Excluir</button>
                        </div>
                    </Fragment>
                </ModalDangerSmall>
            ): null}
        </Fragment>
    )
}