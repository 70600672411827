import Api from './api';

const EstoqueService = {
    // CATEGORIAS
    categorias: () => Api.get('/estoque/categorias'),
    categoria: (id) => Api.get(`/estoque/categorias/${id}`),
    create_categoria: (params) => Api.post('/estoque/categorias', (params)),
    edit_categoria: (id, params) => Api.put(`/estoque/categorias/${id}`, (params)),
    // desativar_categoria: (id) => Api.put(`/estoque/categorias/${id}/excluir`),
    delete_categoria: (id) => Api.delete(`/estoque/categorias/${id}`),
    // GET ITENS DE UMA CATEGORIA
    itens: (id) => Api.get(`/estoque/categorias/${id}/itens`),
    // GET ITEM
    item: (id) => Api.get(`/estoque/itens/${id}`),
    // EDITAR ITEM
    edit_item: (id, params) => Api.put(`/estoque/itens/${id}`, (params), {headers: { 'Content-Type': `multipart/form-data; boundary=${FormData._boundary}`}}),
    // EXCLUIR ITEM
    delete_item: (id) => Api.delete(`/estoque/itens/${id}`),
    // CRIAR PEÇA
    create_peca: (id, params) => Api.post(`/estoque/itens/${id}`, (params), {headers: { 'Content-Type': `multipart/form-data; boundary=${FormData._boundary}`}}),
    // EDITAR PEÇA
    edit_peca: (id, otherId, params) => Api.put(`/estoque/itens/${id}/sub-itens/${otherId}`, (params), {headers: { 'Content-Type': `multipart/form-data; boundary=${FormData._boundary}`}}),
    // BAIXAR PEÇA
    baixar_peca: (id, otherId, params) => Api.put(`/estoque/itens/${id}/sub-itens/${otherId}/baixar`, (params)),
    // ACRESCENTAR UNIDADE PEÇA
    acrescentar_peca: (id, otherId, params) => Api.put(`/estoque/itens/${id}/sub-itens/${otherId}/acrescentar`, (params)),
    // DELETE PEÇA
    delete_peca: (id, otherId) => Api.delete(`/estoque/itens/${id}/sub-itens/${otherId}`),
    // CRIAR ITENS DENTRO DE UMA CATEGORIA
    create_item: (id, params) => Api.post(`/estoque/categorias/${id}`, (params), {headers: { 'Content-Type': `multipart/form-data; boundary=${FormData._boundary}`}}),
    edit_subcategorias: (id, params) => Api.put(`/estoque/subcategorias/${id}`, (params)),
    delete_subcategoria: (id) => Api.delete(`/estoque/subcategorias/${id}`),
}

export default EstoqueService;