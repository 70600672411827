import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faCircle, faPen, faFileInvoice, faSpinner, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import moment from 'moment/moment';
import 'moment-timezone';
import 'moment/locale/pt-br'
import { NumericFormat } from 'react-number-format';
import "../../../styles/contas.scss";
import "../../../styles/reset.scss";
import FinanceiroService from '../../../services/financeiro';
import GerarRecibos from '../../recibos/gerador';
import Modal from "../../layouts/modal";
import Loading from '../../layouts/loading';
import ModalDangerSmall from '../../layouts/modalDangerSmall';
import ImagensService from '../../../services/imagens';
Moment.globalLocale = 'pt-BR'

export default function ContentContas() {
    const [contas, setContas] = useState();
    const [planos, setPlanos] = useState();
    const [parceiros, setParceiros] = useState();
    const [caixas, setCaixas] = useState();
    const [carregado, setCarregado] = useState(false);
    const [modalNovaConta, setModalNovaConta] = useState(false);
    const [modalConta, setModalConta] = useState(false);
    const [modalEditarConta, setModalEditarConta] = useState(false);
    const [modalAlterarStatus, setModalAlterarStatus] = useState(false);
    const [modalBaixarConta, setModalBaixarConta] = useState(false);
    const [modalDeleteConta, setModalDeleteConta] = useState(false);
    const [valorDiferente, setValorDiferente] = useState(false);
    const [parcelado, setParcelado] = useState(false);
    const [quantidadeParcelas, setQuantidadeParcelas] = useState(['1', '2']);
    // const [parcelas, setParcelas] = useState(["0"]);
    const [diferenca, setDiferenca] = useState(0);
    const [finds, setFinds] = useState("");
    const [vencimentoConta, setVencimentoConta] = useState('');
    const [documentoConta, setDocumentoConta] = useState('');
    const [valorConta, setValorConta] = useState('');
    const [contaAtiva, setContaAtiva] = useState();
    const [datasLancamento, setDatasLancamento] = useState();
    const [mensagemLancamento, setMensagemLancamento] = useState();
    const [datasVencimento, setDatasVencimento] = useState();
    const [mensagemVencimento, setMensagemVencimento] = useState();
    const [datasPagamento, setDatasPagamento] = useState();
    const [mensagemPagamento, setMensagemPagamento] = useState();
    const [filtrosAtivos, setFiltrosAtivos] = useState(false);
    const [ordenacaoCampos, setOrdenacaoCampos] = useState();
    const [itemAtivo, setItemAtivo] = useState("pagar");
    const [filtroSelecionado, setFiltroSelecionado] = useState(localStorage.getItem('filtro-ativo-contas') || 'pendente');
    const [aguardandoRetorno, setAguardandoRetorno] = useState(false);
    const navigate = useNavigate();
    
    // function removerParcela() {
    //     const remocao = [...parcelas];
    //     remocao.pop();
    //     setQuantidadeParcelas(quantidadeParcelas - 1)
    //     setParcelas(remocao);
    // }

    function adicionarParcela(quantidade) {
        let quantidadeItem = [];

        for (let index = 1; index <= quantidade; index++) {
            quantidadeItem.push(index)
        }
        setQuantidadeParcelas(quantidadeItem);
    }

    async function getContas() {
        const res = await FinanceiroService.contas();
        setContas(res.data);
    };

    async function getParceiros() {
        const res = await FinanceiroService.parceiros();
        setParceiros(res.data);
    };

    async function getPlanos() {
        const res = await FinanceiroService.planos();
        setPlanos(res.data);
    };

    async function getCaixas() {
        const res = await FinanceiroService.caixas();
        setCaixas(res.data);
    };

    async function gerarReciboConta(conta) {
        const images = await ImagensService.index();
        const logo = images.data.filter(({ nome }) => nome === "Logo")[0].url;
        const assinaturaPresidente = images.data.filter(({ nome }) => nome === "Assinatura Presidente")[0].url;

        GerarRecibos(conta, assinaturaPresidente, logo);
    };

    async function createConta(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        // form.parcelado === "true" ? form.parcelado = true : form.parcelado = false;
        delete form.parcelado;
        delete form.add_observacao;

        form.lancamento = moment(form.lancamento).add(3, "h");
        form.vencimento = moment(form.vencimento).add(3, "h");

        if (itemAtivo === "pagar") {
            form.tipo = "Pagar"
        } else{
            form.tipo = "Receber"
        }

        const regex = /[\d,]+/g;

        form.valor = form.valor.match(regex).join('').replace(',', '.');
        form.valor = Number(form.valor);

        form.observacoes === "" && delete form.observacoes;

        try {
            setAguardandoRetorno(true);

            if (parcelado) {
                for (let i = 1; i <= quantidadeParcelas.length; i++) {
                    const vencimentoInput = document.querySelector(`input[name^="vencimento${i}"]`).value;
                    const documentoInput = document.querySelector(`input[name="documento${i}"]`).value;
                    const valorInput = document.querySelector(`input[name="valor${i}"]`).value;
                    const tipoPagamentoInput = document.querySelector(`select[name="forma_pgto${i}"]`).value;
        
                    let contaParcelada = {
                        vencimento: moment(vencimentoInput).add(3, 'h'),
                        documento: documentoInput,
                        valor: valorInput,
                        forma_pgto: tipoPagamentoInput,
                        descricao: form.descricao,
                        lancamento: form.lancamento,
                        plano_contas: form.plano_contas,
                        tipo: form.tipo
                    }

                    contaParcelada.valor = contaParcelada.valor.match(regex).join('').replace(',', '.');
                    contaParcelada.valor = Number(contaParcelada.valor);

                    if (form.observacoes) {
                        contaParcelada.observacoes = form.observacoes
                    }
                    
                    if (form.parceiro) {
                        contaParcelada.parceiro = form.parceiro
                    } else {
                        contaParcelada.cliente = form.cliente
                    }
        
                    await FinanceiroService.create_conta(contaParcelada);
                };

                await getContas();
                setModalNovaConta(false);
                setParcelado(false);
                setVencimentoConta('');
                setValorConta('');
                setQuantidadeParcelas(['1', '2']);
                setAguardandoRetorno(false);
            } else {
                await FinanceiroService.create_conta(form);
                await getContas();
                setModalNovaConta(false);
                setAguardandoRetorno(false);
            }
        } catch (error) {
            console.log(error)
        }
    };

    async function editConta(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        // form.parcelado === "true" ? form.parcelado = true : form.parcelado = false;
        delete form.parcelado;
        delete form.add_observacao;

        form.lancamento = moment(form.lancamento).add(3, "h");
        form.vencimento = moment(form.vencimento).add(3, "h");

        const regex = /[\d,]+/g;

        form.valor = form.valor.match(regex).join('').replace(',', '.');
        form.valor = Number(form.valor);

        form.observacoes === "" && delete form.observacoes;

        try {
            // console.log(form);
            await FinanceiroService.edit_conta(contaAtiva._id, form);
            await getContas();
            setModalEditarConta(false);
        } catch (error) {
            console.log(error)
        }
    };

    async function baixarConta(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        form.pagamento = moment(form.pagamento).add(3, "h");

        const regex = /[\d,]+/g;

        form.valor_pago = form.valor_pago.match(regex).join('').replace(',', '.');
        form.valor_pago = Number(form.valor_pago);

        try {
            await FinanceiroService.baixar_conta(contaAtiva._id, form);
            await getContas();
            setModalBaixarConta(false);
        } catch (error) {
            console.log(error)
        }
    };

    async function alterarSituacao(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        let form = Object.fromEntries(formData);

        try {
            await FinanceiroService.alterar_situacao_conta(contaAtiva._id, form);
            await getContas();
            setModalAlterarStatus(false);
        } catch (error) {
            console.log(error)
        }
    };

    async function deletarConta() {
        await FinanceiroService.delete_conta(contaAtiva._id);
        setModalDeleteConta(false);
        getContas();
    }

    useEffect(() => {
        (async function() {
            await getContas();
            await getPlanos();
            await getParceiros();
            await getCaixas();
            setCarregado(true);
        })();
        // eslint-disable-next-line
    }, []);

    // FILTRO
    function filtro(a) {
        switch (true) {
            case (filtroSelecionado === "todos"):
                return a;
            case (filtroSelecionado === "pendente"):
                return a.status.at(-1).situacao === "Pendente";
            case (filtroSelecionado === "liquidado"):
                return a.status.at(-1).situacao === "Liquidado";
            case (filtroSelecionado === "cartorio"):
                return a.status.at(-1).situacao === "Em Cartório";
            case (filtroSelecionado === "caução"):
                return a.status.at(-1).situacao === "Em Caução";
            case (filtroSelecionado === "perdido"):
                return a.status.at(-1).situacao === "Perdido";
            default:
            break;
        }
    };

    function busca(a) {
        if (finds === "") {
            return a
        } else {
            return a.descricao.toLowerCase().includes(finds.toLowerCase()) 
            || (itemAtivo === "pagar" ? a.parceiro.razao_social.toLowerCase().includes(finds.toLowerCase()) : a.cliente.toLowerCase().includes(finds.toLowerCase())) 
            || (a.observacao && a.observacao.toLowerCase().includes(finds.toLowerCase())) 
            || a.valor.toLocaleString('pt-br', {minimumFractionDigits: 2}).includes(finds)
            || a.lancamento.replace(/^(\d{4})-(\d{2})-(\d{2}).*$/, "$3/$2/$1").includes(finds)
            || a.vencimento.replace(/^(\d{4})-(\d{2})-(\d{2}).*$/, "$3/$2/$1").includes(finds)
            || (a.pagamento && a.pagamento.replace(/^(\d{4})-(\d{2})-(\d{2}).*$/, "$3/$2/$1").includes(finds))
        }
    };

    function zerarFiltros() {
        setDatasLancamento();
        setDatasVencimento();
        setDatasPagamento();

        const containerFiltros = document.querySelectorAll("div.content-container-filters > form");
        
        for (const item of containerFiltros) {
            item.reset();
        }
    };

    function filtrarDatasLancamento(a) {
        if (datasLancamento === undefined) {
            return true;
        } else if (Object.keys(datasLancamento).length === 2){
            return new Date(a.lancamento) >= new Date(datasLancamento.data1) && new Date(a.lancamento) <= new Date(datasLancamento.data2);
        } else {
            if (datasLancamento.data1) {
                return new Date(a.lancamento) >= new Date(datasLancamento.data1);
            } else {
                return new Date(a.lancamento) <= new Date(datasLancamento.data2);
            };
        };
    };

    function filtrarDatasVencimento(a) {
        if (datasVencimento === undefined) {
            return true;
        } else if (Object.keys(datasVencimento).length === 2){
            return new Date(a.vencimento) >= new Date(datasVencimento.data1) && new Date(a.vencimento) <= new Date(datasVencimento.data2);
        } else {
            if (datasVencimento.data1) {
                return new Date(a.vencimento) >= new Date(datasVencimento.data1);
            } else {
                return new Date(a.vencimento) <= new Date(datasVencimento.data2);
            };
        };
    };

    function filtrarDatasPagamento(a) {
        if (datasPagamento === undefined) {
            return true;
        } else if (Object.keys(datasPagamento).length === 2){
            return new Date(a.pagamento) >= new Date(datasPagamento.data1) && new Date(a.pagamento) <= new Date(datasPagamento.data2);
        } else {
            if (datasPagamento.data1) {
                return new Date(a.pagamento) >= new Date(datasPagamento.data1);
            } else {
                return new Date(a.pagamento) <= new Date(datasPagamento.data2);
            };
        };
    };

    async function filtroLancamento(e) {
        e.preventDefault();

        const formData = new FormData(e.target);
        let form = Object.fromEntries(formData);

        let datas = {};

        if (form.date1_lancamento !== '') {
            datas.data1 = new Date(moment(form.date1_lancamento));
        };

        if (form.date2_lancamento !== '') {
            datas.data2 = new Date(moment(form.date2_lancamento).add(23, 'hours').add(59, 'minutes').add(59, 'seconds'));
        };

        if (Object.keys(datas).length === 2 && new Date(moment(form.date1_lancamento)) > new Date(moment(form.date2_lancamento).add(23, 'hours').add(59, 'minutes').add(59, 'seconds'))) {
            setMensagemLancamento('O data incial deve ser menor que a data final.');
            return;
        } else {
            setMensagemLancamento();
        };

        if (Object.keys(datas).length !== 0) {
            setDatasLancamento(datas);
        } else {
            setDatasLancamento();
        };
    };

    async function filtroVencimento(e) {
        e.preventDefault();

        const formData = new FormData(e.target);
        let form = Object.fromEntries(formData);

        let datas = {};

        if (form.date1_vencimento !== '') {
            datas.data1 = new Date(moment(form.date1_vencimento));
        };

        if (form.date2_vencimento !== '') {
            datas.data2 = new Date(moment(form.date2_vencimento).add(23, 'hours').add(59, 'minutes').add(59, 'seconds'));
        };

        if (Object.keys(datas).length === 2 && new Date(moment(form.date1_vencimento)) > new Date(moment(form.date2_vencimento).add(23, 'hours').add(59, 'minutes').add(59, 'seconds'))) {
            setMensagemVencimento('O data incial deve ser menor que a data final.');
            return;
        } else {
            setMensagemVencimento();
        };

        if (Object.keys(datas).length !== 0) {
            setDatasVencimento(datas);
        } else {
            setDatasVencimento();
        };
    };

    async function filtroPagamento(e) {
        e.preventDefault();

        const formData = new FormData(e.target);
        let form = Object.fromEntries(formData);

        let datas = {};

        if (form.date1_pagamento !== '') {
            datas.data1 = new Date(moment(form.date1_pagamento));
        };

        if (form.date2_pagamento !== '') {
            datas.data2 = new Date(moment(form.date2_pagamento).add(23, 'hours').add(59, 'minutes').add(59, 'seconds'));
        };

        if (Object.keys(datas).length === 2 && new Date(moment(form.date1_pagamento)) > new Date(moment(form.date2_pagamento).add(23, 'hours').add(59, 'minutes').add(59, 'seconds'))) {
            setMensagemPagamento('O data incial deve ser menor que a data final.');
            return;
        } else {
            setMensagemPagamento();
        };

        if (Object.keys(datas).length !== 0) {
            setDatasPagamento(datas);
        } else {
            setDatasPagamento();
        };
    };
    
    // ORDENAÇÃO
    function ordemCampos(a, b) {
        switch (true) {
            case ordenacaoCampos?.coluna === "Lançamento":
                if (ordenacaoCampos?.ordem === "Crescente") {
                    return a.lancamento < b.lancamento ? -1 : a.lancamento > b.lancamento ? 1 : 0;
                } else {
                    return a.lancamento > b.lancamento ? -1 : a.lancamento < b.lancamento ? 1 : 0;
                }
            case ordenacaoCampos?.coluna === "Parceiro":
                if (a.parceiro) {
                    if (ordenacaoCampos?.ordem === "Crescente") {
                        return a.parceiro.razao_social < b.parceiro.razao_social ? -1 : a.parceiro.razao_social > b.parceiro.razao_social ? 1 : 0;
                    } else {
                        return a.parceiro.razao_social > b.parceiro.razao_social ? -1 : a.parceiro.razao_social < b.parceiro.razao_social ? 1 : 0;
                    }
                } else {
                    if (ordenacaoCampos?.ordem === "Crescente") {
                        return a.cliente < b.cliente ? -1 : a.cliente > b.cliente ? 1 : 0;
                    } else {
                        return a.cliente > b.cliente ? -1 : a.cliente < b.cliente ? 1 : 0;
                    }
                }
            case ordenacaoCampos?.coluna === "Descrição":
                if (ordenacaoCampos?.ordem === "Crescente") {
                    return a.descricao < b.descricao ? -1 : a.descricao > b.descricao ? 1 : 0;
                } else {
                    return a.descricao > b.descricao ? -1 : a.descricao < b.descricao ? 1 : 0;
                }
            case ordenacaoCampos?.coluna === "Valor":
                if (ordenacaoCampos?.ordem === "Crescente") {
                    return a.valor < b.valor ? -1 : a.valor > b.valor ? 1 : 0;
                } else {
                    return a.valor > b.valor ? -1 : a.valor < b.valor ? 1 : 0;
                }
            case ordenacaoCampos?.coluna === "Vencimento":
                if (ordenacaoCampos?.ordem === "Crescente") {
                    return a.vencimento < b.vencimento ? -1 : a.vencimento > b.vencimento ? 1 : 0;
                } else {
                    return a.vencimento > b.vencimento ? -1 : a.vencimento < b.vencimento ? 1 : 0;
                }
            case ordenacaoCampos?.coluna === "Pagamento":
                if (ordenacaoCampos?.ordem === "Crescente") {
                    return a.pagamento < b.pagamento ? -1 : a.pagamento > b.pagamento ? 1 : 0;
                } else {
                    return a.pagamento > b.pagamento ? -1 : a.pagamento < b.pagamento ? 1 : 0;
                }
            case ordenacaoCampos?.coluna === "Situação":
                if (ordenacaoCampos?.ordem === "Crescente") {
                    return a.status.at(-1).situacao < b.status.at(-1).situacao ? -1 : a.status.at(-1).situacao > b.status.at(-1).situacao ? 1 : 0;
                } else {
                    return a.status.at(-1).situacao > b.status.at(-1).situacao ? -1 : a.status.at(-1).situacao < b.status.at(-1).situacao ? 1 : 0;
                }
            default:
                return a.vencimento > b.vencimento ? -1 : a.vencimento < b.vencimento ? 1 : 0;
        }
    };

    async function enviarComprovante(e) {
        e.preventDefault();
        
        const data = {
            "comprovante": e.target.files[0]
        };
        
        try {
            await FinanceiroService.enviar_comprovante(contaAtiva._id, data);
            setModalConta(false);
            getContas();
        } catch (error) {
            console.log(error);
        }
    };

    function getValorDiferente(val) {
        setValorDiferente(true);

        const valor = Number(val.match(/[\d,]+/g).join('').replace(',', '.')) - contaAtiva.valor;
        
        setDiferenca(valor)
    };

    document.title = `Contas - DNG Store`;

    return(
        <Fragment>
            { carregado ? 
                <main className='main'>
                    <div className="conteudo-contas">
                        <div className="cabecalho-conteudo">
                            <h2>Contas</h2>
                            <div className="buttons-conteudo">
                                <button className="button-red" onClick={() => navigate(`/financeiro`)}>Voltar</button>
                                <button className="button-green" onClick={() => setModalNovaConta(true)}>Nova Conta</button>
                            </div>
                        </div>
                        <div className='selecao-contas-financeiro'>
                            <div className={itemAtivo === "pagar" ? "item-ativo-contas" : ""} onClick={() => {setItemAtivo("pagar"); if (itemAtivo !== "pagar") { setOrdenacaoCampos(); zerarFiltros();}}}>Contas a Pagar</div>
                            <div className={itemAtivo === "receber" ? "item-ativo-contas" : ""} onClick={() => {setItemAtivo("receber"); if (itemAtivo !== "receber") {setOrdenacaoCampos(); zerarFiltros();}}}>Contas a Receber</div>
                        </div>
                        <div className='container-search'>
                            <select defaultValue={filtroSelecionado} onChange={e => {setFiltroSelecionado(e.target.value); localStorage.setItem('filtro-ativo-contas', e.target.value)}}>
                                <option value="todos">Todos</option>
                                <option value="pendente">Pendente</option>
                                <option value="liquidado">Liquidado</option>
                                <option value="cartorio">Em Cartório</option>
                                <option value="caução">Em Caução</option>
                                <option value="perdido">Perdido</option>
                            </select>
                            <input type='text' placeholder='Buscar' onChange={e => {setFinds(e.target.value)}}/>
                            <FontAwesomeIcon icon={faSearch}/>
                            {filtrosAtivos ? <FontAwesomeIcon icon={faChevronUp} onClick={() => setFiltrosAtivos(false)}/> : <FontAwesomeIcon icon={faChevronDown} onClick={() => setFiltrosAtivos(true)}/>}
                        </div>
                        <div className={!filtrosAtivos ? 'container-filters' : 'container-filters show-filters'}>
                            <div className='content-container-filters'>
                                <h6>Lançamento</h6>
                                <form onSubmit={filtroLancamento}>
                                    <div>
                                        <input type='date' id='date1-lancamento' name='date1_lancamento' min="2020-01-01"/>
                                        <p>até</p>
                                        <input type='date' id='date2-lancamento' name='date2_lancamento' min="2020-01-01"/>
                                    </div>
                                    <button type='reset' className='button-blue' onClick={() => {setDatasLancamento(); setMensagemLancamento()}}>Limpar</button>
                                    <button type='submit' className='button-green'>Aplicar</button>
                                    {mensagemLancamento ? <p>{mensagemLancamento}</p> : null}
                                </form>
                            </div>
                            <div className='content-container-filters'>
                                <h6>Vencimento</h6>
                                <form onSubmit={filtroVencimento}>
                                    <div>
                                        <input type='date' id='date1-vencimento' name='date1_vencimento' min="2020-01-01"/>
                                        <p>até</p>
                                        <input type='date' id='date2-vencimento' name='date2_vencimento' min="2020-01-01"/>
                                    </div>
                                    <button type='reset' className='button-blue' onClick={() => {setDatasVencimento(); setMensagemVencimento()}}>Limpar</button>
                                    <button type='submit' className='button-green'>Aplicar</button>
                                    {mensagemVencimento ? <p>{mensagemVencimento}</p> : null}
                                </form>
                            </div>
                            <div className='content-container-filters'>
                                <h6>Pagamento</h6>
                                <form onSubmit={filtroPagamento}>
                                    <div>
                                        <input type='date' id='date1-pagamento' name='date1_pagamento' min="2020-01-01"/>
                                        <p>até</p>
                                        <input type='date' id='date2-pagamento' name='date2_pagamento' min="2020-01-01"/>
                                    </div>
                                    <button type='reset' className='button-blue' onClick={() => {setDatasPagamento(); setMensagemPagamento()}}>Limpar</button>
                                    <button type='submit' className='button-green'>Aplicar</button>
                                    {mensagemPagamento ? <p>{mensagemPagamento}</p> : null}
                                </form>
                            </div>
                        </div>
                        <div className="tabela-dados">
                            <div className="cabecalho-dados">
                                <div className="column-10" onClick={() => {ordenacaoCampos?.coluna !== "Lançamento" ? setOrdenacaoCampos({coluna: "Lançamento", ordem: "Crescente"}) : ordenacaoCampos?.ordem === "Crescente" ? setOrdenacaoCampos({...ordenacaoCampos, ordem: "Decrescente"}) : setOrdenacaoCampos() }}>Lançamento {ordenacaoCampos?.coluna === "Lançamento" && (ordenacaoCampos?.ordem === "Crescente" ? <FontAwesomeIcon icon={faChevronDown}/> : <FontAwesomeIcon icon={faChevronUp}/>)}</div>
                                <div className="column-25" onClick={() => {ordenacaoCampos?.coluna !== "Parceiro" ? setOrdenacaoCampos({coluna: "Parceiro", ordem: "Crescente"}) : ordenacaoCampos?.ordem === "Crescente" ? setOrdenacaoCampos({...ordenacaoCampos, ordem: "Decrescente"}) : setOrdenacaoCampos() }}>{itemAtivo === "pagar" ? "Fornecedor" : "Cliente"} {ordenacaoCampos?.coluna === "Parceiro" && (ordenacaoCampos?.ordem === "Crescente" ? <FontAwesomeIcon icon={faChevronDown}/> : <FontAwesomeIcon icon={faChevronUp}/>)}</div>
                                <div className="column-20" onClick={() => {ordenacaoCampos?.coluna !== "Descrição" ? setOrdenacaoCampos({coluna: "Descrição", ordem: "Crescente"}) : ordenacaoCampos?.ordem === "Crescente" ? setOrdenacaoCampos({...ordenacaoCampos, ordem: "Decrescente"}) : setOrdenacaoCampos() }}>Descrição {ordenacaoCampos?.coluna === "Descrição" && (ordenacaoCampos?.ordem === "Crescente" ? <FontAwesomeIcon icon={faChevronDown}/> : <FontAwesomeIcon icon={faChevronUp}/>)}</div>
                                <div className="column-10" onClick={() => {ordenacaoCampos?.coluna !== "Valor" ? setOrdenacaoCampos({coluna: "Valor", ordem: "Crescente"}) : ordenacaoCampos?.ordem === "Crescente" ? setOrdenacaoCampos({...ordenacaoCampos, ordem: "Decrescente"}) : setOrdenacaoCampos() }}>Valor {ordenacaoCampos?.coluna === "Valor" && (ordenacaoCampos?.ordem === "Crescente" ? <FontAwesomeIcon icon={faChevronDown}/> : <FontAwesomeIcon icon={faChevronUp}/>)}</div>
                                <div className="column-10" onClick={() => {ordenacaoCampos?.coluna !== "Vencimento" ? setOrdenacaoCampos({coluna: "Vencimento", ordem: "Crescente"}) : ordenacaoCampos?.ordem === "Crescente" ? setOrdenacaoCampos({...ordenacaoCampos, ordem: "Decrescente"}) : setOrdenacaoCampos() }}>Vencimento {ordenacaoCampos?.coluna === "Vencimento" && (ordenacaoCampos?.ordem === "Crescente" ? <FontAwesomeIcon icon={faChevronDown}/> : <FontAwesomeIcon icon={faChevronUp}/>)}</div>
                                <div className="column-10" onClick={() => {ordenacaoCampos?.coluna !== "Pagamento" ? setOrdenacaoCampos({coluna: "Pagamento", ordem: "Crescente"}) : ordenacaoCampos?.ordem === "Crescente" ? setOrdenacaoCampos({...ordenacaoCampos, ordem: "Decrescente"}) : setOrdenacaoCampos() }}>Pagamento {ordenacaoCampos?.coluna === "Pagamento" && (ordenacaoCampos?.ordem === "Crescente" ? <FontAwesomeIcon icon={faChevronDown}/> : <FontAwesomeIcon icon={faChevronUp}/>)}</div>
                                <div className="column-15" onClick={() => {ordenacaoCampos?.coluna !== "Situação" ? setOrdenacaoCampos({coluna: "Situação", ordem: "Crescente"}) : ordenacaoCampos?.ordem === "Crescente" ? setOrdenacaoCampos({...ordenacaoCampos, ordem: "Decrescente"}) : setOrdenacaoCampos() }}>Situação {ordenacaoCampos?.coluna === "Situação" && (ordenacaoCampos?.ordem === "Crescente" ? <FontAwesomeIcon icon={faChevronDown}/> : <FontAwesomeIcon icon={faChevronUp}/>)}</div>
                            </div>
                            <div className="corpo-dados corpo-empresas">
                                { contas.filter(({tipo}) => itemAtivo === "pagar" ? tipo === "Pagar" : tipo === "Receber").filter(filtro).filter(filtrarDatasLancamento).filter(filtrarDatasVencimento).filter(filtrarDatasPagamento).filter(({excluido}) => excluido === false).filter(busca).length === 0 ?
                                    <div className="row-dados">
                                        <div className="column-zerado">
                                            Não foi encontrado nenhum resultado.
                                        </div>
                                    </div>
                                : 
                                    <Fragment>
                                        {contas.filter(({tipo}) => itemAtivo === "pagar" ? tipo === "Pagar" : tipo === "Receber").filter(filtro).filter(filtrarDatasLancamento).filter(filtrarDatasVencimento).filter(filtrarDatasPagamento).filter(({excluido}) => excluido === false).slice(0).reverse().filter(busca).sort(ordemCampos).map((item, index) => (
                                            <div key={index} className="row-dados" onClick={() => {setContaAtiva(item); setModalConta(true)}}>
                                                <div className="column-10">
                                                    <Moment format="DD/MM/YYYY">
                                                        {item.lancamento}
                                                    </Moment>
                                                </div>
                                                <div className="column-25">
                                                    {item.parceiro ? item.parceiro.razao_social : item.cliente}
                                                </div>
                                                <div className="column-20">
                                                    {item.descricao}
                                                </div>
                                                <div className="column-10">
                                                    <NumericFormat value={item.valor} displayType="text" decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale renderText={(value) => <p>{value}</p>}/>
                                                </div>
                                                <div className="column-10">
                                                    <Moment format="DD/MM/YYYY">
                                                        {item.vencimento}
                                                    </Moment>
                                                </div>
                                                <div className="column-10">
                                                    {item.pagamento === undefined || item.pagamento === "" ? 
                                                        "--" 
                                                    : 
                                                        <Moment format="DD/MM/YYYY">
                                                            {item.pagamento}
                                                        </Moment>
                                                    }
                                                </div>
                                                <div className="column-10">
                                                    <FontAwesomeIcon icon={faCircle} style={{ color: item.status.at(-1).situacao === "Pendente" ? "#007bff" : item.status.at(-1).situacao === "Liquidado" ? "#009600" : item.status.at(-1).situacao === "Em Cartório" ? "#005c5c" : item.status.at(-1).situacao === "Em Caução" ? "#ff6400" : "#ff0000" }}/>
                                                    {item.status.at(-1).situacao}
                                                </div>
                                                <div className="column-icon">
                                                    {item.status.at(-1).situacao === "Liquidado" ? <FontAwesomeIcon icon={faFileInvoice} onClick={(event) => {event.stopPropagation(); gerarReciboConta(item)}}/> : null }
                                                </div>
                                                <div className="column-icon" onClick={(event) => {event.stopPropagation(); setContaAtiva(item) ; setModalEditarConta(true)}}>
                                                    <FontAwesomeIcon icon={faPen}/>
                                                </div>
                                            </div>
                                        ))}
                                        <div className="row-dados" style={{fontWeight: 'bold', cursor: 'auto'}}>
                                            <div className="column-55" style={{textTransform: 'uppercase'}}>
                                                Total
                                            </div>
                                            <div className="column-20">
                                                {(() => {
                                                    let valorTotal = 0;

                                                    contas.filter(({tipo}) => itemAtivo === "pagar" ? tipo === "Pagar" : tipo === "Receber").filter(filtro).filter(filtrarDatasLancamento).filter(filtrarDatasVencimento).filter(filtrarDatasPagamento).filter(({excluido}) => excluido === false).slice(0).reverse().filter(busca).forEach(element => {
                                                        valorTotal = valorTotal + element.valor;
                                                    })

                                                    return(<NumericFormat value={valorTotal} displayType="text" decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale renderText={(value) => <Fragment>{value}</Fragment>}/>)
                                                })()}
                                            </div>
                                            <div className="column-10">
                                                {contas.filter(({tipo}) => itemAtivo === "pagar" ? tipo === "Pagar" : tipo === "Receber").filter(filtro).filter(filtrarDatasLancamento).filter(filtrarDatasVencimento).filter(filtrarDatasPagamento).filter(({excluido}) => excluido === false).slice(0).reverse().filter(busca).filter(({ pagamento }) => pagamento !== undefined && pagamento !== "" ).length}
                                            </div>
                                        </div>
                                    </Fragment>
                                    
                                }
                                <h5>Exibindo {contas.filter(({tipo}) => itemAtivo === "pagar" ? tipo === "Pagar" : tipo === "Receber").filter(filtro).filter(filtrarDatasLancamento).filter(filtrarDatasVencimento).filter(filtrarDatasPagamento).filter(({excluido}) => excluido === false).filter(busca).length} {contas.filter(({tipo}) => itemAtivo === "pagar" ? tipo === "Pagar" : tipo === "Receber").filter(filtro).filter(({excluido}) => excluido === false).filter(busca).length === 1 ? "resultado" : "resultados"}</h5>
                            </div>
                        </div>
                    </div>
                </main>
            :
            <Loading>
                
            </Loading>
            }
            {modalNovaConta ? (
                <Modal titulo={`Nova Conta ${itemAtivo === "pagar" ? "a Pagar" : "a Receber"}`} width="50%" onClose={() => {setModalNovaConta(false); setParcelado(false); setVencimentoConta(''); setValorConta(''); setQuantidadeParcelas(['1', '2'])}}>
                    <form onSubmit={createConta}>
                        <div id="campos-nova-conta">
                            <div className="campo-form campo-50">
                                <label htmlFor="parceiro">{itemAtivo === "pagar" ? "Fornecedor" : "Cliente"}</label>
                                {itemAtivo === "pagar" ? 
                                    <select id='parceiro' name='parceiro' defaultValue="" required>
                                        <option value="" disabled>Selecione</option>
                                        { 
                                            parceiros.filter(({fornecedor}) => fornecedor === true).map((item, index) => (
                                                <option key={index} value={item._id}>{item.razao_social}</option>
                                            ))
                                        }
                                    </select>
                                :
                                    <input type='text' name='cliente' id='cliente' required/>
                                }
                            </div>
                            <div className="campo-form campo-50">
                                <label htmlFor="descricao">Descrição</label>
                                <input type="text" name="descricao" id="descricao"/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="parcelado">Parcelado</label>
                                <select name='parcelado' id='parcelado' defaultValue="false" onChange={(e) => {setParcelado(e.target.value === "true" ? true : false);}}>
                                    <option value={false}>Não</option>
                                    <option value={true}>Sim</option>
                                </select>
                            </div>
                            { !parcelado ? 
                                <div className="campo-form campo-25">
                                    <label htmlFor="forma_pgto">Forma de Pgto</label>
                                    <select name='forma_pgto' id='forma_pgto' defaultValue="" required>
                                        <option value="" disabled>Selecione</option>
                                        <option>Boleto Bancário</option>
                                        <option>Cartão de Crédito</option>
                                        <option>Cartão de Débito</option>
                                        <option>Transferência Bancária</option>
                                        <option>Depósito Bancário</option>
                                        <option>Débito Automático</option>
                                        <option>Nota Promissória</option>
                                        <option>Cheque</option>
                                        <option>Troca/Permuta</option>
                                        <option>Outros</option>
                                    </select>
                                </div>
                            :
                                <div className="campo-form campo-25">
                                    <label htmlFor="parcelas">Parcelas</label>
                                    <input type='number' id='parcelas' name='parcelas' min={2} defaultValue={quantidadeParcelas.length} onBlur={(e) => { e.target.value > 2 ? adicionarParcela(e.target.value) : adicionarParcela(2)}}/>
                                </div>
                            }
                            <div className="campo-form campo-50">
                                <label htmlFor="plano_contas">Plano de Contas</label>
                                <select name='plano_contas' id='plano_contas' defaultValue="" required>
                                    <option value="" disabled>Selecione</option>
                                    {planos.filter(({tipo}) => itemAtivo === "pagar" ? tipo === "Saídas" : tipo === "Entradas").map((item, index) => (
                                        <optgroup key={index} label={item.descricao}>
                                            {item.subcategorias.map((s, i) => (
                                                <option key={i} value={s.sub._id}>{s.sub.descricao}</option>
                                            ))}
                                        </optgroup>
                                    ))}
                                </select>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="documento">Documento</label>
                                <input type='text' id='documento' name='documento' value={documentoConta} onChange={(e) => {setDocumentoConta(e.target.value);}}/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="lancamento">Data Lançamento</label>
                                <input type='date' id='lancamento' name='lancamento'/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="vencimento">Data Vencimento</label>
                                <input type='date' id='vencimento' name='vencimento' value={vencimentoConta} onChange={(e) => {setVencimentoConta(e.target.value)}}/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="valor">Valor</label>
                                <NumericFormat id='valor' name='valor' decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale onBlur={(e) => setValorConta(e.target.value.match(/[\d,]+/g).join('').replace(',', '.'))}/>
                            </div>
                            <div className="campo-form campo-100">
                                <label htmlFor="observacoes">Observação</label>
                                <input type='text' id='observacoes' name='observacoes'/>
                            </div>
                            { parcelado ? 
                                <Fragment>
                                    <h5>Parcelas</h5>
                                    {quantidadeParcelas.map((item, index) => (
                                        <div className={`container-inputs`} key={index}>
                                            <div className="campo-form campo-25">
                                                <label htmlFor={`vencimento${item}`}>Vencimento</label>
                                                <input type="date" name={`vencimento${item}`} id={`vencimento${item}`} value={moment(vencimentoConta).add(item - 1, 'M').format("YYYY-MM-DD")}/>
                                            </div>
                                            <div className="campo-form campo-20">
                                                <label htmlFor={`documento${item}`}>Documento</label>
                                                <input type="text" name={`documento${item}`} id={`documento${item}`} value={`${documentoConta}/${item}-${quantidadeParcelas.length}`} readOnly/>
                                            </div>
                                            <div className="campo-form campo-25">
                                                <label htmlFor={`valor${item}`}>Valor</label>
                                                <NumericFormat id={`valor${item}`} name={`valor${item}`} decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale value={ valorConta/quantidadeParcelas.length}/>
                                            </div>
                                            <div className='campo-form campo-30'>
                                                <label htmlFor={`forma_pgto${item}`}>Forma de Pgto</label>
                                                <select name={`forma_pgto${item}`} id={`forma_pgto${item}`} defaultValue="" required>
                                                    <option value="" disabled>Selecione</option>
                                                    <option>Boleto Bancário</option>
                                                    <option>Cartão de Crédito</option>
                                                    <option>Cartão de Débito</option>
                                                    <option>Transferência Bancária</option>
                                                    <option>Depósito Bancário</option>
                                                    <option>Débito Automático</option>
                                                    <option>Nota Promissória</option>
                                                    <option>Cheque</option>
                                                    <option>Troca/Permuta</option>
                                                    <option>Outros</option>
                                                </select>
                                            </div>
                                        </div>
                                    ))}
                                </Fragment>
                            :
                                null
                            }
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalNovaConta(false); setParcelado(false); setVencimentoConta(''); setValorConta(''); setQuantidadeParcelas(['1', '2'])}}>Cancelar</button>
                            {aguardandoRetorno ? <button type="button" className="button-green">Aguarde<FontAwesomeIcon icon={faSpinner} spinPulse style={{marginLeft: ".5em"}}/></button> : <button type="submit" className="button-green">Cadastrar</button>}
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalConta ? (
                <Modal titulo={contaAtiva.tipo === "Pagar" ? "Conta a Pagar" : "Conta a Receber"} width="60%" onClose={() => {setModalConta(false)}}>
                    <div className="info-modal modal-conta">
                        <div className="campo-info campo-80">
                            <h4>Descrição:</h4>
                            <h5>{contaAtiva.descricao}</h5>
                        </div>
                        <div className="campo-info campo-20">
                            <h4>Documento:</h4>
                            <h5>{contaAtiva.documento}</h5>
                        </div>
                        <div className="campo-info campo-80">
                            <h4>{contaAtiva.tipo === "Pagar" ? "Fornecedor:" : "Cliente:"}</h4>
                            <h5>{contaAtiva.parceiro ? contaAtiva.parceiro.razao_social : contaAtiva.cliente}</h5>
                        </div>
                        <div className="campo-info campo-20">
                            <h4>Lançamento:</h4>
                            <h5><Moment format='DD/MM/YYYY'>{contaAtiva.lancamento}</Moment></h5>
                        </div>
                        <div className="campo-info campo-20">
                            <h4>Vencimento:</h4>
                            <h5><Moment format='DD/MM/YYYY'>{contaAtiva.vencimento}</Moment></h5>
                        </div>
                        <div className="campo-info campo-20">
                            <h4>Valor:</h4>
                            <NumericFormat value={contaAtiva.valor} displayType="text" decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale renderText={(value) => <h5>{value}</h5>}/>
                        </div>
                        <div className="campo-info campo-30">
                            <h4>Forma de Pagamento:</h4>
                            <h5>{contaAtiva.forma_pgto}</h5>
                        </div>
                        <div className="campo-info campo-30">
                            <h4>Situação:</h4>
                            <h5>{contaAtiva.status.at(-1).situacao}</h5>
                        </div>
                        <div className="campo-info campo-70">
                            <h4>Plano de Contas:</h4>
                            <h5>{contaAtiva.plano_contas.descricao}</h5>
                        </div>
                        <div className="campo-info campo-30">
                            <h4>Data de Pagamento:</h4>
                            <h5>{contaAtiva.status.at(-1).situacao !== "Liquidado" ? "Não Pago" : <Moment format='DD/MM/YYYY'>{contaAtiva.status.at(-1).data}</Moment>}</h5>
                        </div>
                        { contaAtiva.observacoes !== undefined && contaAtiva.observacoes !== "" ?
                            <div className="campo-info campo-100">
                                <h4>Observação:</h4>
                                <h5>{contaAtiva.observacoes}</h5>
                            </div>
                        :
                            null
                        }
                        { contaAtiva.comprovante === undefined ? <input id="comprovante" type="file" name="comprovante" onChange={enviarComprovante} style={{display: 'none'}} accept=".jpg, .jpeg, .png"/> : null}
                    </div>
                    <div className='buttons-info'>
                        <button type="reset" className="button-red" onClick={() => {setModalConta(false)}}>Cancelar</button>
                        { contaAtiva.comprovante === undefined ? <button type="button" className="button-cyan" onClick={() => {document.querySelector("#comprovante").click()}}>Anexar Comprovante</button> : <button type="button" className="button-cyan" onClick={() => window.open(`https://api.usedng.com.br:3003/files/comprovantes/${contaAtiva.comprovante}`, '_blank')}>Comprovante</button>}
                        <button type="button" className="button-blue" onClick={() => {setModalConta(false); setModalEditarConta(true);}}>Editar</button>
                        { contaAtiva.status.at(-1).situacao !== "Liquidado" ? <button type="button" className="button-blue" onClick={() => {setModalConta(false); setModalAlterarStatus(true)}}>Alterar Situação</button> : null}
                        { contaAtiva.status.at(-1).situacao !== "Liquidado" ? <button type="button" className="button-green" onClick={() => { setModalConta(false); setModalBaixarConta(true)}}>Baixar</button> : null}
                        <button type="button" className="button-darkred" onClick={() => {setModalConta(false); setModalDeleteConta(true)}}>Excluir</button>
                    </div>
                </Modal>
            ): null}
            {modalEditarConta ? (
                <Modal titulo={`Editar Conta ${contaAtiva.tipo === "Pagar" ? "a Pagar" : "a Receber"}`} width="50%" onClose={() => {setModalEditarConta(false)}}>
                    <form onSubmit={editConta}>
                        <div id="campos-editar-conta">
                            <div className="campo-form campo-50">
                                <label htmlFor="parceiro">{contaAtiva.tipo === "Pagar" ? "Fornecedor" : "Cliente"}</label>
                                { contaAtiva.tipo === "Pagar" ?
                                    <select id='parceiro' name='parceiro' defaultValue={contaAtiva.parceiro}>
                                        <option value="default" disabled>Selecione</option>
                                            {parceiros.filter(({fornecedor}) => fornecedor === true).map((item, index) => (
                                                <option key={index} value={item._id}>{item.razao_social}</option>
                                            ))}
                                    </select>
                                :
                                    <input type='text' name='cliente' id='cliente' defaultValue={contaAtiva.cliente}/>
                                }
                            </div>
                            <div className="campo-form campo-50">
                                <label htmlFor="descricao">Descrição</label>
                                <input type="text" name="descricao" id="descricao" defaultValue={contaAtiva.descricao}/>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="forma_pgto">Forma de Pgto</label>
                                <select name='forma_pgto' id='forma_pgto' defaultValue={contaAtiva.forma_pgto}>
                                    <option>Boleto Bancário</option>
                                    <option>Cartão de Crédito</option>
                                    <option>Cartão de Débito</option>
                                    <option>Transferência Bancária</option>
                                    <option>Depósito Bancário</option>
                                    <option>Débito Automático</option>
                                    <option>Nota Promissória</option>
                                    <option>Cheque</option>
                                    <option>Troca/Permuta</option>
                                    <option>Outros</option>
                                </select>
                            </div>
                            <div className="campo-form campo-50">
                                <label htmlFor="plano_contas">Plano de Contas</label>
                                <select name='plano_contas' id='plano_contas' defaultValue={contaAtiva.plano_contas._id}>
                                    <option value="default" disabled>Selecione</option>
                                    {planos.filter(({tipo}) => contaAtiva.tipo === "Pagar" ? tipo === "Saídas" : tipo === "Entradas").map((item, index) => (
                                        <Fragment key={index}>
                                            <optgroup label={item.descricao}>
                                                {item.subcategorias.map((s, i) => (
                                                    <option key={i} value={s.sub._id}>{s.sub.descricao}</option>
                                                ))}
                                            </optgroup>
                                        </Fragment>
                                    ))}
                                </select>
                            </div>
                            <div className="campo-form campo-25">
                                <label htmlFor="documento">Documento</label>
                                <input type='text' id='documento' name='documento' defaultValue={contaAtiva.documento}/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="lancamento">Data Lançamento</label>
                                <input type='date' id='lancamento' name='lancamento' defaultValue={moment(contaAtiva.lancamento).format("YYYY-MM-DD")}/>
                            </div>
                            <div className="campo-form campo-30">
                                <label htmlFor="vencimento">Data Vencimento</label>
                                <input type='date' id='vencimento' name='vencimento' defaultValue={moment(contaAtiva.vencimento).format("YYYY-MM-DD")}/>
                            </div>
                            <div className="campo-form campo-40">
                                <label htmlFor="valor">Valor</label>
                                <NumericFormat value={contaAtiva.valor} id='valor' name='valor' decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale />
                            </div>
                            { contaAtiva.observacoes !== undefined ?
                                <div className="campo-form campo-100">
                                    <label htmlFor="observacoes">Observação</label>
                                    <input type='text' id='observacoes' name='observacoes' defaultValue={contaAtiva.observacoes}/>
                                </div>
                            :
                                null
                            }
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalEditarConta(false); setModalConta(true)}}>Cancelar</button>
                            <button type="submit" className="button-green">Salvar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalAlterarStatus ? (
                <Modal titulo="Alterar Situação" width="35%" onClose={() => {setModalAlterarStatus(false)}}>
                    <form onSubmit={alterarSituacao}>
                        <div id="campos-alterar-situacao">
                            <div className="campo-form campo-100">
                                <label htmlFor="situacao">Situação</label>
                                <select name="situacao" id="situacao">
                                    <option value='default'>Selecione</option>
                                    { contaAtiva.status.at(-1).situacao !== "Pendente" ? <option>Pendente</option> : null}
                                    { contaAtiva.status.at(-1).situacao !== "Em Cartório" ? <option>Em Cartório</option> : null}
                                    { contaAtiva.status.at(-1).situacao !== "Em Caução" ? <option>Em Caução</option> : null}
                                    { contaAtiva.status.at(-1).situacao !== "Perdido" ? <option>Perdido</option> : null}
                                </select>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalAlterarStatus(false); setModalConta(true)}}>Cancelar</button>
                            <button type="submit" className="button-green">Alterar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalBaixarConta ? (
                <Modal titulo="Baixar Conta" width="50%" onClose={() => {setModalBaixarConta(false)}}>
                    <div className='info-modal'>
                        <div className="campo-info campo-80">
                            <h4>Descrição:</h4>
                            <h5>{contaAtiva.descricao}</h5>
                        </div>
                        <div className="campo-info campo-20">
                            <h4>Valor:</h4>
                            <NumericFormat value={contaAtiva.valor} displayType="text" decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale renderText={(value) => <h5>{value}</h5>}/>
                        </div>
                        <div className="campo-info campo-80">
                            <h4>{contaAtiva.tipo === "Pagar" ? "Fornecedor:" : "Cliente:"}</h4>
                            <h5>{contaAtiva.parceiro ? contaAtiva.parceiro.razao_social : contaAtiva.cliente}</h5>
                        </div>
                        <div className="campo-info campo-20">
                            <h4>Vencimento:</h4>
                            <h5><Moment format='DD/MM/YYYY'>{contaAtiva.vencimento}</Moment></h5>
                        </div>
                        <div className="campo-info campo-70">
                            <h4>Plano de Contas:</h4>
                            <h5>{contaAtiva.plano_contas.descricao}</h5>
                        </div>
                        <div className="campo-info campo-30">
                            <h4>Forma de Pagamento:</h4>
                            <h5>{contaAtiva.forma_pgto}</h5>
                        </div>
                    </div>
                    <form onSubmit={baixarConta}>
                        <div id="campos-baixar-conta">
                            <div className="campo-form campo-30">
                                <label htmlFor="pagamento">Data de Pagamento</label>
                                <input type='date' name='pagamento' id='pagamento' defaultValue={moment().format("YYYY-MM-DD")}/>
                            </div>
                            <div className="campo-form campo-40">
                                <label htmlFor="forma_pgto">Forma de Pagamento</label>
                                <select name='forma_pgto' id='forma_pgto' required defaultValue={contaAtiva.forma_pgto}>
                                    <option value="" disabled>Selecione</option>
                                    <option>Boleto Bancário</option>
                                    <option>Cartão de Crédito</option>
                                    <option>Cartão de Débito</option>
                                    <option>Transferência Bancária</option>
                                    <option>Depósito Bancário</option>
                                    <option>Débito Automático</option>
                                    <option>Nota Promissória</option>
                                    <option>Cheque</option>
                                    <option>Troca/Permuta</option>
                                    <option>Outros</option>
                                </select>
                            </div>
                            <div className='campo-form campo-30'>
                                <label htmlFor="valor_pago">Valor Pago</label>
                                <NumericFormat value={contaAtiva.valor} onChange={(e) => {Number(e.target.value.match(/[\d,]+/g).join('').replace(',', '.')) !== contaAtiva.valor ? getValorDiferente(e.target.value) : setValorDiferente(false)}} id='valor_pago' name='valor_pago' decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale />
                            </div>
                            { valorDiferente ?
                                <Fragment>
                                    <div className="campo-form campo-25">
                                        <label htmlFor="caixa">{diferenca < 0 ? "Desconto" : "Juros"}</label>
                                        <NumericFormat value={Number(document.querySelector("#valor_pago").value.match(/[\d,]+/g).join('').replace(',', '.')) - contaAtiva.valor || contaAtiva.valor - Number(document.querySelector("#valor_pago").value.match(/[\d,]+/g).join('').replace(',', '.'))} id='desconto' name='desconto' decimalScale={2} decimalSeparator="," thousandSeparator="." prefix='R$' fixedDecimalScale />
                                    </div>
                                    <div className="campo-form campo-35">
                                        <label htmlFor="forma_pgto">Diferença entre valores</label>
                                        <select name='forma_pgto' id='forma_pgto' defaultValue="desconto">
                                            <option value="desconto">Desconto/Juros</option>
                                        </select>
                                    </div>
                                </Fragment>
                            :
                                null
                            }
                            <div className={`campo-form ${valorDiferente ? "campo-40" : "campo-100"}`}>
                                <label htmlFor="caixa">Caixa</label>
                                <select name='caixa' id='caixa' defaultValue="" required>
                                    <option value="" disabled>Selecione</option>
                                    { caixas.filter((a) => a.excluido === false && a.ativo).map((item, index) => (
                                        <option key={index} value={item._id}>{item.descricao}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='buttons-form'>
                            <button type="reset" className="button-red" onClick={() => {setModalBaixarConta(false); setModalConta(true); setValorDiferente(false); setDiferenca(0)}}>Cancelar</button>
                            <button type="submit" className="button-green">Baixar</button>
                        </div>
                    </form>
                </Modal>
            ): null}
            {modalDeleteConta ? (
                <ModalDangerSmall mensagem={['Deseja excluir a conta', <br/>,<span>{contaAtiva.descricao}</span>,"?"]} onClose={() => {setModalDeleteConta(false)}}>
                    <Fragment>
                        <div className='buttons-form' key={'1'}>
                            <button type="button" className="button-red" onClick={() => {setModalDeleteConta(false); setModalConta(true)}}>Cancelar</button>
                            <button type="submit" className="button-blue" onClick={() => {deletarConta()}}>Excluir</button>
                        </div>
                    </Fragment>
                </ModalDangerSmall>
            ): null}
        </Fragment>
    )
}