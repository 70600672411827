import 'moment-timezone';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from 'moment/moment';
import extenso from 'extenso';

export default function GerarRecibos(conta, assinatura, logo) {
    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });

    pdfMake.vfs = pdfFonts.pdfMake.vfs;
      

    const details = [
            {
                table: {
                    widths: ['*'],
                    height: '50%',
                    body: [
                        [
                            {
                                columns: [
                                    {
                                        columns: [
                                            {
                                                image: logo,
                                                width: 80,
                                                margin: [10, 0, 0, 0]
                                            }
                                        ],
                                        width: '20%'
                                    },
                                    {
                                        width: '60%',
                                        height: 80,
                                        fontSize: 24,
                                        alignment: 'center',
                                        margin: [0, 30, 0, 0],
                                        text: 'RECIBO',
                                        bold: true
                                    },
                                    {
                                        width: '20%',
                                        margin: [0, 30, 0, 0],
                                        bold: true,
                                        table: {
                                            body: [
                                                [formatter.format(conta.valor_pago)]
                                            ]
                                        },
                                        layout: {
                                            paddingLeft: function(i, node) { return 15; },
                                            paddingRight: function(i, node) { return 15; },
                                            paddingTop: function(i, node) { return 5; },
                                            paddingBottom: function(i, node) { return 5; }
                                        }
                                    }

                                ],
                                margin: [0, 10, 0, 20],
                            },
                        ],
                        [
                            {
                                width: '100%',
                                fontSize: 11,
                                alignment: 'justify',
                                margin: [20, 10, 20, 20],
                                lineHeight: 1.2,
                                text: `\u00A0 \u00A0 \u00A0 \u00A0 Recebi de ${conta.tipo === "Pagar" ? "CENTRO DE TREINAMENTO E CAPACITAÇÃO PROFISSIONAL DO PARANÁ" : conta.parceiro.razao_social}, CNPJ 14.511.754/0001-10, a importância de ${formatter.format(conta.valor_pago)} (${extenso(conta.valor_pago.toString().replace('.',","), { mode: 'currency' })}) referente ao pagamento da conta ${conta.descricao} / documento ${conta.documento}, com vencimento em ${moment(conta.vencimento).format("DD/MM/YYYY")} e valor original de ${formatter.format(conta.valor)}.`,
                            }
                        ],
                        [
                            {
                                width: '100%',
                                fontSize: 11,
                                margin: [20, 10, 20, 0],
                                text: `Para maior clareza, firmamos o presente.`,
                            }
                        ],
                        [
                            {
                                width: '100%',
                                fontSize: 11,
                                margin: [20, 0, 20, 40],
                                text: `Maringá - PR, ${moment().format("DD [de] MMMM [de] YYYY")}.`,
                            }
                        ],
                        [
                            {
                                width: '100%',
                                fontSize: 11,
                                alignment: 'center',
                                text: `____________________________________________`,
                            }
                        ],
                        [
                            {
                                width: '100%',
                                fontSize: 10,
                                alignment: 'center',
                                bold: true,
                                text: conta.tipo === "Pagar" ? conta.parceiro.razao_social : "Centro de Treinamento e Capacitação Profissional do Paraná"
                            }
                        ],
                        [
                            {
                                width: '100%',
                                fontSize: 10,
                                margin: [0, 0, 0, 20],
                                alignment: 'center',
                                bold: true,
                                text: conta.tipo === "Pagar" ? conta.parceiro.cnpj : "14.511.754/0001-10"
                            }
                        ],
                    ]
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 1 : 0;
                    },
                    vLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 1 : 0;
                    },
                    hLineColor: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 'black' : '';
                    },
                    vLineColor: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 'black' : '';
                    },
                },
                absolutePosition: {x:25, y:425}
                
            },
            {
                table: {
                    widths: ['*'],
                    height: '50%',
                    body: [
                        [
                            {
                                columns: [
                                    {
                                        columns: [
                                            {
                                                image: logo,
                                                width: 80,
                                                margin: [10, 0, 0, 0]
                                            }
                                        ],
                                        width: '20%'
                                    },
                                    {
                                        width: '60%',
                                        height: 80,
                                        fontSize: 24,
                                        alignment: 'center',
                                        margin: [0, 30, 0, 0],
                                        text: 'RECIBO',
                                        bold: true
                                    },
                                    {
                                        width: '20%',
                                        margin: [0, 30, 0, 0],
                                        bold: true,
                                        table: {
                                            body: [
                                                [formatter.format(conta.valor_pago)]
                                            ]
                                        },
                                        layout: {
                                            paddingLeft: function(i, node) { return 15; },
                                            paddingRight: function(i, node) { return 15; },
                                            paddingTop: function(i, node) { return 5; },
                                            paddingBottom: function(i, node) { return 5; }
                                        }
                                    }

                                ],
                                margin: [0, 10, 0, 20],
                            },
                        ],
                        [
                            {
                                width: '100%',
                                fontSize: 11,
                                alignment: 'justify',
                                margin: [20, 10, 20, 20],
                                lineHeight: 1.2,
                                text: `\u00A0 \u00A0 \u00A0 \u00A0 Recebi de ${conta.tipo === "Pagar" ? "CENTRO DE TREINAMENTO E CAPACITAÇÃO PROFISSIONAL DO PARANÁ" : conta.parceiro.razao_social}, CNPJ 14.511.754/0001-10, a importância de ${formatter.format(conta.valor_pago)} (${extenso(conta.valor_pago.toString().replace('.',","), { mode: 'currency' })}) referente ao pagamento da conta ${conta.descricao} / documento ${conta.documento}, com vencimento em ${moment(conta.vencimento).format("DD/MM/YYYY")} e valor original de ${formatter.format(conta.valor)}.`,
                            }
                        ],
                        [
                            {
                                width: '100%',
                                fontSize: 11,
                                margin: [20, 10, 20, 0],
                                text: `Para maior clareza, firmamos o presente.`,
                            }
                        ],
                        [
                            {
                                width: '100%',
                                fontSize: 11,
                                margin: [20, 0, 20, 40],
                                text: `Maringá - PR, ${moment().format("DD [de] MMMM [de] YYYY")}.`,
                            }
                        ],
                        [
                            {
                                width: '100%',
                                fontSize: 11,
                                alignment: 'center',
                                text: `____________________________________________`,
                            }
                        ],
                        [
                            {
                                width: '100%',
                                fontSize: 10,
                                alignment: 'center',
                                bold: true,
                                text: conta.tipo === "Pagar" ? conta.parceiro.razao_social : "Centro de Treinamento e Capacitação Profissional do Paraná"
                            }
                        ],
                        [
                            {
                                width: '100%',
                                fontSize: 10,
                                margin: [0, 0, 0, 20],
                                alignment: 'center',
                                bold: true,
                                text: conta.tipo === "Pagar" ? conta.parceiro.cnpj : "14.511.754/0001-10"
                            }
                        ],
                    ]
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 1 : 0;
                    },
                    vLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 1 : 0;
                    },
                    hLineColor: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 'black' : '';
                    },
                    vLineColor: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 'black' : '';
                    },
                }

            }
    ];

    const pdfDefinitions = {
        pageSize: 'A4',
        pageOrientation: 'portrait',
        pageMargins: [25, 25, 25, 25],

        info: {
            title: `Lista de Presença`,
        },
        content: [details]
    }

    pdfMake.createPdf(pdfDefinitions).open();
}