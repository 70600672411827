import { Fragment } from 'react';
import Header from '../../components/header/index';
import ContentSugestoesFinalizadosChamados from '../../components/chamados/sugestoes/finalizados';

const ChamadosSugestoesFinalizadosScreen = (props) => (
  <Fragment>
    <Header ativo={props.ativo}/>
    <ContentSugestoesFinalizadosChamados/>
  </Fragment>
)

export default ChamadosSugestoesFinalizadosScreen;