import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/pt-br'
import "../../../styles/finalizados.scss";
import "../../../styles/reset.scss";
import Loading from '../../layouts/loading';
import ChamadosService from '../../../services/chamados';
Moment.globalLocale = 'pt-BR'

export default function ContentSuporteFinalizadosChamados() {
    const navigate = useNavigate();
    const [suporte, setSuporte] = useState();
    const [carregado, setCarregado] = useState(false);

    async function getChamados() {
        const response = await ChamadosService.suporte();
        setSuporte(response.data.filter((a) => a.excluido === false && a.concluido === true));
    }

    useEffect(() =>{
        (async () => {
            await getChamados();
            setCarregado(true);
        })();
    }, []);

    document.title = `Chamados de Suporte Finalizados - DNG Store`;

    return(
        <Fragment>
            { carregado ?
            <main className='main'>
                <div className="conteudo-finalizados">
                    <div className="cabecalho-conteudo">
                        <h2>Chamados de Suporte Finalizados</h2>
                        <div className="buttons-conteudo">
                            <button className="button-red" onClick={() => {navigate('/chamados/suporte')}}>Voltar</button>
                            <button className="button-blue" onClick={() => {window.print()}}>Imprimir</button>
                        </div>
                    </div>
                    <div className='lista-chamados'>
                        <div className='itens-chamados'>
                            { suporte?.length !== 0 ?
                                ( suporte?.slice().reverse().map((item, index) => (
                                    <div className='item-chamados' key={index} onClick={() => {navigate(`/chamados/suporte/${item._id}`)}}>
                                        <div className='titulo-categoria'>
                                            <h2>{item.titulo}</h2>
                                            <div className='descricao-categoria'>
                                                <p>Por {(() => {
                                                    switch (true) {
                                                        case (item.created_by.permission === 1):
                                                            return(<span style={{color: "#007bff"}}>{item.created_by.name}</span>)
                                                        case (item.created_by.permission === 2):
                                                            return(<Fragment><span style={{color: "#000080"}}>{item.created_by.name}</span></Fragment>)
                                                        case (item.created_by.permission === 3):
                                                            return(<Fragment><span style={{color: "#FF0000"}}>{item.created_by.name}</span></Fragment>)
                                                        case (item.created_by.permission === 4):
                                                            return(<Fragment><span style={{color: "#006400"}}>{item.created_by.name}</span></Fragment>)
                                                        default:
                                                        break;
                                                    }
                                                })()}, <Moment format='DD [de] MMMM'>{item.created_at}</Moment></p>
                                            </div>
                                        </div>
                                    </div>
                                )))
                            : 
                                <div className='column-zerado'>Não existe nenhum chamado de suporte finalizado.</div> 
                            }
                        </div>
                    </div>
                </div>
            </main>
            :
            (
                <Loading/>
            )}
        </Fragment>
    )
};